import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { StepperComponent } from "../components/Stepper/Stepper";
import { PreviousButton } from "../components/PreviousButton/PreviousButton";
import { QuoteSummary } from "../components/QuoteSummary/QuoteSummary";
import { Benefits } from "../components/Benefits/Benefits";
import { ContinueButton } from "../components/ContinueButton/ContinueButton";
import { Paths } from "../models/constants/paths";
import { CarePlusEndpoints } from "../models/constants/carePlusEndpoints";
import axios from "axios";
import { GlobalContext } from "../context/GlobalContext";
import { React, useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Loader, Center, Paper, Text, Stack, Button } from "@mantine/core";
import { defaultContext } from "../models/constants/defaultContext";
import { appInsights } from "../components/ApplicationInsights/ApplicationInsights";
import { PageTitleMetaTags } from "../components/PageTitleMetaTags/PageTitleMetaTags";
import { AzureBlobFiles } from "../models/constants/azureBlobFiles";
import { WhatsCoveredCard } from "../components/WhatsCoveredCard/WhatsCoveredCard";

export const FinalEstimatedQuote = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { context, setContext } = useContext(GlobalContext);
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [isContinueDisabled, setIsContinueDisabled] = useState(false);
  const pageTitle = t("browser-tab-titles.final-estimated-quote");
  const pageDescription = t("browser-tab-descriptions.final-estimated-quote");
  const metaTitle = t("browser-meta-titles.final-estimated-quote");
  const metaDescription = t("browser-meta-descriptions.final-estimated-quote");

  const handleLoginRedirect = () => {
    setIsContinueDisabled(true);
    let contextJSON = context;
    contextJSON.completed_pages.final_estimated_quote = true;
    localStorage.setItem("context", JSON.stringify(contextJSON));
    instance.loginRedirect(loginRequest).catch((error) => {
      console.log(error);
      setIsContinueDisabled(false);
      navigate(Paths.Error);
    });
  };

  const GetDisqualifyingQuestions = async (context) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_API_URL +
          CarePlusEndpoints.DisqualifyingQuestions,
        {
          params: {
            is_6inches_lift_kit: context.user_answers.lift_kit_over_six_inches,
            is_aftermarket_performance:
              context.user_answers.aftermarket_enhancements,
          },
          timeout: process.env.REACT_APP_API_TIMEOUT,
        }
      );
      return response;
    } catch (error) {
      navigate(Paths.Error);
    }
  };

  const GetSurcharge = async (context) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_API_URL + CarePlusEndpoints.Surcharge,
        {
          params: {
            year: context.vehicle_data.year,
            rate_class: context.initial_quote.rate_class,
            is_four_by_four: context.user_answers.awd,
            is_diesel: context.user_answers.diesel,
            is_turbo: context.user_answers.oem_turbo_or_super,
          },
          timeout: process.env.REACT_APP_API_TIMEOUT,
        }
      );
      return response;
    } catch (error) {
      appInsights.trackException({ error: new Error(error), severityLevel: 1 });
      navigate(Paths.Error);
    }
  };

  useEffect(() => {
    setIsContinueDisabled(false);
    setIsLoading(true);
    if (!context.completed_pages.questionnaire) {
      setContext(defaultContext);
      navigate(Paths.Home);
    } else {
      if (context.initial_quote.rate_class) {
        GetSurcharge(context)
          .then((res) => {
            setContext({
              ...context,
              surcharges: {
                diesel_surcharge_value: parseFloat(
                  res?.data?.diesel_surcharge_value
                ),
                four_by_four_surcharge_value: parseFloat(
                  res?.data?.four_by_four_surcharge_value
                ),
                turbo_surcharge_value: parseFloat(
                  res?.data?.turbo_surcharge_value
                ),
              },
            });
          })
          .catch(() => {
            navigate(Paths.Error);
          });
      }
      GetDisqualifyingQuestions(context)
        .then((res) => {
          if (res?.data?.ineligibility_reasons.length) {
            navigate(Paths.IneligibleVehicle, {
              replace: true,
              state: { reasons: res.data.ineligibility_reasons },
            });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          appInsights.trackException({
            error: new Error(error),
            severityLevel: 1,
          });
          navigate(Paths.Error);
        });
    }
  }, []);

  return isLoading ? (
    <div className="full-container">
      <div className="content-container">
        <Center>
          <Loader color="#006B5B" size="xl" />
        </Center>
      </div>
    </div>
  ) : (
    <>
      <PageTitleMetaTags
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        metaImage={AzureBlobFiles.MetaImage}
      />
      <div className="full-container">
        <div className="content-container">
          <PreviousButton path={Paths.Questionnaire} />
          <div className="header">
            <div className="header_1">{t("estimated-pretax-quote")}</div>
          </div>
          <QuoteSummary />
          <WhatsCoveredCard />
          <Benefits />
          <ContinueButton
            className="button"
            label={t("continue-to-purchase")}
            clickAction={handleLoginRedirect}
            disabled={isContinueDisabled}
            clicked={isContinueDisabled}
          />
        </div>
      </div>
    </>
  );
};
