import { useTranslation } from "react-i18next";
import { Button, Center, Loader } from "@mantine/core";
import { GlobalContext } from "../context/GlobalContext";
import { useNavigate, useLocation } from "react-router-dom";
import { ContinueButton } from "../components/ContinueButton/ContinueButton";
import { Paths } from "../models/constants/paths";
import { defaultContext } from "../models/constants/defaultContext";
import { React, useContext, useEffect, useState } from "react";
import { IneligibilityReasons } from "../models/constants/ineligibilityReasons";
import { PageTitleMetaTags } from "../components/PageTitleMetaTags/PageTitleMetaTags";
import { AzureBlobFiles } from "../models/constants/azureBlobFiles";

export const IneligibleVehicle = () => {
  const { t } = useTranslation();
  const { context, setContext } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [header, setHeader] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [ineligibilityReasons, setIneligibilityReasons] = useState([]);
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const noVehicle =
    ineligibilityReasons[0] === IneligibilityReasons.NO_ELIGIBLE_VEHICLE &&
    ineligibilityReasons.length === 1;
    const pageTitle = t("browser-tab-titles.ineligible-vehicle");
    const pageDescription = t("browser-tab-descriptions.ineligible-vehicle");
    const metaTitle = t("browser-meta-titles.ineligible-vehicle");
    const metaDescription = t("browser-meta-descriptions.ineligible-vehicle");
  

  useEffect(() => {
    if (location?.state?.reasons) {
      setIneligibilityReasons(location.state.reasons);
      setMake(context.vehicle_data.make);
      setModel(context.vehicle_data.model);
    } else {
      setContext(defaultContext);
      navigate(Paths.Home);
    }
    setContext(defaultContext);
  }, []);

  useEffect(() => {
    generateHeader(ineligibilityReasons);
    setIsLoading(false);
  }, [ineligibilityReasons]);

  const textReasons = {
    [IneligibilityReasons.INELIGIBLE_MAKE_MODEL]: t(
      "does-not-qualify-vehicleclass",
      {
        make: make,
        model: model,
      }
    ),
    [IneligibilityReasons.INELIGIBLE_NO_MAKE_MATCH]: t(
      "does-not-qualify-vehicleclass",
      {
        make: make,
        model: model,
      }
    ),
    [IneligibilityReasons.INELIGIBLE_VEHICLE_AGE]: t("does-not-qualify-year"),
    [IneligibilityReasons.INELIGIBLE_MILEAGE]: t("does-not-qualify-mileage"),
    [IneligibilityReasons.INELIGIBLE_LIFT_KIT_6_INCHES]: t(
      "does-not-qualify-liftkit"
    ),
    [IneligibilityReasons.INELIGIBLE_AFTERMARKET_PERFORMANCE]: t(
      "does-not-qualify-aftermarket"
    ),
    [IneligibilityReasons.SHOP_NOT_PARTICIPATING]: t("shop-not-participating"),
    [IneligibilityReasons.INELIGIBLE_CUSTOMER_TAX_STATE_NOT_REGISTERED]: t(
      "does-not-qualify-state-tax-not-registered"
    ),
    [IneligibilityReasons.INELIGIBLE_VIN]: t("does-not-qualify-vin"),
    [IneligibilityReasons.INCORRECT_VEHICLE_INFORMATION]: t("incorrect-vehicle-information"),
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function generateHeader() {
    if (noVehicle) {
      setHeader(t("no-vehicles-on-account"));
    } else {
      setHeader(t("does-not-qualify", { make: make }));
    }
  }

  function generateButton() {
    if (noVehicle) {
      return (
        <Button
          fullWidth
          className="button"
          color="#FF671D"
          onClick={() =>
            window.open(
              process.env.REACT_APP_SCHEDULER_URL,
              "_blank",
              "noreferrer"
            )
          }
        >
          {t("schedule-inspection")}
        </Button>
      );
    } else {
      return (
        <ContinueButton
          label={t("start-new-quote")}
          clickAction={() => navigate(Paths.Home)}
        />
      );
    }
  }

  function displayIneligibilityReasons() {
    if (ineligibilityReasons.length === 1) {
      if (
        ineligibilityReasons[0] === IneligibilityReasons.NO_ELIGIBLE_VEHICLE
      ) {
        return (
          <div className="subheader_centered">
            {t("schedule-an-inspection-to-qualify")}
          </div>
        );
      } else {
        return (
          <div className="subheader_centered">
            {t("does-not-qualify-reason", {
              reason: textReasons[ineligibilityReasons[0]],
            })}
          </div>
        );
      }
    } else {
      return (
        <>
          <div className="subheader_centered">
            {t("does-not-qualify-multiple")}
          </div>
          <br />
          <div className="subheader_centered">
            {ineligibilityReasons.map((reason) => {
              return <li>{capitalizeFirstLetter(textReasons[reason])}</li>;
            })}
          </div>
        </>
      );
    }
  }

  return isLoading ? (
    <div className="full-container">
      <div className="content-container">
        <Center>
          <Loader color="#006B5B" size="xl" />
        </Center>
      </div>
    </div>
  ) : (
    <div className="full-container">
      <PageTitleMetaTags
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        metaImage={AzureBlobFiles.MetaImage}
      />
      <div className="full-container_ineligible-vehicle">
        <div className="content-container">
          <div className="header">
            <div className="header_2">
              <div className="header_2_centered">{header}</div>
            </div>
            <div className="subheader">{displayIneligibilityReasons()}</div>
            {generateButton()}
          </div>
        </div>
      </div>
    </div>
  );
};
