import { React, useContext } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/GlobalContext";
import { Paper, Flex } from "@mantine/core";

export const StripePaymentBreakdown = () => {
  const { t } = useTranslation();
  const { context } = useContext(GlobalContext);

  const currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const initialRegistrationFee = parseFloat(context.final_quote.one_time_payment);
  const registrationFeeDiscount = parseFloat(context.final_quote.one_time_payment_discount_amount);
  const finalRegistrationFee = parseFloat(context.final_quote.discounted_one_time_payment);
  const baseCoverage = parseFloat(context.final_quote.monthly_rate);
  const taxes =
    parseFloat(context.final_quote.one_time_payment_tax) +
    parseFloat(context.final_quote.subtotal_tax);

  const lineItem = (name, price, style) => {
    if (style === undefined) {
      style = "";
    }
    return (
      <Flex gap="sm" align="center" justify="space-between" direction="row">
        <div className={`surcharge${style}Text`}>{name}</div>
        <div className={`surcharge${style}Text alignRight`}>{price}</div>
      </Flex>
    );
  }

  return (
    <Paper
      shadow="0"
      radius="md"
      p="16px"
      style={{ margin: "32px 0px 16px 0px" }}
    >
      <div className="text-box-header" align="center">
        {" "}
        {t("monthly-payment")}
      </div>
      <div style={{ marginBottom: "16px" }}>
        {lineItem(
          t("first-months-coverage"),
          currency.format(baseCoverage)
        )}
      </div>
      {lineItem(
        "Subtotal",
        currency.format(finalRegistrationFee + baseCoverage)
      )}
      {lineItem("Taxes", currency.format(taxes))}
      {lineItem(
        "Due Today",
        currency.format(finalRegistrationFee + baseCoverage + taxes),
        "Bold"
      )}
      <div className="paymentpage">
        {t("monthly-payment-disclaimer", {
          year: context.vehicle_data.year,
          make: context.vehicle_data.make,
          model: context.vehicle_data.model,
        })}
      </div>
    </Paper>
  );
};
