import { useTranslation } from "react-i18next";
import React from "react";
import {
  Container,
  Flex,
  Image,
  Modal,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useMediaQuery, useDisclosure } from "@mantine/hooks";
import { VideoModal } from "./VideoModal";

export const IssueCard = (props) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });
  const cardWidth = isMobile
    ? "375px"
    : props.size === "standard"
    ? "376px"
    : "576px";

  const cardIcon = () => {
    return (
      <Image
        src={props.icon}
        onClick={open}
        style={{ width: "80px", height: "80px", borderRadius: "4px", cursor:"pointer" }}
      />
    );
  };

  const cardContent = () => {
    return props.size === "standard" || isMobile ? (
      <Stack gap="16px">
        {cardIcon()}
        <Stack gap="8px">
          <Title ff="Crimson Text" fz="24px" fw={600} lh="32px">
            {t(props.title)}
          </Title>
          <Text ff="Montserrat" fz="18px" fw={400} lh="160%">
            {t(props.text)}
          </Text>
        </Stack>
      </Stack>
    ) : (
      <Flex direction="row" gap="16px">
        {cardIcon()}
        <Stack gap="8px">
          <Title ff="Crimson Text" fz="24px" fw={600} lh="32px">
            {t(props.title)}
          </Title>
          <Text ff="Montserrat" fz="18px" fw={400} lh="160%">
            {t(props.text)}
          </Text>
        </Stack>
      </Flex>
    );
  };

  return (
    <Paper
      radius="4px"
      w={cardWidth}
      styles={{
        root: {
          background: "#FFF",
          borderColor: "#006B5B",
          borderWidth: "2px",
          marginBottom: "24px",
        },
      }}
      withBorder
      shadow="lg"
    >
      <Container p="32px">{cardContent()}</Container>
      <Modal size="auto" opened={opened} onClose={close}>
        <VideoModal video_url={"https://vimeo.com/991739365?"}/>
      </Modal>
    </Paper>
  );
};
