import { useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { React } from 'react';
import { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';

export const PreviousButton = ({ path }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { context } = useContext(GlobalContext);

  function goBack() {
    navigate(path);
  }

  return (
    <div style={{ marginLeft: "-18px", marginTop: "-32px" }}>
      <Button
        className="button"
        variant="transparent"
        color="#FF671D"
        onClick={() => goBack()}
      >
        {t("previous")}
      </Button>
    </div>
  );
};
