export const Paths = {
  Agreements: "/agreements",
  AuthResponse: "/auth-response",
  ConfirmAddress: "/confirm-address",
  ConfirmDetails: "/confirm-details",
  ConfirmVehicle: "/confirm-vehicle",
  Error: "/error",
  FinalEstimatedQuote: "/estimated-quote",
  FinalQuote: "/final-quote",
  Home: "/",
  IneligibleVehicle: "/ineligible-vehicle",
  LogIn: "/log-in",
  Payment: "/payment",
  Questionnaire: "/questionnaire",
  TermsAndConditions: "/terms-and-conditions",
  ThankYou: "/thank-you",
  Welcome: "/welcome"
};
