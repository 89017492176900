import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Flex, Stack, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { CoverageCard } from "../CoverageCard/CoverageCard";
import IconExtendVehicleLife from "../../assets/images/Extend the Life of Your Vehicle copy 2.svg"
import IconMoreVehiclesMilesYears from "../../assets/images/More Vehicles, More Miles, More Years copy 2.svg"
import IconCommercialVehicles from "../../assets/images/Commercial Vehicles copy 2.svg";
import IconWaitingPeriod from "../../assets/images/Waiting Period.png"

export const EligibleVehicles = () => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery("(max-width: 1024px)", true, {
		getInitialValueInEffect: false,
	});

	const coveredCardsData = [
		{
			title: "vehicles-under-15-years",
			text: "vehicles-under-15-years-description", //update "See sample contract" to link to the sample contract.
			icon: IconExtendVehicleLife,
		},
		{
			title: "most-makes-and-models",
			text: "most-makes-and-models-description",
			icon: IconMoreVehiclesMilesYears,
		},
    {
      title: "thirty-days-three-hundred-miles",
      text: "thirty-days-three-hundred-miles-description",
      icon: IconWaitingPeriod,
    },
		{
			title: "commercial-vehicles",
			text: "commercial-vehicles-description",
			icon: IconCommercialVehicles,
		},
	];

	return (
		<>
			<Container size={"responsive"} px={0} id="eligible">
				<Stack p={isMobile ? "48px 6%" : "6.8% 9.4% 5.3% 9.4%"} gap={24}>
					<Stack ta={"center"}>
						<Title ff="Crimson Text" fz="40px" lh={"120%"}>
							{t("eligible-vehicles")}
						</Title>
						<Text ff="Montserrat" fz="20px" lh="165%" c="#495057">
							{t("eligible-vehicles-description")}
						</Text>
					</Stack>
					<Flex wrap="wrap" justify="center">
						{coveredCardsData.map((card, index) => (
							<React.Fragment key={index}>
								<CoverageCard {...card} isCovered={true} />
							</React.Fragment>
						))}
					</Flex>
				</Stack>
			</Container>
		</>
	);
};
