import { Button, Modal, Paper, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { WhatsCoveredModal } from "../WhatsCovered/WhatsCoveredModal";

export const WhatsCoveredCard = () => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <Paper shadow="0" radius="md" p="24px" mb="18px" withBorder>
      <Stack gap="xs">
        <Text
          styles={{
            root: {
              fontFamily: "Crimson Text",
              fontSize: "20px",
              fontWeight: 600,
              color: "#212529",
            },
          }}
        >
          What's Covered
        </Text>
        <Text
          styles={{
            root: {
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontWeight: 500,
              color: "#000",
            },
          }}
        >
          The average vehicle has 30,000 components. Nice Difference Care+
          covers most of them.
        </Text>
        <Button
          onClick={open}
          variant="outline"
          color="#FF671D"
          size="lg"
          styles={{
            root: {
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: "18px",
              marginTop: "18px",
            },
          }}
        >
          See What's Covered
        </Button>
      </Stack>
      <Modal size="auto" opened={opened} onClose={close} title="What's Covered">
        <WhatsCoveredModal />
      </Modal>
    </Paper>
  );
};
