import { Button } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/GlobalContext";
import { React, useContext, useEffect, useState } from "react";
import { Center, Loader } from "@mantine/core";
import { IconExclamationCircle } from "@tabler/icons-react";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";

const CheckoutForm = () => {
  const { t } = useTranslation();
  const { context } = useContext(GlobalContext);
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    if (!stripe) {
      return;
    }

    if (!context.stripe_subscription_data.stripe_client_secret) {
      return;
    }
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    //Confirm Payment
    let contextJson = context;
    contextJson.completed_pages.payment = true;
    localStorage.setItem("context", JSON.stringify(contextJson));
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_STRIPE_RETURN_URL,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
    localStorage.clear();
    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="text-box-header">{t("billing-address")}</div>
      <AddressElement options={{ mode: "billing" }} />
      <div className="text-box-header-payment">{t("payment")}</div>
      <PaymentElement id="payment-element" />
      <Button
        className="button"
        type="submit"
        fullWidth
        color="#FF671D"
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        {isLoading ? (
          <Center><Loader color="#006B5B"/></Center>
        ) : (
          t("submit-payment")
        )}
      </Button>
      {message && (
        <div id="payment-message" className="stripe-error">
          <IconExclamationCircle style={{ color: "#FF0000" }} />
          {message}
        </div>
      )}
    </form>
  );
};

export default CheckoutForm;
