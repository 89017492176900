import { React, useState } from "react";
import { Container } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ActionIcon, Group } from "@mantine/core";
import { IconPlayerPlay } from "@tabler/icons-react";
import Vimeo from "@u-wave/react-vimeo";

export const BrochureVideo = (props) => {
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });

  const [isPlaying, setVideoPlaying] = useState(false);

  const handleClick = () => {
    setVideoPlaying(true);
    window.dataLayer.push({ event: "video_loaded" });
  };

  return (
    <>
      {isMobile ? (
        !isPlaying ? (
          <Container className="player-wrapper" onClick={() => handleClick()}>
            <Vimeo
              video={props.video_url}
              className="react-player"
              controls={false}
              showTitle={false}
              responsive={true}
              autoplay={false}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                cursor: "pointer",
              }}
            >
              <Group justify="center">
                <ActionIcon
                  onClick={() => {
                    handleClick();
                  }}
                  variant="default"
                  size="xl"
                  aria-label="Toggle color scheme"
                >
                  <IconPlayerPlay stroke={1.5} />
                </ActionIcon>
              </Group>
            </div>
          </Container>
        ) : (
          <Container size={"responsive"} p={0}>
            <Container className="player-wrapper">
              <Vimeo
                video={props.video_url}
                className="react-player"
                controls={true}
                autoplay={true}
                showTitle={false}
                responsive={true}
                loop={false}
                paused={true}
              />
            </Container>
          </Container>
        )
      ) : null}
    </>
  );
};
