import { useTranslation } from "react-i18next";
import {
  Group,
  Image,
  Title,
  Text,
  Stack,
  Container,
  ActionIcon,
} from "@mantine/core";
import { useState } from "react";
import { IconPlayerPlay } from "@tabler/icons-react";
import IconNiceDifference from "../../assets/images/ND Care+ Legacy.svg";
import { useMsal } from "@azure/msal-react";
import { useMediaQuery } from "@mantine/hooks";
import Vimeo from "@u-wave/react-vimeo";

export const HeroHeader = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();

  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });

  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideoLoadAnalytics = () => {
    window.dataLayer.push({ event: "video_loaded" });
  };

  const handleClick = () => {
    setIsPlaying(true);
  };

  let activeAccount = instance.getActiveAccount();

  const displayVideo = () => {
    return isMobile ? null : isPlaying ? (
      <Container>
        <div className="player-wrapper">
          <Vimeo
            video="https://vimeo.com/1014866027"
            className="react-player"
            controls={true}
            showTitle={false}
            responsive={true}
            autoplay={true}
            loop={false}
            onPlaying={() => {
              handleVideoLoadAnalytics();
            }}
          />
        </div>
      </Container>
    ) : (
      <Container className="player-wrapper" onClick={() => handleClick()}>
        <Vimeo
          video="https://vimeo.com/1014866027"
          className="react-player"
          controls={false}
          showTitle={false}
          responsive={true}
          autoplay={false}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
          }}
        >
          <Group justify="center">
            <ActionIcon
              onClick={() => {
                handleClick();
              }}
              variant="default"
              size="xl"
              aria-label="Toggle color scheme"
            >
              <IconPlayerPlay stroke={1.5} />
            </ActionIcon>
          </Group>
        </div>
      </Container>
    );
  };

  return (
    <>
      <div
        style={{
          backgroundSize: "cover",
          marginTop: "84px",
        }}
      >
        <Container size="xs">
          <Image src={IconNiceDifference} fit></Image>
        </Container>
        <Container size={"responsive"} px={0} bg={"#F8F9FA"}>
          <Group
            p={isMobile ? "" : "0% 16.7% 0% 16.7%"}
            wrap="nowrap"
            gap={72}
            align="center"
            grow
          >
            <Stack
              gap={8}
              p={isMobile ? "0px 23px 59px 25px" : ""}
              w={"auto"}
              h={"100%"}
            >
              <Title
                ff={"Crimson Text"}
                fz={isMobile ? 40 : 56}
                fw={600}
                lh={"120%"}
                c={"#006b5b"}
                mt={"48px"}
              >
                {t("hero-header-title")}
              </Title>
              <Text
                ff={"Montserrat"}
                fz={isMobile ? 18 : 20}
                fw={"bold"}
                lh={"160%"}
                c={"#000000"}
              >
                {t("hero-header-text")}
              </Text>
              <Text
                ff={"Montserrat"}
                fz={isMobile ? 16 : 20}
                fw={400}
                lh={"155%"}
                c={"#000000"}
                mb={isMobile ? "0px" : "48px"}
              >
                {t("hero-header-text-2")}
              </Text>
            </Stack>
            {displayVideo()}
          </Group>
        </Container>
      </div>
    </>
  );
};
