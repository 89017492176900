export const IneligibilityReasons = {
  ALREADY_ENROLLED: 'ALREADY_ENROLLED',
  INCORRECT_VEHICLE_INFORMATION: 'INCORRECT_VEHICLE_INFORMATION',
  INELIGIBLE_AFTERMARKET_PERFORMANCE: 'INELIGIBLE_AFTERMARKET_PERFORMANCE',
  INELIGIBLE_CUSTOMER_TAX_STATE_NOT_REGISTERED: 'INELIGIBLE_CUSTOMER_TAX_STATE_NOT_REGISTERED',
  INELIGIBLE_LIFT_KIT_6_INCHES: 'INELIGIBLE_LIFT_KIT_6_INCHES',
  INELIGIBLE_MAKE_MODEL: 'INELIGIBLE_MAKE_MODEL',
  INELIGIBLE_MILEAGE: 'INELIGIBLE_MILEAGE',
  INELIGIBLE_NO_MAKE_MATCH: 'INELIGIBLE_NO_MAKE_MATCH',
  INELIGIBLE_VEHICLE_AGE: 'INELIGIBLE_VEHICLE_AGE',
  INELIGIBLE_VIN: 'INELIGIBLE_VIN',
  INVALID_DVI: 'INVALID_DVI',
  INVALID_VEHICLE_DATA: 'INVALID_VEHICLE_DATA',
  INVALID_YMMM:'INVALID_YMMM',
  NO_ELIGIBLE_VEHICLE: 'NO_ELIGIBLE_VEHICLE',
  SHOP_NOT_PARTICIPATING: 'SHOP_NOT_PARTICIPATING'
}