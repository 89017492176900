import { Container, Stack } from "@mantine/core";
import { React } from "react";
import { ComponentsList } from "../ComponentsList/ComponentsList";

export const WhatsCoveredModal = (props) => {

  return (
    <Container size={"responsive"} px={0} maw="1250px">
      <Stack styles={{ root: { width: "100%" } }}>
        <Stack ta={"center"}>
          <Stack>
            <ComponentsList
              category={props.category}
              searchCriteria={props.searchCriteria}
              modal={true}
            />
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};
