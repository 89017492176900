import { MantineProvider, Button, createTheme } from "@mantine/core";
import { GlobalContext } from "../../context/GlobalContext";
import { React, useState, useContext } from "react";

export const ToggleButton = ({
  questionId,
  leftButtonText,
  rightButtonText,
}) => {
  const { context, setContext } = useContext(GlobalContext);
  const [button1Variant, setButton1Variant] = useState(() =>
    context.user_answers[questionId] ? "filled" : "outline"
  );
  const [button2Variant, setButton2Variant] = useState(() =>
    context.user_answers[questionId] === false ? "filled" : "outline"
  );

  function handleButtonClick(button) {
    if (button === "button1") {
      setContext({
        ...context,
        user_answers: { ...context.user_answers, [questionId]: true },
      });
      if (button1Variant === "outline") {
        setButton1Variant("filled");
        setButton2Variant("outline");
      }
    } else {
      if (questionId === "problems") {
        if (document.getElementById("problemTextbox")) {
          document.getElementById("problemTextbox").value = "";
        }
        setContext({
          ...context,
          user_answers: {
            ...context.user_answers,
            [questionId]: false,
            problems_details: "",
          },
        });
      } else {
        setContext({
          ...context,
          user_answers: {
            ...context.user_answers,
            [questionId]: false,
          },
        });
      }
      if (button2Variant === "outline") {
        setButton2Variant("filled");
        setButton1Variant("outline");
      }
    }
  }

  const theme = createTheme({
    fontFamily: "Montserrat, sans-serif",
    colors: {
      "cba-legacy-green": [
        "#ebf7f6",
        "#d8f0ee",
        "#a4dbd6",
        "#76c4bc",
        "#2d9688",
        "#006b5b",
        "#00614e",
        "#004f3b",
        "#00402c",
      ],
    },
  });

  return (
    <MantineProvider theme={theme}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          className="button"
          style={{ marginRight: "8px", flexGrow: "1", borderWidth: "2px" }}
          variant={button1Variant}
          color="cba-legacy-green"
          onClick={() => handleButtonClick("button1")}
        >
          {leftButtonText}
        </Button>
        <Button
          className="button"
          style={{ flexGrow: "1", borderWidth: "2px" }}
          variant={button2Variant}
          color="cba-legacy-green"
          onClick={() => handleButtonClick("button2")}
        >
          {rightButtonText}
        </Button>
      </div>
    </MantineProvider>
  );
};
