import logo from "../../assets/images/Christian Brothers Horizontal_Green_RGB-01 (4).svg";
import { useMsal } from "@azure/msal-react";
import { Image, Flex, Divider, Progress } from "@mantine/core";
import { SignOutModal } from "../SignOutModal/SignOutModal";
import { StepperComponent } from "../Stepper/Stepper";
import { Paths } from "../../models/constants/paths.js";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const LogoHeader = () => {
  const { instance } = useMsal();
  let activeAccount = instance.getActiveAccount();

  const location = useLocation();
  const [stepperState, setStepperState] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isInitialQuote, setIsInitialQuote] = useState(false);
  const [displayStepper, setDisplayStepper] = useState(false);

  function displaySignOutButton() {
    return activeAccount ? <SignOutModal /> : null;
  }

  useEffect(() => {
    switch (location.pathname) {
      case Paths.Questionnaire:
        setIsInitialQuote(true);
        setDisplayStepper(true);
        setStepperState(1);
        break;
      case Paths.FinalEstimatedQuote:
        setIsInitialQuote(true);
        setDisplayStepper(true);
        setStepperState(2);
        break;
      case Paths.ConfirmVehicle:
        setIsInitialQuote(false);
        setDisplayStepper(true);
        setStepperState(0);
        setProgressPercentage(0);
        break;
      case Paths.ConfirmDetails:
        setIsInitialQuote(false);
        setDisplayStepper(true);
        setStepperState(0);
        setProgressPercentage(16);
        break;
      case Paths.ConfirmAddress:
        setIsInitialQuote(false);
        setDisplayStepper(true);
        setStepperState(1);
        setProgressPercentage(33);
        break;
      case Paths.FinalQuote:
        setIsInitialQuote(false);
        setDisplayStepper(true);
        setStepperState(1);
        setProgressPercentage(50);
        break;
      case Paths.Agreements:
        setIsInitialQuote(false);
        setDisplayStepper(true);
        setStepperState(2);
        setProgressPercentage(67);
        if (localStorage.dviReviewStatus) {
          console.log(localStorage.dviReviewStatus);
        }
        break;
      case Paths.Payment:
        setIsInitialQuote(false);
        setDisplayStepper(true);
        setStepperState(3);
        setProgressPercentage(83);
        break;
      case Paths.Welcome:
        setIsInitialQuote(false);
        setDisplayStepper(true);
        setStepperState(4);
        setProgressPercentage(100);
        break;
      default:
        setDisplayStepper(false);
        break;
    }
  }, [location.pathname]);

  return (
    <>
      <Flex justify="space-between">
        <Flex align={"center"}>
          <Image
            className="headerStyle"
            radius="xs"
            w={"228px"}
            h={"40px"}
            fit="contain"
            src={logo}
          />
        </Flex>
        <div>{displaySignOutButton()}</div>
      </Flex>
      <div className="headerDivider">
        <Divider my="lg" />
      </div>
      {displayStepper ?
        <div className="full-container">
          <div className="content-container">
            <StepperComponent activeState={stepperState} isInitialQuote={isInitialQuote} />
            {isInitialQuote ? null : (
              <Progress.Root
                size={15}
                radius={"md"}
                transitionDuration={700}
                styles={{ root: { marginTop: "18px" } }}
              >
                <Progress.Section value={progressPercentage} color={"#006B5B"}>
                  <Progress.Label>{`${progressPercentage}%`}</Progress.Label>
                </Progress.Section>
              </Progress.Root>
            )}
          </div>
        </div>
        : null
      }
    </>
  );
};
