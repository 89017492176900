import { React } from "react";
import { Helmet } from "react-helmet";

export const PageTitleMetaTags = (props) => {

	return (
    <div> 
      <Helmet> 
        <title>{props.pageTitle}</title> 
        <meta name="description" content={props.pageDescription} /> 
        <meta property="og:title" content={props.metaTitle} /> 
        <meta property="og:description" content={props.metaDescription} /> 
        <meta property="og:image" content={props.metaImage} /> 
      </Helmet> 
    </div>
	);
};
