import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Container, Group, Stack, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Carousel } from "@mantine/carousel";
import Autoplay from "embla-carousel-autoplay";
import { TestimonialCard } from "./TestimonialCard";
import { Rating } from "../Rating/Rating";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";

export const Testimonials = () => {
  const autoplay = useRef(Autoplay({ delay: 5000 }));

  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });

  const testimonialData = [
    {
      review:
        "The folks at Christian Brothers are the BEST! Michael did an excellent job at diagnosing my problem and Brent kept me up to date on the progress and was such a wonderful person to speak to on the phone. Did I mention they dropped me off and even picked me up to get my car when it was ready. I would highly recommend Christian Brothers for any car repairs.",
      customer: "Rich C., Surprise, AZ",
    },
    {
      review:
        "They fixed a problem with our truck that has not been working properly for years. 3 dealers couldn’t fix it. We are grateful to them. Professional organization and proactive with frequent updates and texts and even called the next day to follow up again. Excellent business!!!",
      customer: "Doug F., Knoxville, TN",
    },
    {
      review:
        "They picked up my car from my job a couple miles away from the shop. Their texting/online approval and payment process is very easy to use. They had my car done quickly and the work was great. I highly recommend them!",
      customer: "Bridget H., Peoria, AZ",
    },
    {
      review:
        "I took my car there to be serviced under my car's extended warranty. They assessed what needed to be done, filed the claim, and took care of all the necessary paperwork. They were able to get all the repairs done and the car back to me seamlessly. Their staff communicates well and is very friendly. The shuttle is a nice perk too. I will most definitely be using them in the future!",
      customer: "Ashley H., Tulsa, OK",
    },
  ];

  return (
    <>
      <Container size={"responsive"} px={0} id="reviews">
        <Group justify="center">
          <Stack p={isMobile ? "48px 6%" : "6.8% 0 5.3% 0"} gap={24}>
            <Stack ta={"center"} maw="650px">
              <Title ff="Crimson Text" fz="40px" lh={"120%"}>
                {t("testimonials-title")}
              </Title>
              <Text ff="Montserrat" fz="20px" lh="165%" c="#495057">
                {t("testimonials-description")}
              </Text>
            </Stack>
            <Rating />
          </Stack>
          <VideoPlayer video_url={"https://vimeo.com/991739365?"} />
        </Group>
        <Carousel
          withControls={false}
          slideSize={{ base: "100%", sm: "50%", md: "25%" }}
          slideGap={{ base: 0, sm: "md" }}
          slidesToScroll={{ base: 1, sm: 2, md: 4 }}
          plugins={[autoplay.current]}
          align={"start"}
          loop
        >
          {testimonialData.map((card, index) => (
            <React.Fragment key={index}>
              <Carousel.Slide>
                <TestimonialCard {...card} />
              </Carousel.Slide>
            </React.Fragment>
          ))}
        </Carousel>
      </Container>
    </>
  );
};
