import React from "react";
import {
  Anchor,
  Container,
  Flex,
  Group,
  Image,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useTranslation, Trans } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";
import Awards from "../../assets/images/jd-power-awards-opt.webp";

export const TrustedBy = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });

  const statsCard = (headerLarge, headerSmall, subheader, text = null) => {
    return (
      <Paper w="374px" h="185px" bg="#004C3D">
        <Flex direction="column" gap={0}>
          <Group>
            <Text
              styles={{
                root: {
                  fontFamily: "Crimson Text",
                  fontSize: "56px",
                  color: "#F5A800",
                  marginLeft: "82px",
                  marginTop: text ? "28px" : "40px",
                },
              }}
            >
              {headerLarge}
            </Text>
            <Text
              styles={{
                root: {
                  fontFamily: "Crimson Text",
                  fontSize: "24px",
                  color: "#F5A800",
                  marginLeft: "-14px",
                  marginTop: text ? "45px" : "57px",
                },
              }}
            >
              {headerSmall}
            </Text>
          </Group>
          <Text
            styles={{
              root: {
                fontFamily: "Montserrat",
                fontSize: "18px",
                fontWeight: 600,
                color: "#FFF",
                marginTop: "-20px",
                marginLeft: "85px",
              },
            }}
          >
            {subheader}
          </Text>
          <Text
            styles={{
              root: {
                fontFamily: "Montserrat",
                fontSize: "16px",
                color: "#FFF",
                marginLeft: "85px",
              },
            }}
          >
            {text}
          </Text>
        </Flex>
      </Paper>
    );
  };

  const jdPowerLink = () => {
    return (
      <Anchor
        underline="hover"
        target="_blank"
        href="https://www.jdpower.com/awards"
        ff="Montserrat"
        fz="10px"
      >
        {t("jd-power-fine-print")}
      </Anchor>
    );
  };

  return (
    <Container size={"responsive"} px={0} id="trusted_by">
      <Stack
        p={isMobile ? "48px 24px" : "6.1% 10% 2.9% 10%"}
        gap={isMobile ? "32px" : "56px"}
      >
        <Container size={"responsive"} px={0} ta={"center"}>
          <Title ff="Crimson Text" fz="40px">
            {t("trusted-by-header")}
          </Title>
          <Text ff="Montserrat" fz="18px" lh="160%" c="#495057">
            {t("trusted-by-subheader")}
          </Text>
        </Container>
        <Container size={"responsive"} px={0}>
          <Group gap={1} justify="center">
            {statsCard("300", "+", t("locations-nationwide"))}
            {statsCard("15.1", t("million"), t("vehicles-serviced"))}
            {statsCard(
              "40",
              "+",
              t("years-servicing-vehicles"),
              t("founded-1982")
            )}
          </Group>
        </Container>
        <Group justify="center">
          <Image src={Awards} h="160px" w="273px" />
          <Stack>
            <Text
              maw="560px"
              styles={{
                root: {
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: 600,
                  textAlign: "center",
                },
              }}
            >
              {t("jd-power")}
            </Text>
            <Text
              maw="560px"
              styles={{
                root: {
                  fontFamily: "Montserrat",
                  fontSize: "10px",
                  textAlign: "center",
                },
              }}
            >
              <Trans
                i18nKey={"jd-power-fine-print"}
                t={t}
                values={{ jdPowerLink }}
                components={{ awardsLink: jdPowerLink() }}
              />
            </Text>
          </Stack>
        </Group>
      </Stack>
    </Container>
  );
};
