import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Flex, Image, Stack, Text } from "@mantine/core";
import icon from "../../assets/images/tabler-icon-star-filled.svg";

export const Rating = () => {
	const { t } = useTranslation();

	return (
		<>
			<Container size={"responsive"} px={0}>
				<Stack ta={"center"} gap={4}>
					<Flex justify={"center"}>
						<Image src={icon} p={4} style={{ width: "56px", height: "56px" }} />
						<Image src={icon} p={4} style={{ width: "56px", height: "56px" }} />
						<Image src={icon} p={4} style={{ width: "56px", height: "56px" }} />
						<Image src={icon} p={4} style={{ width: "56px", height: "56px" }} />
						<Image src={icon} p={4} style={{ width: "56px", height: "56px" }} />
					</Flex>
					<Text ff="Montserrat" fz="14px" fw={400} lh="145%" c="#868E96">
						{t("google-reviews")}
					</Text>
				</Stack>
			</Container>
		</>
	);
};
