import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Center, Container, Stack, Text, Title } from "@mantine/core";
import { HowItWorksCard } from "./HowItWorksCard";
import icon1 from "../../assets/images/Number 1.svg";
import icon2 from "../../assets/images/Number 2.svg";
import icon3 from "../../assets/images/Number 3.svg";
import image1 from "../../assets/images/Step1Image.jpg";
import image2 from "../../assets/images/review-your-inspection.png";
import image3 from "../../assets/images/you're-covered.png";
import { useMediaQuery } from "@mantine/hooks";

export const HowItWorks = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });

  const handleScrollToTop = () => {
    const section = document.getElementById("start_quote");
    const offset = 10;
    const sectionPosition = section.getBoundingClientRect().top;
    const offsetPosition = sectionPosition + window.scrollY - offset;

    window.scrollTo({ top: offsetPosition, behavior: "smooth" });
  };

  const cardsData = [
    {
      image: image1,
      icon: icon1,
      title: "get-a-quote",
      text: "get-a-quote-description",
    },
    {
      image: image2,
      icon: icon2,
      title: "review-your-inspection",
      text: "review-your-inspection-description",
    },
    {
      image: image3,
      icon: icon3,
      title: "lock-in-rates-and-buy",
      text: "lock-in-rates-and-buy-description",
    },
  ];

  return (
    <Container size={"responsive"} px={0} bg={"#F8F9FA"} id="enrollment_steps">
      <Stack
        p={isMobile ? "48px 24px" : "6.1% 20% 2.9% 20%"}
        gap={isMobile ? "32px" : "56px"}
      >
        <Container size={"responsive"} px={0} ta={"center"}>
          <Title ff="Crimson Text" fz="40px">
            {t("how-it-works-title")}
          </Title>
          <Title ff="Crimson Text" fz="40px">
            {t("three-simple-steps")}
          </Title>
          <Text ff="Montserrat" fz="18px" lh="160%" c="#495057">
            {t("how-it-works-description")}
          </Text>
        </Container>
        <Container size={"responsive"} px={0}>
          {cardsData.map((card, index) => (
            <React.Fragment key={index}>
              <HowItWorksCard {...card} />
              {index < cardsData.length - 1 && (
                <Center>
                  <div
                    style={{
                      width: "2px",
                      height: "32px",
                      borderLeft: "2px dotted #006B5B",
                    }}
                  />
                </Center>
              )}
            </React.Fragment>
          ))}
        </Container>
        <Text ff="Montserrat" fz="18px" ta="center" lh="160%">
          {t("vehicle-inspection-footnote")}
        </Text>
        {isMobile ? (
          <Button
            onClick={handleScrollToTop}
            radius={4}
            fullWidth
            color="#FF671D"
            className="button"
          >
            {t("get-your-quote")}
          </Button>
        ) : (
          <Container size={"responsive"}>
            <Button
              onClick={handleScrollToTop}
              radius={4}
              size="md"
              color="#FF671D"
              className="button"
            >
              {t("get-your-quote")}
            </Button>
          </Container>
        )}
      </Stack>
    </Container>
  );
};
