import { useTranslation } from "react-i18next";
import React from "react";
import {
  Container,
  Divider,
  Flex,
  Group,
  Image,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import CarePlus from "../../assets/images/careplus.svg";

export const SavingsCard = (props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });
  const cardWidth = isMobile
    ? "375px"
    : props.size === "standard"
    ? "376px"
    : "576px";

  const cardContent = () => {
    return (
      <Stack>
        <Group gap="8px">
          <Title ff="Crimson Text" fz="24px" fw={600} lh="32px">
            {t("save-with")}
          </Title>
          <Image h="20px" src={CarePlus} />
        </Group>
        <Group justify="space-between">
          <Text ff="Montserrat" fz="18px" fw={400} lh="28px">{t("grand-total")}</Text>
          <Text ff="Montserrat" fz="18px" fw={600} lh="28px" td="line-through">{props.payments.grandTotal}</Text>
        </Group>
        <Group justify="space-between">
          <Stack gap={0}>
            <Text ff="Montserrat" fz="18px" fw={400} lh="28px">{t("stephen-paid")}</Text>
            <Text ff="Montserrat" fz="10px" fw={400} lh="14px">{t("stephen-no-deductible")}</Text>
          </Stack>
          <Text ff="Montserrat" fz="18px" fw={600} lh="28px">{props.payments.amountPaid}</Text>
        </Group>
        <Divider />
        <Group justify="space-between">
          <Text ff="Montserrat" fz="18px" fw={400} lh="28px">{t("care-plus-covered")}</Text>
          <Text ff="Montserrat" fz="18px" fw={600} lh="28px" c="#F5A800">{props.payments.covered}</Text>
        </Group>
      </Stack>
    );
  };

  return (
    <Paper
      radius="4px"
      w={cardWidth}
      styles={{
        root: {
          background: "#FFF",
          borderColor: "#F5A800",
          borderWidth: "2px",
          marginBottom: "24px",
        },
      }}
      withBorder
      shadow="lg"
    >
      <Container p="24px">{cardContent()}</Container>
    </Paper>
  );
};
