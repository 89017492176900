import { Image, Center, Loader } from "@mantine/core";
import { GlobalContext } from "../context/GlobalContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../components/CheckoutForm.jsx/CheckoutForm";
import { React, useContext, useEffect } from "react";
import stripelogo from "../assets/images/ND Care+ Black & Gold.jpg";
import { PreviousButton } from "../components/PreviousButton/PreviousButton";
import { Paths } from "../models/constants/paths";
import { StripePaymentBreakdown } from "../components/StripePaymentBreakdown/StripePaymentBreakdown";
import { useNavigate } from "react-router-dom";
import { defaultContext } from "../models/constants/defaultContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageTitleMetaTags } from "../components/PageTitleMetaTags/PageTitleMetaTags";
import { AzureBlobFiles } from "../models/constants/azureBlobFiles";

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_API_KEY));

export const Payment = () => {
  const { context, setContext } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const pageTitle = t("browser-tab-titles.payment");
  const pageDescription = t("browser-tab-descriptions.payment");
  const metaTitle = t("browser-meta-titles.payment");
  const metaDescription = t("browser-meta-descriptions.payment");

  const options = {
    clientSecret: context.stripe_subscription_data.stripe_client_secret,
    appearance: {},
  };

  useEffect(() => {
    if (!context.completed_pages.agreements) {
      setContext(defaultContext);
      navigate(Paths.Home);
    } else {
      setIsLoading(false);
    }
  }, []);

  return isLoading? (
    <div className="full-container">
      <div className="content-container">
        <Center>
          <Loader color="#006B5B" size="xl" />
        </Center>
      </div>
    </div>
  ) : (
    <div className="full-container">
      <PageTitleMetaTags
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        metaImage={AzureBlobFiles.MetaImage}
      />
      <div className="content-container">
        <Image
          className="stripelogo"
          radius="xs"
          h={"180px"}
          fit="contain"
          src={stripelogo}
        />
        <PreviousButton path={Paths.Agreements} />
        <StripePaymentBreakdown />
        <div className="header"></div>
        {context.stripe_subscription_data.stripe_client_secret && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </div>
  );
};
