import { AuthenticatedTemplate } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { Container } from "react-bootstrap";
import { IdTokenData } from "../components/DataDisplay/DataDisplay";
import { HeroHeader } from "../components/HeroHeader/HeroHeader";
import { TrustedBy } from "../components/TrustedBy/TrustedBy";
import { HowItWorks } from "../components/HowItWorks/HowItWorks";
import { BrochureBenefits } from "../components/BrochureBenefits/BrochureBenefits";
import { EligibleVehicles } from "../components/EligibleVehicles/EligibleVehicles";
import { SaveOnRepairs } from "../components/SaveOnRepairs/SaveOnRepairs";
import { CarePlusVs } from "../components/CarePlusVs/CarePlusVs";
import { WhatsCovered } from "../components/WhatsCovered/WhatsCovered";
import { Testimonials } from "../components/Testimonials/Testimonials";
import { FrequentlyAskedQuestions } from "../components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import { InitialQuoteForm } from "../components/InitialQuoteForm/InitialQuoteForm";
import { BrochureFooterImage } from "../components/BrochureFooter/BrochureFooterImage";
import { AppShell } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { React } from "react";
import { useLocation } from "react-router-dom";
import { BrochureQuoteImage } from "../components/BrochureQuoteImage/BrochureQuoteImage";
import { BrochureVideo } from "../components/BrochureVideo/BrochureVideo";
import { useTranslation } from "react-i18next";
import { BrochureHeader } from "../components/BrochureHeader/BrochureHeader";
import { PageTitleMetaTags } from "../components/PageTitleMetaTags/PageTitleMetaTags";
import { AzureBlobFiles } from "../models/constants/azureBlobFiles";
import { BrochureBanner } from "../components/BrochureBanner/BrochureBanner";

/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const Home = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const year = queryParams.get("year");
  const make = queryParams.get("make");
  const model = queryParams.get("model");
  const mileage = queryParams.get("mileage");
  const vin = queryParams.get("vin");
  const pageTitle = t("browser-tab-titles.home");
  const pageDescription = t("browser-tab-descriptions.home");
  const metaTitle = t("browser-meta-titles.home");
  const metaDescription = t("browser-meta-descriptions.home");

  const renderInitialQuoteForm = () => {
    return isMobile ? (
      <InitialQuoteForm
        year={year}
        make={make}
        model={model}
        mileage={mileage}
        vin={vin}
      />
    ) : null;
  };

  return (
    <AppShell header={{ height: 83 }} padding="md">
      <PageTitleMetaTags
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        metaImage={AzureBlobFiles.MetaImage}
      />
      <BrochureBanner />
      <BrochureHeader />
      <AuthenticatedTemplate>
        {activeAccount ? (
          <Container>
            <IdTokenData idTokenClaims={activeAccount.idTokenClaims} />
          </Container>
        ) : null}
      </AuthenticatedTemplate>

      <HeroHeader year={year} make={make} model={model} mileage={mileage} />
      <BrochureVideo video_url={"https://vimeo.com/1014866027"} />
      {renderInitialQuoteForm()}
      <BrochureQuoteImage
        year={year}
        make={make}
        model={model}
        mileage={mileage}
        vin={vin}
      />
      <HowItWorks />
      <TrustedBy />
      <BrochureBenefits />
      <EligibleVehicles />
      <SaveOnRepairs />
      <CarePlusVs />
      <WhatsCovered />
      <FrequentlyAskedQuestions />
      <Testimonials />
      {renderInitialQuoteForm()}
      <BrochureFooterImage
        year={year}
        make={make}
        model={model}
        mileage={mileage}
        vin={vin}
      />
    </AppShell>
  );
};
