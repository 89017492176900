import { useTranslation } from "react-i18next";
import { QuestionBox } from "../QuestionBox/QuestionBox";
import { React } from "react";

export const VehicleFeatures = (props) => {
  const { t } = useTranslation();

  function displayVinVerified() {
    if (props.vinVerified) {
      return (
        <>
          <QuestionBox
            id={"lift_kit_over_six_inches"}
            header={t("suspension-lift-kit")}
          />
          <QuestionBox
            id={"aftermarket_enhancements"}
            header={t("aftermarket-branded-titles-enhancements")}
            subheader={t("aftermarket-branded-titles-examples")}
          />
        </>
      );
    } else {
      return (
        <>
          <QuestionBox id={"awd"} header={t("all-wheel-drive")} />
          <QuestionBox id={"diesel"} header={t("diesel-engine")} />
          <QuestionBox
            id={"lift_kit_over_six_inches"}
            header={t("suspension-lift-kit")}
          />
          <QuestionBox
            id={"oem_turbo_or_super"}
            header={t("turbocharger-or-supercharger")}
            subheader={t("manufacturers-site")}
          />
          <QuestionBox
            id={"aftermarket_enhancements"}
            header={t("aftermarket-branded-titles-enhancements")}
            subheader={t("aftermarket-branded-titles-examples")}
          />
        </>
      );
    }
  }

  return (
    <>
      <div className="header">
        <div className="header_3">{t("vehicle-features")}</div>
      </div>
      {displayVinVerified()}
    </>
  );
};
