import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Stack, TextInput, NumberInput, Button, Select, Group } from "@mantine/core";
import { useNavigate } from "react-router";
import { useForm } from "@mantine/form";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { defaultContext } from "../../models/constants/defaultContext";
import { usStates } from "../../models/constants/usStates";
import { Paths } from "../../models/constants/paths";


export const LicensePlateForm = () => {
  const {t} = useTranslation();
  const { context, setContext } = useContext(GlobalContext);
  const [license_plate, setLicensePlate] = useState("");
  const [state, setState] = useState("");
  const [mileage, setMileage] = useState("");
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      license_plate:
        null || context.quote_form_data.license_plate,
      state: null || context.quote_form_data.state,
      mileage: null || context.quote_form_data.mileage,
    },
    validate: {
      license_plate: (value) => (!value ? t("license-plate-required") : null),
      state: (value) => (!value ? t("state-required") : null),
      mileage: (value) => (!value ? t("vehicle-mileage-required") : null),
    },
  });

  const autoFillForm = (license_plate, state, mileage) => {
    form.setFieldValue("license_plate", license_plate);
    setLicensePlate(license_plate);
    form.setFieldValue("state", state);
    setState(state);
    form.setFieldValue("mileage", mileage);
    setMileage(mileage);
  };

  const submitForm = (formData) => {
    if (form.isValid()) {
      setContext({
        ...context,
        vehicle_data: {
          ...context.vehicle_data,
          license_plate: formData.license_plate,
          state: formData.state,
          mileage: formData.mileage
        },
        quote_form_data: {
          ...context.quote_form_data,
          license_plate: formData.license_plate,
          state: formData.state,
          mileage: formData.mileage
        },
        completed_pages: { ...context.completed_pages, home: true },
      });
      navigate(Paths.Questionnaire);
    }
  };

  useEffect(() => {
    if (
      context.quote_form_data.license_plate &&
      context.quote_form_data.state &&
      context.quote_form_data.mileage
    ) {
      autoFillForm(
        context.quote_form_data.license_plate,
        context.quote_form_data.state,
        context.quote_form_data.mileage
      );
    } 
  }, []);

  useEffect(() => {
    if (form.values.state != context.quote_form_data.state) {
      setContext({
        ...defaultContext,
        quote_form_data: context.quote_form_data,
      });
    }
    setState(form.values.state);
  }, [form.values.state]);

  useEffect(() => {
    if (form.values.license_plate != context.quote_form_data.license_plate) {
      setContext({
        ...defaultContext,
        quote_form_data: context.quote_form_data,
      });
    }
    setLicensePlate(form.values.license_plate);
  }, [form.values.license_plate]);

  useEffect(() => {
    if (
      form.values.mileage &&
      form.values.mileage !== context.vehicle_data.mileage
    ) {
      setContext({
        ...defaultContext,
        quote_form_data: context.quote_form_data,
      });
    }
    setMileage(form.values.mileage);
  }, [form.values.mileage]);

  const stateFilter = ({ options, search }) => {
    return options.filter((option) => {
      return option.label.toLowerCase().trim().startsWith(search.toLowerCase());
    })
  };

  return (
    <form id="licensePlateForm" onSubmit={form.onSubmit((values) => submitForm(values))}>
      <Stack gap={16} ff={"Montserrat"} fz={16} fw={600} lh={"155%"}>
        <Group gap={16} grow>
          <TextInput
            label={t("license-plate")}
            placeholder={t("license-plate")}
            size="lg"
            maxLength={7}
            value={license_plate}
            {...form.getInputProps("license_plate")}
          />
          <Select
            label={"State"}
            placeholder={"State"}
            size="lg"
            data={usStates}
            value={state}
            clearable
            searchable
            filter={stateFilter}
            withCheckIcon={false}
            styles={{
              dropdown: {
                fontFamily: "Montserrat",
                fontSize: "16px",
              },
            }}
            {...form.getInputProps("state")}
          />
        </Group>
        <NumberInput
          label={t("mileage")}
          placeholder={t("mileage")}
          maxLength={7}
          allowDecimal={false}
          allowNegative={false}
          allowLeadingZeros={false}
          thousandSeparator=","
          hideControls
          size="lg"
          value={mileage}
          {...form.getInputProps("mileage")}
        />
      </Stack>
      <Button type="submit" fullWidth color="#FF671D" className="button">
        {t("get-your-quote")}
      </Button>
    </form>
  );
};
