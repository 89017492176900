import { useTranslation } from "react-i18next";
import React from "react";
import {
  Button,
  Container,
  Flex,
  Group,
  Modal,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import { useMediaQuery, useDisclosure } from "@mantine/hooks";
import { PDFModal } from "./PDFModal";
import { AzureBlobFiles } from "../../models/constants/azureBlobFiles";

export const RepairsCard = (props) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });

  const repairCard = (icon, repair) => {
    return (
      <Paper withBorder radius="4px" mih="72px">
        <Group
          align="center"
          gap="16px"
          styles={{
            root: { padding: isMobile ? "16px 8px 8px 16px" : "16px" },
          }}
        >
          {icon}
          <Text
            ff="Montserrat"
            fz={isMobile ? "12px" : "20px"}
            fw={600}
            lh="165%"
            styles={{ root: {whiteSpace: "normal"}}}
          >
            {repair}
          </Text>
        </Group>
      </Paper>
    );
  };

  return (
    <Paper
      radius="4px"
      maw="576px"
      mb="24px"
      styles={{
        root: {
          background: "#FFF",
          borderColor: "#006B5B",
          borderWidth: "2px",
        },
      }}
      withBorder
    >
      <Container p="16px 24px 24px 16px">
        <Flex direction="column" gap="16px">
          <Title ff="Crimson Text" fz="24px" fw={600} lh="32px">
            {t("repairs")}
          </Title>
          {props.repairs.map((repair) => {
            return repairCard(repair.repairIcon, repair.repairName);
          })}
        </Flex>
        <Button
          onClick={open}
          size="compact-xs"
          variant="transparent"
          color="orange"
          mt="16px"
        >
          {props.linkText}
        </Button>
      </Container>
      <Modal fullScreen opened={opened} onClose={close}>
        <PDFModal url={AzureBlobFiles.SampleRO}/>
      </Modal>
    </Paper>
  );
};
