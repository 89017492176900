import {
  Flex,
  Button,
  Modal,
  Center,
  Text,
  Menu,
  Avatar,
  Group,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { IconAlertCircleFilled } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { useState, useContext } from "react";
import { loginRequest } from "../../authConfig";
import { GlobalContext } from "../../context/GlobalContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Paths } from "../../models/constants/paths";
import "./SignOutModal.scss";

export const SignOutModal = () => {
  const { t } = useTranslation();
  const { context } = useContext(GlobalContext);
  const { instance } = useMsal();
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }
  const location = useLocation();
  const displayMenu = location.pathname === Paths.Home;
  const navigate = useNavigate();
  const [acctOpened, setAcctOpened] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });

  const modalHeader = (
    <Flex gap="8px">
      <IconAlertCircleFilled />
      <Center>
        <Text className="sign-out-title">{t("confirm-sign-out")}</Text>
      </Center>
    </Flex>
  );

  const handleSignIn = () => {
    sessionStorage.setItem("sign-in", "true");
    let contextJSON = context;
    contextJSON.completed_pages.final_estimated_quote = true;
    localStorage.setItem("context", JSON.stringify(contextJSON));
    instance.loginRedirect(loginRequest).catch((error) => {
      console.log(error);
      navigate(Paths.Error);
    });
  };

  const handleReturnToQuote = () => {
    let contextJSON = context;
    contextJSON.completed_pages.final_estimated_quote = true;
    localStorage.setItem("context", JSON.stringify(contextJSON));
    instance.loginRedirect(loginRequest).catch((error) => {
      console.log(error);
      navigate(Paths.Error);
    });
  };

  return (
    <>
      <Modal
        centered
        opened={opened}
        size="sm"
        onClose={close}
        title={modalHeader}
      >
        <div className="sign-out-text">{t("confirm-sign-out-text")}</div>
        <Flex justify="center" gap={"8px"} mt={"8px"}>
          <Button
            size="md"
            color="#ff671d"
            title={t("sign-out")}
            onClick={() => instance.logoutRedirect()}
          >
            <div className="sign-out-button">{t("sign-out")}</div>
          </Button>
          <Button
            variant="outline"
            size="md"
            color="#006b5b"
            title={t("sign-out")}
            onClick={close}
          >
            <div className="sign-out-button">{t("cancel")}</div>
          </Button>
        </Flex>
      </Modal>
      {displayMenu ? (
        <Menu
          opened={acctOpened}
          onChange={setAcctOpened}
          radius={0}
          offset={13}
          transitionProps={{ transition: "scale-y" }}
          styles={{
            dropdown: { background: "#006B5B", border: "#006B5B" },
            item: {
              fontFamily: "Montserrat",
              color: "white",
              fontSize: "16pt",
              fontWeight: 600,
              padding: "24px 48px 24px 48px",
            },
          }}
        >
          <Menu.Target>
            <Avatar
              size="lg"
              color="#F5A800"
              variant="transparent"
              styles={{ root: { cursor: "pointer" } }}
            />
          </Menu.Target>
          <Menu.Dropdown>
            {activeAccount ? (
              <Menu.Item color="#006B5B" onClick={open}>
                {t("sign-out")}
              </Menu.Item>
            ) : (
              <Menu.Item color="#006B5B" onClick={() => handleSignIn()}>
                {t("sign-in")}
              </Menu.Item>
            )}
            <Menu.Item
              color="#006B5B"
              onClick={() =>
                window.open("https://my.cbac.com", "_blank").focus()
              }
            >
              {t("manage-my-account")}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ) : (
        <Group className="logout-button-pages" position="center">
          <Button
            size={isMobile ? "xs" : "md"}
            className="sign-out-space"
            color="#ff671d"
            title={t("sign-out")}
            onClick={open}
          >
            {t("sign-out")}
          </Button>
        </Group>
      )}
    </>
  );
};
