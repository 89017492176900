export const boldText = (text) => {
  return <span style={{ fontWeight: "700" }}>{text}</span>;
};

export const italicText = (text) => {
  return <span style={{ fontStyle: "italic"}}>{text}</span>
}

export const boldItalicText = (text) => {
  return <span style={{ fontStyle: "italic", fontWeight: "700" }}>{text}</span>;
};

export const boldGreenText = (text) => {
  return <span style={{ fontWeight: "700", color: "#006B5B" }}>{text}</span>;
}

export const boldOrangeText = (text) => {
  return <span style={{ fontWeight: "700", color: "#FF671D" }}>{text}</span>
}