import { Button, Center, Loader } from "@mantine/core";

export const ContinueButton = ({ label, clickAction, disabled, clicked }) => {
  return (
    <Button
      className="button"
      disabled={disabled}
      fullWidth
      color="#FF671D"
      style={{ marginBottom: "32px" }}
      onClick={clickAction}
    >
      {clicked ? <Center><Loader color="#006B5B"/></Center> : label}
    </Button>
  );
};
