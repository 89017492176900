import { useTranslation } from "react-i18next";
import React from "react";
import {
  Container,
  Flex,
  Image,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export const BrochureBenefitCard = (props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {getInitialValueInEffect: false});
  const cardWidth = isMobile ? "375px" : props.size === "standard" ? "376px" : "576px";

  const cardIcon = () => {
    return <Image src={props.icon} style={{ width: "56px", height: "56px", background: "#F7FFFE", border: "solid #006B5B 1px", borderRadius: "4px", padding: "8px" }} />;
  };

  const cardContent = () => {
    return props.size === "standard" || isMobile ? (
      <Stack gap="16px">
        {cardIcon()}
        <Stack gap="8px">
          <Title ff="Montserrat" fz="20px" fw={600} lh="165%">
            {t(props.title)}
          </Title>
          <Text ff="Montserrat" fz="18px" fw={400} lh="160%">
            {t(props.text)}
          </Text>
        </Stack>
      </Stack>
    ) : (
      <Flex direction="row" gap="16px">
        {cardIcon()}
        <Stack gap="8px">
          <Title ff="Montserrat" fz="20px" fw={600} lh="165%">
            {t(props.title)}
          </Title>
          <Text ff="Montserrat" fz="18px" fw={400} lh="160%">
            {t(props.text)}
          </Text>
        </Stack>
      </Flex>
    );
  };


  return (
    <Paper
      radius="4px"
      w={cardWidth}
      style={{ background: "#FFF", borderColor: props.borderColor, borderWidth: "2px" }}
      withBorder
    >
      <Container p="32px">{cardContent()}</Container>
    </Paper>
  );
};
