import React from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Stack,
  Text,
  Title,
  Group,
  Image,
  BackgroundImage,
  Center,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Paths } from "../../models/constants/paths";
import { useLocation } from "react-router-dom";
import CarePlus from "../../assets/images/careplus.svg";
import SaveOnRepairsBackground from "../../assets/images/SaveOnRepairsBackground.png";
import { IssueCard } from "./IssueCard";
import { RepairsCard } from "./RepairsCard";
import { SavingsCard } from "./SavingsCard";
import { BrochureBenefitCard } from "../BrochureBenefits/BrochureBenefitCard";
import IconNoCostDeductible from "../../assets/images/No Cost Deductibles for Vets, First Responders, and active military copy.png";
import { IconCheckupList } from "@tabler/icons-react";
import StephenVideoIcon from "../../assets/images/StephenVideoIcon.png";

export const SaveOnRepairs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const isMobile = useMediaQuery("(max-width: 1425px)", true, {
    getInitialValueInEffect: false,
  });

  const repairCases = {
    coveredRepair: {
      issueName: "Stephen's Issue",
      issueDescription:
        "Stephen came in for a transmission check when he noticed a slight surge/delay when starting to accelerate. He noted the issue was subtle with no jerking while accelerating.",
      issueIcon: StephenVideoIcon,
      repairs: [
        {
          repairName: "Test & Inspect Diagnostic",
          repairIcon: (
            <IconCheckupList
              style={{
                width: "40px",
                height: "40px",
                background: "#F7FFFE",
                borderRadius: "4px",
                padding: "8px",
              }}
            />
          ),
        },
        {
          repairName: "Replaced Transmission Assembly",
          repairIcon: (
            <IconCheckupList
              style={{
                width: "40px",
                height: "40px",
                background: "#F7FFFE",
                borderRadius: "4px",
                padding: "8px",
              }}
            />
          ),
        },
        {
          repairName: "Flash Computer Programming",
          repairIcon: (
            <IconCheckupList
              style={{
                width: "40px",
                height: "40px",
                background: "#F7FFFE",
                borderRadius: "4px",
                padding: "8px",
              }}
            />
          ),
        },
        {
          repairName: "Replaced Engine Oil Cooler Lines & Seal",
          repairIcon: (
            <IconCheckupList
              style={{
                width: "40px",
                height: "40px",
                background: "#F7FFFE",
                borderRadius: "4px",
                padding: "8px",
              }}
            />
          ),
        },
      ],
      linkText: "See Stephen's Claim",
      payments: {
        grandTotal: "$7931.60",
        amountPaid: "$0.00",
        covered: "$7931.60",
      },
      highlightedBenefit: {
        benefitName:
          "No Cost Deductibles for Military and First Responders, Veterans",
        benefitDescription:
          "Active Duty Military members, Veterans, EMTs, Law Enforcement, and Firefighters are never required to pay a deductible at a CBA service center. Deductibles otherwise start at $400.",
        benefitIcon: IconNoCostDeductible,
      },
    },
  };

  return isMobile ? (
    <Container size={"responsive"} px={0} bg={"#F8F9FA"}>
      <Stack p={"48px 6%"} gap={24}>
        <Stack ta={"center"}>
          <Group justify="center">
            <Title ff="Crimson Text" fz="40px" lh={"120%"}>
              {t("save-on-repairs")}
            </Title>
            <Image src={CarePlus} />
          </Group>
          <Text ff="Montserrat" fz="20px" lh="165%" c="#495057">
            {t("save-on-repairs-description")}
          </Text>
        </Stack>
        <Center>
          <Stack>
            <IssueCard
              icon={repairCases.coveredRepair.issueIcon}
              size="wide"
              title={repairCases.coveredRepair.issueName}
              text={repairCases.coveredRepair.issueDescription}
            />
            <RepairsCard
              repairs={repairCases.coveredRepair.repairs}
              linkText={repairCases.coveredRepair.linkText}
            />
            <SavingsCard payments={repairCases.coveredRepair.payments} />
            <BrochureBenefitCard
              title={repairCases.coveredRepair.highlightedBenefit.benefitName}
              text={
                repairCases.coveredRepair.highlightedBenefit.benefitDescription
              }
              icon={repairCases.coveredRepair.highlightedBenefit.benefitIcon}
              borderColor="#F5A800"
              borderWidth="2px"
            />
          </Stack>
        </Center>
      </Stack>
    </Container>
  ) : (
    <Container size={"responsive"} px={0} bg={"#F8F9FA"}>
      <Stack p={"6.8% 9% 5.3% 9%"} gap={24}>
        <Stack ta={"center"}>
          <Group justify="center">
            <Title ff="Crimson Text" fz="40px" lh={"120%"}>
              {t("save-on-repairs")}
            </Title>
            <Image src={CarePlus} />
          </Group>
          <Center>
            <Text ff="Montserrat" fz="20px" lh="165%" c="#495057" maw="1300px">
              {t("save-on-repairs-description")}
            </Text>
          </Center>
        </Stack>
        <Center>
          <BackgroundImage
            src={SaveOnRepairsBackground}
            h="800px"
            mt="24px"
            maw="1300px"
          >
            <Group justify="space-between">
              <Stack>
                <IssueCard
                  icon={repairCases.coveredRepair.issueIcon}
                  size="wide"
                  title={repairCases.coveredRepair.issueName}
                  text={repairCases.coveredRepair.issueDescription}
                />
                <RepairsCard
                  repairs={repairCases.coveredRepair.repairs}
                  linkText={repairCases.coveredRepair.linkText}
                />
              </Stack>
              <Stack>
                <SavingsCard payments={repairCases.coveredRepair.payments} />
                <BrochureBenefitCard
                  title={
                    repairCases.coveredRepair.highlightedBenefit.benefitName
                  }
                  text={
                    repairCases.coveredRepair.highlightedBenefit
                      .benefitDescription
                  }
                  icon={
                    repairCases.coveredRepair.highlightedBenefit.benefitIcon
                  }
                  borderColor="#F5A800"
                  borderWidth="2px"
                />
              </Stack>
            </Group>
          </BackgroundImage>
        </Center>
      </Stack>
    </Container>
  );
};
