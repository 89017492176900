import { Container } from "@mantine/core";
import { React } from "react";
import { VideoPlayer } from "../VideoPlayer/VideoPlayer";

export const VideoModal = (props) => {
  return (
    <Container size={"responsive"} px={0}>
      <VideoPlayer video_url={props.video_url}/>
    </Container>
  );
};
