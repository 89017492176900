import IconUnaddressedConditions from "../../assets/images/Unadressed Pre-Existing Conditions copy 2.svg";
import IconCollison from "../../assets/images/Collision copy 2.svg";
import IconAftermarketAlterations from "../../assets/images/Aftermarket Alterations copy 2.svg";
import IconDamagedTitles from "../../assets/images/Damaged Titles copy 2.svg";

export const categories = [
  "engine",
  "transmission",
  "driveline",
  "suspension",
  "steering",
  "braking-system",
  "ac-heating",
  "electrical",
  "emissions",
  "safety-systems",
  "interior",
  "exterior",
  "miscellaneous",
];

export const coveredComponents = [
  {category: "engine", translationKey: "components.covered.engine.active-shutter-actuator"},
  {category: "engine", translationKey: "components.covered.engine.active-shutter-module"},
  {category: "engine", translationKey: "components.covered.engine.active-shutters"},
  {category: "engine", translationKey: "components.covered.engine.air-charge-cooler"},
  {category: "engine", translationKey: "components.covered.engine.bearings"},
  {category: "engine", translationKey: "components.covered.engine.belt-tensioner"},
  {category: "engine", translationKey: "components.covered.engine.cam-timing-solenoid"},
  {category: "engine", translationKey: "components.covered.engine.camshaft"},
  {category: "engine", translationKey: "components.covered.engine.camshaft-bearings"},
  {category: "engine", translationKey: "components.covered.engine.camshaft-followers"},
  {category: "engine", translationKey: "components.covered.engine.connecting-rod-bearings"},
  {category: "engine", translationKey: "components.covered.engine.connecting-rods"},
  {category: "engine", translationKey: "components.covered.engine.coolant"},
  {category: "engine", translationKey: "components.covered.engine.coolant-recovery-tank"},
  {category: "engine", translationKey: "components.covered.engine.cooling-fan-module"},
  {category: "engine", translationKey: "components.covered.engine.crankshaft"},
  {category: "engine", translationKey: "components.covered.engine.crankshaft-main-bearings"},
  {category: "engine", translationKey: "components.covered.engine.cylinder-block"},
  {category: "engine", translationKey: "components.covered.engine.cylinder-deactivation-solenoid"},
  {category: "engine", translationKey: "components.covered.engine.cylinder-head"},
  {category: "engine", translationKey: "components.covered.engine.cylinder-head-gasket"},
  {category: "engine", translationKey: "components.covered.engine.diagnostic"},
  {category: "engine", translationKey: "components.covered.engine.diesel-injector-pump"},
  {category: "engine", translationKey: "components.covered.engine.dipstick-tube"},
  {category: "engine", translationKey: "components.covered.engine.electric-boost-cooling-pump"},
  {category: "engine", translationKey: "components.covered.engine.electric-water-pump"},
  {category: "engine", translationKey: "components.covered.engine.engine"},
  {category: "engine", translationKey: "components.covered.engine.engine-belt-tensioner"},
  {category: "engine", translationKey: "components.covered.engine.engine-block-short"},
  {category: "engine", translationKey: "components.covered.engine.engine-block-long"},
  {category: "engine", translationKey: "components.covered.engine.engine-cooling-diagnostic"},
  {category: "engine", translationKey: "components.covered.engine.engine-cooling-fan-motor"},
  {category: "engine", translationKey: "components.covered.engine.engine-fan-blade"},
  {category: "engine", translationKey: "components.covered.engine.engine-fuel-diagnostic"},
  {category: "engine", translationKey: "components.covered.engine.engine-mounts"},
  {category: "engine", translationKey: "components.covered.engine.ethanol-sensor"},
  {category: "engine", translationKey: "components.covered.engine.exhaust-manifold-gasket"},
  {category: "engine", translationKey: "components.covered.engine.exhaust-manifold"},
  {category: "engine", translationKey: "components.covered.engine.fan-clutch"},
  {category: "engine", translationKey: "components.covered.engine.fasteners-nuts-bolts"},
  {category: "engine", translationKey: "components.covered.engine.filters"},
  {category: "engine", translationKey: "components.covered.engine.flywheel"},
  {category: "engine", translationKey: "components.covered.engine.fuel-cooler"},
  {category: "engine", translationKey: "components.covered.engine.fuel-heater"},
  {category: "engine", translationKey: "components.covered.engine.fuel-injection-pump"},
  {category: "engine", translationKey: "components.covered.engine.fuel-injectors"},
  {category: "engine", translationKey: "components.covered.engine.fuel-lines"},
  {category: "engine", translationKey: "components.covered.engine.fuel-pressure-regulator"},
  {category: "engine", translationKey: "components.covered.engine.fuel-pump"},
  {category: "engine", translationKey: "components.covered.engine.fuel-pump-control-module"},
  {category: "engine", translationKey: "components.covered.engine.fuel-pump-relay"},
  {category: "engine", translationKey: "components.covered.engine.fuel-tank"},
  {category: "engine", translationKey: "components.covered.engine.fuel-tank-sending-unit"},
  {category: "engine", translationKey: "components.covered.engine.gasket-seal-kit"},
  {category: "engine", translationKey: "components.covered.engine.gaskets-seals-turbo-super"},
  {category: "engine", translationKey: "components.covered.engine.harmonic-balancer"},
  {category: "engine", translationKey: "components.covered.engine.head-bolts"},
  {category: "engine", translationKey: "components.covered.engine.heater-control-valve"},
  {category: "engine", translationKey: "components.covered.engine.heater-core"},
  {category: "engine", translationKey: "components.covered.engine.heater-core-pump"},
  {category: "engine", translationKey: "components.covered.engine.high-pressure-pump"},
  {category: "engine", translationKey: "components.covered.engine.high-pressure-oil-pump"},
  {category: "engine", translationKey: "components.covered.engine.high-pressure-oil-rails"},
  {category: "engine", translationKey: "components.covered.engine.intake-manifold"},
  {category: "engine", translationKey: "components.covered.engine.intake-tuning-actuator"},
  {category: "engine", translationKey: "components.covered.engine.intake-tuning-valve"},
  {category: "engine", translationKey: "components.covered.engine.intercooler-turbo-air-to-air"},
  {category: "engine", translationKey: "components.covered.engine.low-pressure-pump"},
  {category: "engine", translationKey: "components.covered.engine.manifold-plate"},
  {category: "engine", translationKey: "components.covered.engine.orings-gaskets-seals"},
  {category: "engine", translationKey: "components.covered.engine.oil"},
  {category: "engine", translationKey: "components.covered.engine.oil-cooler"},
  {category: "engine", translationKey: "components.covered.engine.oil-cooler-line"},
  {category: "engine", translationKey: "components.covered.engine.oil-filter"},
  {category: "engine", translationKey: "components.covered.engine.oil-filter-adapter-housing"},
  {category: "engine", translationKey: "components.covered.engine.oil-pan"},
  {category: "engine", translationKey: "components.covered.engine.oil-pan-gasket"},
  {category: "engine", translationKey: "components.covered.engine.oil-pump"},
  {category: "engine", translationKey: "components.covered.engine.oil-pump-control-solenoid"},
  {category: "engine", translationKey: "components.covered.engine.oil-pump-housing"},
  {category: "engine", translationKey: "components.covered.engine.pcv-system"},
  {category: "engine", translationKey: "components.covered.engine.pcv-valve"},
  {category: "engine", translationKey: "components.covered.engine.piston-rings"},
  {category: "engine", translationKey: "components.covered.engine.pistons"},
  {category: "engine", translationKey: "components.covered.engine.pulleys"},
  {category: "engine", translationKey: "components.covered.engine.push-rods"},
  {category: "engine", translationKey: "components.covered.engine.radiator"},
  {category: "engine", translationKey: "components.covered.engine.rear-main-seal"},
  {category: "engine", translationKey: "components.covered.engine.rocker-arms"},
  {category: "engine", translationKey: "components.covered.engine.rocker-arm-bushings"},
  {category: "engine", translationKey: "components.covered.engine.rocker-arm-shafts"},
  {category: "engine", translationKey: "components.covered.engine.rocker-cover"},
  {category: "engine", translationKey: "components.covered.engine.sealant-sealers"},
  {category: "engine", translationKey: "components.covered.engine.seals-gaskets"},
  {category: "engine", translationKey: "components.covered.engine.serpentine-belt-tensioner"},
  {category: "engine", translationKey: "components.covered.engine.supercharger"},
  {category: "engine", translationKey: "components.covered.engine.tappet-valve"},
  {category: "engine", translationKey: "components.covered.engine.tensioners"},
  {category: "engine", translationKey: "components.covered.engine.thermostat"},
  {category: "engine", translationKey: "components.covered.engine.thermostat-gasket"},
  {category: "engine", translationKey: "components.covered.engine.thermostat-housing"},
  {category: "engine", translationKey: "components.covered.engine.throttle-body"},
  {category: "engine", translationKey: "components.covered.engine.timing-belt"},
  {category: "engine", translationKey: "components.covered.engine.timing-belt-chain-guides"},
  {category: "engine", translationKey: "components.covered.engine.timing-chain-cover"},
  {category: "engine", translationKey: "components.covered.engine.timing-chain-gears"},
  {category: "engine", translationKey: "components.covered.engine.timing-chain-belt-cover"},
  {category: "engine", translationKey: "components.covered.engine.timing-chain-belt-idler-pulley"},
  {category: "engine", translationKey: "components.covered.engine.turbocharger"},
  {category: "engine", translationKey: "components.covered.engine.turbocharger-wastegate"},
  {category: "engine", translationKey: "components.covered.engine.turbocharger-wastegate-actuator"},
  {category: "engine", translationKey: "components.covered.engine.vacuum-pump"},
  {category: "engine", translationKey: "components.covered.engine.valley-pan"},
  {category: "engine", translationKey: "components.covered.engine.valley-pan-gasket"},
  {category: "engine", translationKey: "components.covered.engine.valve-cover-gaskets"},
  {category: "engine", translationKey: "components.covered.engine.valve-cover-oil-fill-cap"},
  {category: "engine", translationKey: "components.covered.engine.valve-covers"},
  {category: "engine", translationKey: "components.covered.engine.valve-guide-seal"},
  {category: "engine", translationKey: "components.covered.engine.valve-guides"},
  {category: "engine", translationKey: "components.covered.engine.valve-lifters"},
  {category: "engine", translationKey: "components.covered.engine.valve-retainers"},
  {category: "engine", translationKey: "components.covered.engine.valve-seats"},
  {category: "engine", translationKey: "components.covered.engine.valve-springs"},
  {category: "engine", translationKey: "components.covered.engine.valves"},
  {category: "engine", translationKey: "components.covered.engine.variable-valve-actuators"},
  {category: "engine", translationKey: "components.covered.engine.variable-valve-timing-solenoids"},
  {category: "engine", translationKey: "components.covered.engine.wastegate-vnt-control-actuator"},
  {category: "engine", translationKey: "components.covered.engine.water-in-fuel-sensor"},
  {category: "engine", translationKey: "components.covered.engine.water-pump"},
  {category: "engine", translationKey: "components.covered.engine.water-pump-control-module"},
  {category: "engine", translationKey: "components.covered.engine.water-pump-gasket"},
  {category: "engine", translationKey: "components.covered.engine.water-pump-housing"},
  {category: "engine", translationKey: "components.covered.engine.windage-tray"},
  {category: "transmission", translationKey: "components.covered.transmission.all-clutches-steels"},
  {category: "transmission", translationKey: "components.covered.transmission.bell-housing"},
  {category: "transmission", translationKey: "components.covered.transmission.drive-flex-plate"},
  {category: "transmission", translationKey: "components.covered.transmission.fasteners-nuts-bolts"},
  {category: "transmission", translationKey: "components.covered.transmission.gear-shifter-and-mechanism"},
  {category: "transmission", translationKey: "components.covered.transmission.internal-transaxle-solenoids"},
  {category: "transmission", translationKey: "components.covered.transmission.seals-gaskets"},
  {category: "transmission", translationKey: "components.covered.transmission.torque-converter"},
  {category: "transmission", translationKey: "components.covered.transmission.transaxle-case"},
  {category: "transmission", translationKey: "components.covered.transmission.transaxle-differential-assembly"},
  {category: "transmission", translationKey: "components.covered.transmission.transaxle-electronic-control-module"},
  {category: "transmission", translationKey: "components.covered.transmission.transaxle-speed-sensors"},
  {category: "transmission", translationKey: "components.covered.transmission.transmission-case"},
  {category: "transmission", translationKey: "components.covered.transmission.transmission-control-module"},
  {category: "transmission", translationKey: "components.covered.transmission.transmission-oil-pain"},
  {category: "transmission", translationKey: "components.covered.transmission.transmission-range-switch"},
  {category: "transmission", translationKey: "components.covered.transmission.transmission-transaxle-fluid"},
  {category: "driveline", translationKey: "components.covered.driveline.automatic-front-locking-hubs"},
  {category: "driveline", translationKey: "components.covered.driveline.axle-housing"},
  {category: "driveline", translationKey: "components.covered.driveline.axle-shaft-assemblies"},
  {category: "driveline", translationKey: "components.covered.driveline.axle-shaft-bearings"},
  {category: "driveline", translationKey: "components.covered.driveline.axle-shafts"},
  {category: "driveline", translationKey: "components.covered.driveline.center-support-bearings"},
  {category: "driveline", translationKey: "components.covered.driveline.crush-sleeve"},
  {category: "driveline", translationKey: "components.covered.driveline.cv-halfshaft-complete-and-boots-joints"},
  {category: "driveline", translationKey: "components.covered.driveline.differential-axle-complete"},
  {category: "driveline", translationKey: "components.covered.driveline.differential-control-module"},
  {category: "driveline", translationKey: "components.covered.driveline.differential-coupling-control-module"},
  {category: "driveline", translationKey: "components.covered.driveline.differential-coupling-housing-assembly"},
  {category: "driveline", translationKey: "components.covered.driveline.differential-housing-cover"},
  {category: "driveline", translationKey: "components.covered.driveline.disconnect-housing-assembly"},
  {category: "driveline", translationKey: "components.covered.driveline.drive-axle"},
  {category: "driveline", translationKey: "components.covered.driveline.drive-axle-diagnostic"},
  {category: "driveline", translationKey: "components.covered.driveline.drive-axle-housing"},
  {category: "driveline", translationKey: "components.covered.driveline.drive-shaft-assemblies"},
  {category: "driveline", translationKey: "components.covered.driveline.fasteners-nuts-bolts"},
  {category: "driveline", translationKey: "components.covered.driveline.fluids-additive"},
  {category: "driveline", translationKey: "components.covered.driveline.four-wheel-drive-actuator"},
  {category: "driveline", translationKey: "components.covered.driveline.locking-differential-actuator-motor"},
  {category: "driveline", translationKey: "components.covered.driveline.locking-differential-switch"},
  {category: "driveline", translationKey: "components.covered.driveline.locking-hubs"},
  {category: "driveline", translationKey: "components.covered.driveline.locking-rings"},
  {category: "driveline", translationKey: "components.covered.driveline.lubricated-differential-bearings"},
  {category: "driveline", translationKey: "components.covered.driveline.pinion-bearings"},
  {category: "driveline", translationKey: "components.covered.driveline.pinion-cones"},
  {category: "driveline", translationKey: "components.covered.driveline.pinion-cups"},
  {category: "driveline", translationKey: "components.covered.driveline.pinion-flanges"},
  {category: "driveline", translationKey: "components.covered.driveline.pinion-gears"},
  {category: "driveline", translationKey: "components.covered.driveline.pinion-nuts"},
  {category: "driveline", translationKey: "components.covered.driveline.pinion-races"},
  {category: "driveline", translationKey: "components.covered.driveline.power-transfer-unit"},
  {category: "driveline", translationKey: "components.covered.driveline.prndl-position-switch"},
  {category: "driveline", translationKey: "components.covered.driveline.rear-axle-housing"},
  {category: "driveline", translationKey: "components.covered.driveline.sealer-silicone"},
  {category: "driveline", translationKey: "components.covered.driveline.seals-gaskets"},
  {category: "driveline", translationKey: "components.covered.driveline.shaft-bearings"},
  {category: "driveline", translationKey: "components.covered.driveline.shafts"},
  {category: "driveline", translationKey: "components.covered.driveline.shifter-mechanism"},
  {category: "driveline", translationKey: "components.covered.driveline.shim-kit"},
  {category: "driveline", translationKey: "components.covered.driveline.shims"},
  {category: "driveline", translationKey: "components.covered.driveline.side-gears"},
  {category: "driveline", translationKey: "components.covered.driveline.spacers"},
  {category: "driveline", translationKey: "components.covered.driveline.transfer-case"},
  {category: "driveline", translationKey: "components.covered.driveline.transfer-case-shift-motor"},
  {category: "driveline", translationKey: "components.covered.driveline.universal-joints"},
  {category: "driveline", translationKey: "components.covered.driveline.viscous-coupler"},
  {category: "driveline", translationKey: "components.covered.driveline.wheel-bearings"},
  {category: "driveline", translationKey: "components.covered.driveline.yoke"},
  {category: "suspension", translationKey: "components.covered.suspension.active-swaybar-actuator"},
  {category: "suspension", translationKey: "components.covered.suspension.air-suspension-system"},
  {category: "suspension", translationKey: "components.covered.suspension.auxiliary-springs"},
  {category: "suspension", translationKey: "components.covered.suspension.axle-trac-bar"},
  {category: "suspension", translationKey: "components.covered.suspension.coil/leaf-springs"},
  {category: "suspension", translationKey: "components.covered.suspension.control-arm-shafts-and-bushings"},
  {category: "suspension", translationKey: "components.covered.suspension.electronic-swaybar-disconnect-motor"},
  {category: "suspension", translationKey: "components.covered.suspension.fasteners-nuts-and-bolts"},
  {category: "suspension", translationKey: "components.covered.suspension.front-stabilizer-sway-bar-link-and-bushing"},
  {category: "suspension", translationKey: "components.covered.suspension.height-sensor"},
  {category: "suspension", translationKey: "components.covered.suspension.hub-bearings"},
  {category: "suspension", translationKey: "components.covered.suspension.hydraulic-suspension-module"},
  {category: "suspension", translationKey: "components.covered.suspension.hydraulic-suspension-motor"},
  {category: "suspension", translationKey: "components.covered.suspension.king-pin"},
  {category: "suspension", translationKey: "components.covered.suspension.kingpins-and-bushings"},
  {category: "suspension", translationKey: "components.covered.suspension.lateral-link-arm"},
  {category: "suspension", translationKey: "components.covered.suspension.level-control-compressor"},
  {category: "suspension", translationKey: "components.covered.suspension.level-control-solenoid"},
  {category: "suspension", translationKey: "components.covered.suspension.lower-control-arm-left"},
  {category: "suspension", translationKey: "components.covered.suspension.lower-control-arm-right"},
  {category: "suspension", translationKey: "components.covered.suspension.lower-control-arm-bushings"},
  {category: "suspension", translationKey: "components.covered.suspension.lower-control-arm-shaft-left"},
  {category: "suspension", translationKey: "components.covered.suspension.lower-control-arm-shaft-right"},
  {category: "suspension", translationKey: "components.covered.suspension.mode-selector-switch"},
  {category: "suspension", translationKey: "components.covered.suspension.mounting-plates-and-bushings"},
  {category: "suspension", translationKey: "components.covered.suspension.panhard-rod"},
  {category: "suspension", translationKey: "components.covered.suspension.radius-arm-left"},
  {category: "suspension", translationKey: "components.covered.suspension.radius-arm-right"},
  {category: "suspension", translationKey: "components.covered.suspension.radius-arm-bushings"},
  {category: "suspension", translationKey: "components.covered.suspension.rear-torsion-arms-and-bars"},
  {category: "suspension", translationKey: "components.covered.suspension.rear-trailing-arm-assembly"},
  {category: "suspension", translationKey: "components.covered.suspension.ride-control-module"},
  {category: "suspension", translationKey: "components.covered.suspension.seals"},
  {category: "suspension", translationKey: "components.covered.suspension.spindles"},
  {category: "suspension", translationKey: "components.covered.suspension.spindle-knuckle"},
  {category: "suspension", translationKey: "components.covered.suspension.spindle-and-spindle-supports"},
  {category: "suspension", translationKey: "components.covered.suspension.spring-bushing,-shackle-and-hanger"},
  {category: "suspension", translationKey: "components.covered.suspension.stabilizer-bar"},
  {category: "suspension", translationKey: "components.covered.suspension.stabilizer-bar-front"},
  {category: "suspension", translationKey: "components.covered.suspension.stabilizer-bar-rear"},
  {category: "suspension", translationKey: "components.covered.suspension.stabilizer-bushings"},
  {category: "suspension", translationKey: "components.covered.suspension.stabilizer-links"},
  {category: "suspension", translationKey: "components.covered.suspension.stub-axle"},
  {category: "suspension", translationKey: "components.covered.suspension.support-bearings"},
  {category: "suspension", translationKey: "components.covered.suspension.suspension-diagnostic"},
  {category: "suspension", translationKey: "components.covered.suspension.thrust-arms"},
  {category: "suspension", translationKey: "components.covered.suspension.torsion-bars"},
  {category: "suspension", translationKey: "components.covered.suspension.torsion-bushings"},
  {category: "suspension", translationKey: "components.covered.suspension.torsion-mounts"},
  {category: "suspension", translationKey: "components.covered.suspension.trailing-arm"},
  {category: "suspension", translationKey: "components.covered.suspension.upper-and-lower-ball-joints"},
  {category: "suspension", translationKey: "components.covered.suspension.upper-control-arm-left"},
  {category: "suspension", translationKey: "components.covered.suspension.upper-control-arm-right"},
  {category: "suspension", translationKey: "components.covered.suspension.upper-control-arm-bushings"},
  {category: "suspension", translationKey: "components.covered.suspension.upper-control-arm-shaft-left"},
  {category: "suspension", translationKey: "components.covered.suspension.upper-control-arm-shaft-right"},
  {category: "steering", translationKey: "components.covered.steering.bellows-boots"},
  {category: "steering", translationKey: "components.covered.steering.center-link"},
  {category: "steering", translationKey: "components.covered.steering.control-valve"},
  {category: "steering", translationKey: "components.covered.steering.couplings"},
  {category: "steering", translationKey: "components.covered.steering.drag-link"},
  {category: "steering", translationKey: "components.covered.steering.electronic-steering-motor-control-module"},
  {category: "steering", translationKey: "components.covered.steering.fasteners-nuts-and-bolts"},
  {category: "steering", translationKey: "components.covered.steering.idler-arm"},
  {category: "steering", translationKey: "components.covered.steering.inner-outer-tie-rods"},
  {category: "steering", translationKey: "components.covered.steering.pitman-arm"},
  {category: "steering", translationKey: "components.covered.steering.power-steering-gear"},
  {category: "steering", translationKey: "components.covered.steering.power-steering-motors"},
  {category: "steering", translationKey: "components.covered.steering.power-steering-pump"},
  {category: "steering", translationKey: "components.covered.steering.power-steering-pump-cooler"},
  {category: "steering", translationKey: "components.covered.steering.power-steering-pump-reservoir"},
  {category: "steering", translationKey: "components.covered.steering.pulley-assembly-for-power-steering-pump"},
  {category: "steering", translationKey: "components.covered.steering.rack-and-pinion-assembly"},
  {category: "steering", translationKey: "components.covered.steering.rack-and-pinion-boots"},
  {category: "steering", translationKey: "components.covered.steering.seals-and-gaskets"},
  {category: "steering", translationKey: "components.covered.steering.steering-column"},
  {category: "steering", translationKey: "components.covered.steering.steering-gear-housing-and-all-internal-parts"},
  {category: "steering", translationKey: "components.covered.steering.steering-shafts"},
  {category: "steering", translationKey: "components.covered.steering.telescoping-steering-column-motors"},
  {category: "braking-system", translationKey: "components.covered.braking-system.abs-accumulator"},
  {category: "braking-system", translationKey: "components.covered.braking-system.abs-electronic-control-processor"},
  {category: "braking-system", translationKey: "components.covered.braking-system.abs-gaskets"},
  {category: "braking-system", translationKey: "components.covered.braking-system.abs-hydraulic-control-assembly"},
  {category: "braking-system", translationKey: "components.covered.braking-system.abs-hydraulic-pump-motor-assembly"},
  {category: "braking-system", translationKey: "components.covered.braking-system.abs-pressure-modulator-valve"},
  {category: "braking-system", translationKey: "components.covered.braking-system.abs-wheel-speed-sensor-left-front"},
  {category: "braking-system", translationKey: "components.covered.braking-system.abs-wheel-speed-sensor-left-rear"},
  {category: "braking-system", translationKey: "components.covered.braking-system.abs-wheel-speed-sensor-right-front"},
  {category: "braking-system", translationKey: "components.covered.braking-system.abs-wheel-speed-sensor-right-rear"},
  {category: "braking-system", translationKey: "components.covered.braking-system.acceleration-deceleration-sensor"},
  {category: "braking-system", translationKey: "components.covered.braking-system.anti-lock-braking-system"},
  {category: "braking-system", translationKey: "components.covered.braking-system.backing-plates"},
  {category: "braking-system", translationKey: "components.covered.braking-system.brake-adjuster"},
  {category: "braking-system", translationKey: "components.covered.braking-system.brake-caliper-left-front"},
  {category: "braking-system", translationKey: "components.covered.braking-system.brake-caliper-left-rear"},
  {category: "braking-system", translationKey: "components.covered.braking-system.brake-caliper-right-front"},
  {category: "braking-system", translationKey: "components.covered.braking-system.brake-caliper-right-rear"},
  {category: "braking-system", translationKey: "components.covered.braking-system.brake-caliper-kit"},
  {category: "braking-system", translationKey: "components.covered.braking-system.brake-diagnostic"},
  {category: "braking-system", translationKey: "components.covered.braking-system.brake-lines"},
  {category: "braking-system", translationKey: "components.covered.braking-system.brake-master-cylinder"},
  {category: "braking-system", translationKey: "components.covered.braking-system.brake-pedal-sensor"},
  {category: "braking-system", translationKey: "components.covered.braking-system.brake-pedal-shaft"},
  {category: "braking-system", translationKey: "components.covered.braking-system.brake-pedal-switch"},
  {category: "braking-system", translationKey: "components.covered.braking-system.combination-valve"},
  {category: "braking-system", translationKey: "components.covered.braking-system.electric-brake-boost-pump-motor"},
  {category: "braking-system", translationKey: "components.covered.braking-system.electronic-abs-control-module"},
  {category: "braking-system", translationKey: "components.covered.braking-system.emergency-braking-collision-sensor-radar-camera"},
  {category: "braking-system", translationKey: "components.covered.braking-system.fasteners-nuts-and-bolts"},
  {category: "braking-system", translationKey: "components.covered.braking-system.gaskets"},
  {category: "braking-system", translationKey: "components.covered.braking-system.hydraulic-fitting(s)"},
  {category: "braking-system", translationKey: "components.covered.braking-system.hydraulic-line(s)"},
  {category: "braking-system", translationKey: "components.covered.braking-system.lateral-accelerometer"},
  {category: "braking-system", translationKey: "components.covered.braking-system.master-cylinder"},
  {category: "braking-system", translationKey: "components.covered.braking-system.parking-brake"},
  {category: "braking-system", translationKey: "components.covered.braking-system.parking-brake-actuator"},
  {category: "braking-system", translationKey: "components.covered.braking-system.parking-brake-cables-linkage"},
  {category: "braking-system", translationKey: "components.covered.braking-system.parking-brake-module"},
  {category: "braking-system", translationKey: "components.covered.braking-system.parking-brake-switch"},
  {category: "braking-system", translationKey: "components.covered.braking-system.power-brake-booster"},
  {category: "braking-system", translationKey: "components.covered.braking-system.power-brake-cylinder"},
  {category: "braking-system", translationKey: "components.covered.braking-system.proportioning-valve"},
  {category: "braking-system", translationKey: "components.covered.braking-system.compensating-valve"},
  {category: "braking-system", translationKey: "components.covered.braking-system.seals-and-gaskets"},
  {category: "braking-system", translationKey: "components.covered.braking-system.self-adjusters"},
  {category: "braking-system", translationKey: "components.covered.braking-system.steering-angle-sensor"},
  {category: "braking-system", translationKey: "components.covered.braking-system.traction-control-switch"},
  {category: "braking-system", translationKey: "components.covered.braking-system.vacuum-boost-pump"},
  {category: "braking-system", translationKey: "components.covered.braking-system.wheel-cylinder-left-front"},
  {category: "braking-system", translationKey: "components.covered.braking-system.wheel-cylinder-left-rear"},
  {category: "braking-system", translationKey: "components.covered.braking-system.wheel-cylinder-right-front"},
  {category: "braking-system", translationKey: "components.covered.braking-system.wheel-cylinder-right-rear"},
  {category: "braking-system", translationKey: "components.covered.braking-system.yaw-rate-sensor"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.ac-heating-outlet-and-duct"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.ac-clutch-and-clutch-bearings"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.ac-compressor"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.ac-compressor-clutch-switch"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.ac-control-power-module"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.ac-lines"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.ac-compressor-control-module"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.accumulator-receiver-dryer"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.air-conditioning-diagnostic"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.auto-heater-ac-control-head-assembly"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.auto-climate-control-programmer"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.blend-door-actuators-and-motors"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.blend-door-cable"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.blend-door-case"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.blower-motor-resistor"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.climate-control-module"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.clutch-cycling-switch"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.compressor-seals"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.condenser"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.driver-temperature-sensor"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.evacuate-and-recharge"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.evaporator-core-case"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.evaporator-fan"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.evaporator-refrigerant-temperature-sensor"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.expansion-valve"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.fasteners-nuts-and-bolts"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.front-and-rear-blower-motor"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.front-and-rear-evaporator"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.gaskets-and-seals"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.heater-core"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.heater-ac-blower-speed-switch"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.high-low-cut-off-switch-and-or-pressure-switch"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.idler-pulley"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.interior-temperature-sensor"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.manual-heater-ac-control-head-assembly"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.orifice-tube"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.passenger-temperature-sensor"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.pressure-cycling-switch"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.rear-instrument-panel-control-assembly"},
  {category: "ac-heating", translationKey: "components.covered.ac-heating.refrigerant"},
  {category: "electrical", translationKey: "components.covered.electrical.adaptive-cruise-control-systems"},
  {category: "electrical", translationKey: "components.covered.electrical.adaptive-headlamp-system"},
  {category: "electrical", translationKey: "components.covered.electrical.all-control-modules"},
  {category: "electrical", translationKey: "components.covered.electrical.alternator"},
  {category: "electrical", translationKey: "components.covered.electrical.antenna-assembly"},
  {category: "electrical", translationKey: "components.covered.electrical.audio-amplifier"},
  {category: "electrical", translationKey: "components.covered.electrical.auto-dimming-side-rearview-mirrors"},
  {category: "electrical", translationKey: "components.covered.electrical.back-up-assist-camera"},
  {category: "electrical", translationKey: "components.covered.electrical.blind-spot-sensors-radars"},
  {category: "electrical", translationKey: "components.covered.electrical.clocks-compass"},
  {category: "electrical", translationKey: "components.covered.electrical.coil-pack-assembly"},
  {category: "electrical", translationKey: "components.covered.electrical.convertible-top-hydraulic-cylinders"},
  {category: "electrical", translationKey: "components.covered.electrical.convertible-top-motor"},
  {category: "electrical", translationKey: "components.covered.electrical.coolant-temp-sensor"},
  {category: "electrical", translationKey: "components.covered.electrical.cooled-seat-fan-cooling-module"},
  {category: "electrical", translationKey: "components.covered.electrical.cooling-fan-motor"},
  {category: "electrical", translationKey: "components.covered.electrical.cooling-fan-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.cruise-control-amplifier"},
  {category: "electrical", translationKey: "components.covered.electrical.cruise-control-module"},
  {category: "electrical", translationKey: "components.covered.electrical.cruise-control-servo"},
  {category: "electrical", translationKey: "components.covered.electrical.cruise-control-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.distributor-assembly"},
  {category: "electrical", translationKey: "components.covered.electrical.door-closing-motor"},
  {category: "electrical", translationKey: "components.covered.electrical.door-latches"},
  {category: "electrical", translationKey: "components.covered.electrical.door-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.dvd-players"},
  {category: "electrical", translationKey: "components.covered.electrical.electric-mirror-motor-and-controls"},
  {category: "electrical", translationKey: "components.covered.electrical.electrical-diagnostic"},
  {category: "electrical", translationKey: "components.covered.electrical.electronic-driver-information-display"},
  {category: "electrical", translationKey: "components.covered.electrical.electronic-driver-information-module"},
  {category: "electrical", translationKey: "components.covered.electrical.electronic-fuel-injection-system"},
  {category: "electrical", translationKey: "components.covered.electrical.electronic-instrument-cluster"},
  {category: "electrical", translationKey: "components.covered.electrical.engine-camshaft-position-sensor"},
  {category: "electrical", translationKey: "components.covered.electrical.engine-control-module"},
  {category: "electrical", translationKey: "components.covered.electrical.engine-crankshaft-position-sensor"},
  {category: "electrical", translationKey: "components.covered.electrical.factory-alarm-control-module"},
  {category: "electrical", translationKey: "components.covered.electrical.fasteners-nuts-and-bolts"},
  {category: "electrical", translationKey: "components.covered.electrical.flasher-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.front-window-wiper-motor"},
  {category: "electrical", translationKey: "components.covered.electrical.fuel-door-actuator-electric-only"},
  {category: "electrical", translationKey: "components.covered.electrical.fuel-gauge"},
  {category: "electrical", translationKey: "components.covered.electrical.fuel-pump"},
  {category: "electrical", translationKey: "components.covered.electrical.global-positioning-system-gps"},
  {category: "electrical", translationKey: "components.covered.electrical.glow-plug-controller"},
  {category: "electrical", translationKey: "components.covered.electrical.hazard-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.headlamp-door-motor"},
  {category: "electrical", translationKey: "components.covered.electrical.headlamp-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.headlight-door-motor"},
  {category: "electrical", translationKey: "components.covered.electrical.heads-up-display"},
  {category: "electrical", translationKey: "components.covered.electrical.heated-armrest"},
  {category: "electrical", translationKey: "components.covered.electrical.heated-seat-systems"},
  {category: "electrical", translationKey: "components.covered.electrical.heated-steering-wheel"},
  {category: "electrical", translationKey: "components.covered.electrical.height-adjustment-compressor"},
  {category: "electrical", translationKey: "components.covered.electrical.horn-assemblies"},
  {category: "electrical", translationKey: "components.covered.electrical.ignition-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.ignition-switch-lock-cylinder"},
  {category: "electrical", translationKey: "components.covered.electrical.ignition-switch-spring"},
  {category: "electrical", translationKey: "components.covered.electrical.inductive-cell-phone-charger"},
  {category: "electrical", translationKey: "components.covered.electrical.infrared-systems"},
  {category: "electrical", translationKey: "components.covered.electrical.integrated-radio-gps"},
  {category: "electrical", translationKey: "components.covered.electrical.in-vehicle-wireless-charging-station"},
  {category: "electrical", translationKey: "components.covered.electrical.keyless-entry-sensors-and-receiver-module"},
  {category: "electrical", translationKey: "components.covered.electrical.knock-sensor"},
  {category: "electrical", translationKey: "components.covered.electrical.lane-departure-sensor-camera"},
  {category: "electrical", translationKey: "components.covered.electrical.liquid-crystal-displays-lcd"},
  {category: "electrical", translationKey: "components.covered.electrical.manually-operated-electrical-switches"},
  {category: "electrical", translationKey: "components.covered.electrical.message-center"},
  {category: "electrical", translationKey: "components.covered.electrical.navigation-systems"},
  {category: "electrical", translationKey: "components.covered.electrical.neutral-safety-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.o2-sensor"},
  {category: "electrical", translationKey: "components.covered.electrical.odometer-and-speedometer"},
  {category: "electrical", translationKey: "components.covered.electrical.oil-gauge"},
  {category: "electrical", translationKey: "components.covered.electrical.oil-level-and-oil-pressure-sending-unit-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.oil-pressure-gauge"},
  {category: "electrical", translationKey: "components.covered.electrical.on-board-communications-system"},
  {category: "electrical", translationKey: "components.covered.electrical.overhead-electronic-compass-temperature"},
  {category: "electrical", translationKey: "components.covered.electrical.overhead-electronic-vehicle-information-center"},
  {category: "electrical", translationKey: "components.covered.electrical.overhead-view-cameras"},
  {category: "electrical", translationKey: "components.covered.electrical.park-assist-module"},
  {category: "electrical", translationKey: "components.covered.electrical.park-assist-sensors"},
  {category: "electrical", translationKey: "components.covered.electrical.parking-sensors"},
  {category: "electrical", translationKey: "components.covered.electrical.pass-lock-sensor"},
  {category: "electrical", translationKey: "components.covered.electrical.perimeter-warning-system"},
  {category: "electrical", translationKey: "components.covered.electrical.power-adjustable-pedal-motor-assembly"},
  {category: "electrical", translationKey: "components.covered.electrical.power-adjustable-pedal-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.power-antenna-assembly"},
  {category: "electrical", translationKey: "components.covered.electrical.power-antenna-motor"},
  {category: "electrical", translationKey: "components.covered.electrical.power-door-locks-and-linkage"},
  {category: "electrical", translationKey: "components.covered.electrical.power-door-locks-latch-assembly"},
  {category: "electrical", translationKey: "components.covered.electrical.power-door-lock-actuators"},
  {category: "electrical", translationKey: "components.covered.electrical.power-door-lock-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.power-folding-seat-motor"},
  {category: "electrical", translationKey: "components.covered.electrical.power-hatch-liftgate-motion-sensor"},
  {category: "electrical", translationKey: "components.covered.electrical.power-hatch-liftgate-motor-assembly"},
  {category: "electrical", translationKey: "components.covered.electrical.power-headrest-motor-module"},
  {category: "electrical", translationKey: "components.covered.electrical.power-headrest-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.power-liftgate"},
  {category: "electrical", translationKey: "components.covered.electrical.power-lumbar-pump-motor"},
  {category: "electrical", translationKey: "components.covered.electrical.power-mirror-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.power-outlets"},
  {category: "electrical", translationKey: "components.covered.electrical.power-seat-motors"},
  {category: "electrical", translationKey: "components.covered.electrical.power-seat-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.power-sliding-rear-window-motor-and-regulator"},
  {category: "electrical", translationKey: "components.covered.electrical.power-sunroof-motor"},
  {category: "electrical", translationKey: "components.covered.electrical.power-tailgate-motor"},
  {category: "electrical", translationKey: "components.covered.electrical.powertrain-control-module"},
  {category: "electrical", translationKey: "components.covered.electrical.power-window-motors-regulators"},
  {category: "electrical", translationKey: "components.covered.electrical.power-window-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.radio-gps-navigation-components"},
  {category: "electrical", translationKey: "components.covered.electrical.radio-tape-player-cd-player"},
  {category: "electrical", translationKey: "components.covered.electrical.rear-window-defogger-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.rear-window-wiper-motor"},
  {category: "electrical", translationKey: "components.covered.electrical.rearview-back-up-camera-and-sensors"},
  {category: "electrical", translationKey: "components.covered.electrical.rearview-mirror-assembly"},
  {category: "electrical", translationKey: "components.covered.electrical.receiver"},
  {category: "electrical", translationKey: "components.covered.electrical.refrigerator"},
  {category: "electrical", translationKey: "components.covered.electrical.remote-start-system"},
  {category: "electrical", translationKey: "components.covered.electrical.resistors"},
  {category: "electrical", translationKey: "components.covered.electrical.sd-card-usb-port-reader-for-audio-system"},
  {category: "electrical", translationKey: "components.covered.electrical.seat-heater-element"},
  {category: "electrical", translationKey: "components.covered.electrical.seat-heater-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.seat-heaters"},
  {category: "electrical", translationKey: "components.covered.electrical.seat-massager-motor-and-or-assembly"},
  {category: "electrical", translationKey: "components.covered.electrical.seat-recliner-assembly"},
  {category: "electrical", translationKey: "components.covered.electrical.slide-door-motor-assembly"},
  {category: "electrical", translationKey: "components.covered.electrical.sliding-door-module"},
  {category: "electrical", translationKey: "components.covered.electrical.speakers"},
  {category: "electrical", translationKey: "components.covered.electrical.starter-drive"},
  {category: "electrical", translationKey: "components.covered.electrical.starter-motor"},
  {category: "electrical", translationKey: "components.covered.electrical.starter-solenoid"},
  {category: "electrical", translationKey: "components.covered.electrical.stop-lamp-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.sunroof-panoramic-roof-and-covertible-top-motors"},
  {category: "electrical", translationKey: "components.covered.electrical.tachometer"},
  {category: "electrical", translationKey: "components.covered.electrical.tailgate-lock-actuator"},
  {category: "electrical", translationKey: "components.covered.electrical.television"},
  {category: "electrical", translationKey: "components.covered.electrical.temperature-gauge"},
  {category: "electrical", translationKey: "components.covered.electrical.temperature-sensor"},
  {category: "electrical", translationKey: "components.covered.electrical.thermal-imaging-camera"},
  {category: "electrical", translationKey: "components.covered.electrical.tire-pressure-monitor-sensor"},
  {category: "electrical", translationKey: "components.covered.electrical.trip-computer"},
  {category: "electrical", translationKey: "components.covered.electrical.trunk-release-actuator"},
  {category: "electrical", translationKey: "components.covered.electrical.turbo-gauge"},
  {category: "electrical", translationKey: "components.covered.electrical.turn-signal-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.usb-outlets"},
  {category: "electrical", translationKey: "components.covered.electrical.vacuum-pump"},
  {category: "electrical", translationKey: "components.covered.electrical.vented-seat-fan"},
  {category: "electrical", translationKey: "components.covered.electrical.voice-activation-sensors"},
  {category: "electrical", translationKey: "components.covered.electrical.voltage-regulator"},
  {category: "electrical", translationKey: "components.covered.electrical.window-regulators"},
  {category: "electrical", translationKey: "components.covered.electrical.wiper-module"},
  {category: "electrical", translationKey: "components.covered.electrical.wiper-switch"},
  {category: "electrical", translationKey: "components.covered.electrical.wiring-harness"},
  {category: "emissions", translationKey: "components.covered.emissions.air-fuel-ratio-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.air-injection-check-valve"},
  {category: "emissions", translationKey: "components.covered.emissions.air-injection-control-valve"},
  {category: "emissions", translationKey: "components.covered.emissions.air-injection-valve"},
  {category: "emissions", translationKey: "components.covered.emissions.air-pump"},
  {category: "emissions", translationKey: "components.covered.emissions.aspirator-tube"},
  {category: "emissions", translationKey: "components.covered.emissions.barometric-pressure-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.canister-purge-solenoid"},
  {category: "emissions", translationKey: "components.covered.emissions.deceleration-valve"},
  {category: "emissions", translationKey: "components.covered.emissions.diesel-emission-fluid-heater"},
  {category: "emissions", translationKey: "components.covered.emissions.diesel-emissions-fluid-injector"},
  {category: "emissions", translationKey: "components.covered.emissions.diesel-emissions-fluid-level-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.diesel-emissions-fluid-pump"},
  {category: "emissions", translationKey: "components.covered.emissions.diesel-emissions-fluid-tank"},
  {category: "emissions", translationKey: "components.covered.emissions.diesel-exhaust-fluid-def-system"},
  {category: "emissions", translationKey: "components.covered.emissions.diesel-particulate-filter-fuel-injector"},
  {category: "emissions", translationKey: "components.covered.emissions.diesel-particulate-filter-pressure-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.diesel-particulate-filter-temperature-module"},
  {category: "emissions", translationKey: "components.covered.emissions.diesel-particulate-filter-temperature-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.dpfe-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.egr-check-valve"},
  {category: "emissions", translationKey: "components.covered.emissions.egr-controller"},
  {category: "emissions", translationKey: "components.covered.emissions.egr-cooler"},
  {category: "emissions", translationKey: "components.covered.emissions.egr-diverter-valve"},
  {category: "emissions", translationKey: "components.covered.emissions.egr-position-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.egr-solenoid"},
  {category: "emissions", translationKey: "components.covered.emissions.egr-tube"},
  {category: "emissions", translationKey: "components.covered.emissions.egr-valve"},
  {category: "emissions", translationKey: "components.covered.emissions.egr-efe-thermal-vacuum-switch"},
  {category: "emissions", translationKey: "components.covered.emissions.evap-leak-detection-pump-and-valve"},
  {category: "emissions", translationKey: "components.covered.emissions.evap-purge-canister"},
  {category: "emissions", translationKey: "components.covered.emissions.evap-tank-pressure-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.evap-vent-valve"},
  {category: "emissions", translationKey: "components.covered.emissions.evaporative-system-detector-monitor"},
  {category: "emissions", translationKey: "components.covered.emissions.exhaust-gas-temperature-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.fasteners-nuts-and-bolts"},
  {category: "emissions", translationKey: "components.covered.emissions.fuel-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.fuel-tank-pressure-control-valve"},
  {category: "emissions", translationKey: "components.covered.emissions.fuel-tank-pressure-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.fuel-temperture-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.gas-engine-egr-recirculation-system"},
  {category: "emissions", translationKey: "components.covered.emissions.idle-speed-control-motor"},
  {category: "emissions", translationKey: "components.covered.emissions.intake-air-resonator"},
  {category: "emissions", translationKey: "components.covered.emissions.intake-air-temperture-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.knock-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.leak-detection-pump"},
  {category: "emissions", translationKey: "components.covered.emissions.map-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.mass-airflow-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.nox-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.oxygen-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.pcm-coolant-temp-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.pcv-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.purge-solenoid"},
  {category: "emissions", translationKey: "components.covered.emissions.throttle-position-sensor"},
  {category: "emissions", translationKey: "components.covered.emissions.vapor-canister"},
  {category: "safety-systems", translationKey: "components.covered.safety-systems.adaptive-cruise-control-system"},
  {category: "safety-systems", translationKey: "components.covered.safety-systems.automated-parking-system"},
  {category: "safety-systems", translationKey: "components.covered.safety-systems.automatic-emergency-braking-aeb-system"},
  {category: "safety-systems", translationKey: "components.covered.safety-systems.cornering-brake-control-systems"},
  {category: "safety-systems", translationKey: "components.covered.safety-systems.fasteners-nuts-and-bolts"},
  {category: "safety-systems", translationKey: "components.covered.safety-systems.impact-sensors"},
  {category: "safety-systems", translationKey: "components.covered.safety-systems.infrared-night-vision-system"},
  {category: "safety-systems", translationKey: "components.covered.safety-systems.lane-departure-warning-systems"},
  {category: "safety-systems", translationKey: "components.covered.safety-systems.obstacle-detection-sensor-systems"},
  {category: "safety-systems", translationKey: "components.covered.safety-systems.occupancy-sensors"},
  {category: "safety-systems", translationKey: "components.covered.safety-systems.rear-blind-zone-monitoring-system"},
  {category: "miscellaneous", translationKey: "components.covered.miscellaneous.4x4-awd"},
  {category: "miscellaneous", translationKey: "components.covered.miscellaneous.commercial-use"},
  {category: "miscellaneous", translationKey: "components.covered.miscellaneous.diesel"},
  {category: "miscellaneous", translationKey: "components.covered.miscellaneous.fleet-vehicles-coverage"},
  {category: "miscellaneous", translationKey: "components.covered.miscellaneous.ride-share-substitute-transportation"},
  {category: "miscellaneous", translationKey: "components.covered.miscellaneous.tire-suspension-lift-kit"},
  {category: "miscellaneous", translationKey: "components.covered.miscellaneous.turbo-supercharger"},
]

export const nonCoveredComponents = [
  {category: "engine", translationKey: "components.non-covered.engine.drive-belts"},
  {category: "engine", translationKey: "components.non-covered.engine.filters"},
  {category: "engine", translationKey: "components.non-covered.engine.freeze-plugs"},
  {category: "engine", translationKey: "components.non-covered.engine.glow-plugs"},
  {category: "engine", translationKey: "components.non-covered.engine.spark-plugs"},
  {category: "transmission", translationKey: "components.non-covered.transmission.clutch-assembly"},
  {category: "driveline", translationKey: "components.non-covered.driveline.wheels-tires"},
  {category: "suspension", translationKey: "components.non-covered.suspension.shocks"},
  {category: "suspension", translationKey: "components.non-covered.suspension.struts"},
  {category: "braking-system", translationKey: "components.non-covered.braking-system.abs-relay"},
  {category: "braking-system", translationKey: "components.non-covered.braking-system.brakes"},
  {category: "electrical", translationKey: "components.non-covered.electrical.batteries"},
  {category: "electrical", translationKey: "components.non-covered.electrical.battery-cables"},
  {category: "electrical", translationKey: "components.non-covered.electrical.cup-holders"},
  {category: "electrical", translationKey: "components.non-covered.electrical.defroster-grid"},
  {category: "electrical", translationKey: "components.non-covered.electrical.fuses"},
  {category: "emissions", translationKey: "components.non-covered.emissions.particulate-filter"},
  {category: "emissions", translationKey: "components.non-covered.emissions.exhaust-system"},
  {category: "safety-systems", translationKey: "components.non-covered.safety-systems.safety-restraint-systems"},
  {category: "interior", translationKey: "components.non-covered.interior.entertainment-system-components"},
  {category: "interior", translationKey: "components.non-covered.interior.cup-holders-ash-trays"},
  {category: "interior", translationKey: "components.non-covered.interior.dash-pad"},
  {category: "interior", translationKey: "components.non-covered.interior.trim-moldings"},
  {category: "interior", translationKey: "components.non-covered.interior.upholstery-carpet"},
  {category: "exterior", translationKey: "components.non-covered.exterior.bumpers"},
  {category: "exterior", translationKey: "components.non-covered.exterior.exterior-ornamentation"},
  {category: "exterior", translationKey: "components.non-covered.exterior.glass"},
  {category: "exterior", translationKey: "components.non-covered.exterior.headlamps"},
  {category: "exterior", translationKey: "components.non-covered.exterior.windshield-wiper-components"},
  {category: "miscellaneous", translationKey: "components.non-covered.miscellaneous.environmental-damage"},
  {category: "miscellaneous", translationKey: "components.non-covered.miscellaneous.neglect"},
  {category: "miscellaneous", translationKey: "components.non-covered.miscellaneous.frame-structural-parts"},
  {category: "miscellaneous", translationKey: "components.non-covered.miscellaneous.impact-collision-damage"},
  {category: "miscellaneous", translationKey: "components.non-covered.miscellaneous.non-oem-components"},
  {category: "miscellaneous", translationKey: "components.non-covered.miscellaneous.squeaks-rattles"},
];

export const notCoveredCardsData = [
  {
    title: "unaddressed-pre-existing-conditions",
    text: "unaddressed-pre-existing-conditions-description",
    icon: IconUnaddressedConditions,
  },
  {
    title: "collision",
    text: "collision-description",
    icon: IconCollison,
  },
  {
    title: "aftermarket-alterations",
    text: "aftermarket-alterations-description",
    icon: IconAftermarketAlterations,
  },
  {
    title: "damaged-titles",
    text: "damaged-titles-description",
    icon: IconDamagedTitles,
  },
];

export const desktopStyle = {
  root: {
    width: "400px",
    textAlign: "left",
    overflow: "visible",
  },
};

export const mobileStyle = {
  root: {
    width: "375px",
    textAlign: "left",
    overflow: "visible"
  },
};