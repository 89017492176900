import { useTranslation } from "react-i18next";
import { StepperComponent } from "../components/Stepper/Stepper";
import { PreviousButton } from "../components/PreviousButton/PreviousButton";
import { VehicleDetails } from "../components/VehicleDetails/VehicleDetails";
import { QuoteSummary } from "../components/QuoteSummary/QuoteSummary";
import { Benefits } from "../components/Benefits/Benefits";
import { ContinueButton } from "../components/ContinueButton/ContinueButton";
import { Paths } from "../models/constants/paths";
import { GlobalContext } from "../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Center, Loader } from "@mantine/core";
import { CarePlusEndpoints } from "../models/constants/carePlusEndpoints";
import { React, useContext, useEffect, useState } from "react";
import { IneligibilityReasons } from "../models/constants/ineligibilityReasons";
import { defaultContext } from "../models/constants/defaultContext";
import { appInsights } from "../components/ApplicationInsights/ApplicationInsights";
import { PageTitleMetaTags } from "../components/PageTitleMetaTags/PageTitleMetaTags";
import { AzureBlobFiles } from "../models/constants/azureBlobFiles";

export const FinalQuote = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { context, setContext } = useContext(GlobalContext);
  const [isLoading, setLoading] = useState(true);
  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(
    false
  );
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const pageTitle = t("browser-tab-titles.final-quote");
  const pageDescription = t("browser-tab-descriptions.final-quote");
  const metaTitle = t("browser-meta-titles.final-quote");
  const metaDescription = t("browser-meta-descriptions.final-quote");

  const GetFinalQuote = async () => {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_API_URL + CarePlusEndpoints.FinalQuote,
      {
        params: {
          dgb_id: context.user.dgb_id,
          vin: context.selected_vin,
        },
        timeout: process.env.REACT_APP_API_TIMEOUT
      }
    );
    return response;
  };

  const ValidateAddress = async () => {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_API_URL + CarePlusEndpoints.ValidateAddress,
      {
        params: {
          state: context.user.mailing_address.state,
        },
        timeout: process.env.REACT_APP_API_TIMEOUT
      }
    );
    return response;
  };

  const CreateStripeCustomer = async () => {
    axios.post(
      process.env.REACT_APP_BACKEND_API_URL +
        CarePlusEndpoints.CreateorUpdateStripeCustomer,
      null,
      {
        params: {
          dgb_id: context.user.dgb_id,
          email: context.user.email,
          name: context.user.first_name + " " + context.user.last_name,
          line1: context.user.mailing_address.line1,
          line2: context.user.mailing_address.line2,
          city: context.user.mailing_address.city,
          state: context.user.mailing_address.state,
          country: context.user.mailing_address.country,
          postal_code: context.user.mailing_address.postal_code,
        },
        timeout: process.env.REACT_APP_API_TIMEOUT
      },
    )
    .catch(error => {
      appInsights.trackException({ error: new Error(error), severityLevel: 1 });
      navigate(Paths.Error)
    });
  }

  const HandleFinalQuoteResponse = (res) => {
    if (res?.data?.ineligibility_reasons?.length) {
      navigate(Paths.IneligibleVehicle, { replace: true, state: {reasons: res.data.ineligibility_reasons}});
    }
    setContext({
      ...context,
      initial_quote: {
        ...context.initial_quote,
        value: parseFloat(res?.data?.base_rate),
      },
      surcharges: {
        diesel_surcharge_value: parseFloat(
          res?.data?.diesel_surcharge_value
        ),
        four_by_four_surcharge_value: parseFloat(
          res?.data?.four_by_four_surcharge_value
        ),
        turbo_surcharge_value: parseFloat(
          res?.data?.turbo_surcharge_value
        ),
      },
      final_quote: {
        base_rate: parseFloat(res?.data?.base_rate),
        discounted_one_time_payment: parseFloat(res?.data?.discounted_one_time_payment),
        one_time_payment: parseFloat(res?.data?.one_time_payment),
        one_time_payment_discount_amount: parseFloat(res?.data?.one_time_payment_discount_amount),
        one_time_payment_tax: parseFloat(res?.data?.one_time_payment_tax),
        plan_code: res?.data?.plan_code,
      },
      vehicle_data: {
        ...context.vehicle_data,
        year: parseFloat(res?.data?.vin_decoded_year),
        make: res?.data?.vin_decoded_make,
        model: res?.data?.vin_decoded_model,
      },
    });
    setLoading(false);
  }

  useEffect(() => {
    if (!context.completed_pages.confirm_address) {
      setContext(defaultContext);
      navigate(Paths.Home);
    } else {
      let vehicle = context.user.vehicles.find((vehicle) => {
        return vehicle.vin === context.selected_vin;
      });
      setSelectedVehicle(vehicle);
      CreateStripeCustomer()
        .then(() => {
          ValidateAddress()
            .then((res) => {
              if (res?.data?.ineligibility_reasons?.length) {
                navigate(Paths.IneligibleVehicle, {
                  replace: true,
                  state: {
                    reasons: [
                      IneligibilityReasons.INELIGIBLE_CUSTOMER_TAX_STATE_NOT_REGISTERED,
                    ],
                  },
                });
              } else {
                GetFinalQuote()
                  .then((res) => {
                    HandleFinalQuoteResponse(res);
                  })
                  .catch((error) => {
                    navigate(Paths.Error);
                  });
              }
            })
            .catch((error) => {
              appInsights.trackException({ error: new Error(error), severityLevel: 1 });
              navigate(Paths.Error);
            });
        })
        .catch((error) => {
          appInsights.trackException({ error: new Error(error), severityLevel: 1 });
          navigate(Paths.Error)
        })
    }
  }, []);

  const handleContinue = () => {
    setIsContinueButtonDisabled(true);
    axios
      .post(
        process.env.REACT_APP_BACKEND_API_URL +
          CarePlusEndpoints.CreateContractWithStripeSubscription,
        null,
        {
          params: {
            dgb_id: context.user.dgb_id,
            vin: context.selected_vin,
            first_name: context.user.first_name,
            last_name: context.user.last_name,
            email: context.user.email,
            line1: context.user.mailing_address.line1,
            line2: context.user.mailing_address.line2,
            city: context.user.mailing_address.city,
            state: context.user.mailing_address.state,
            postal_code: context.user.mailing_address.postal_code,
            notes: context.user_answers.problems_details,
          },
        }
      )
      .then((res) => {
        setContext({
          ...context,
          surcharges: {
            ...context.surcharges,
            diesel_surcharge_value: parseFloat(res.data.diesel_surcharge_value),
            four_by_four_surcharge_value: parseFloat(res.data.four_by_four_surcharge_value),
            turbo_surcharge_value: parseFloat(res.data.turbo_surcharge_value)
          },
          final_quote: {
            ...context.final_quote,
            discounted_one_time_payment: parseFloat(res?.data?.discounted_one_time_payment),
            monthly_rate: parseFloat(res?.data?.base_rate),
            one_time_payment: parseFloat(res?.data?.one_time_payment),
            one_time_payment_discount_amount: parseFloat(res?.data?.one_time_payment_discount_amount),
            one_time_payment_tax: parseFloat(res?.data?.one_time_payment_tax),
            subtotal_tax: parseFloat(res?.data?.subtotal_tax),
            plan_code: res?.data?.plan_code,
          },
          stripe_subscription_data: {
            contract_pdf: res.data.contract_pdf.pdf,
            signature_coordinates: res.data.contract_pdf.signature_coordinates,
            stripe_client_secret: res.data.stripe_client_secret,
            stripe_subscription_id: res.data.stripe_subscription_id,
          },
          pcmi_contract_data: {
            contract_number: res.data.contract_number,
          },
          completed_pages: {
            ...context.completed_pages,
            final_quote: true,
          },
        });
        navigate(Paths.Agreements);
      })
      .catch((error) => {
        appInsights.trackException({ error: new Error(error), severityLevel: 1 });
        navigate(Paths.Error);
      });
  };

  return isLoading ? (
    <div className="full-container">
      <div className="content-container">
        <Center>
          <Loader color="#006B5B" size="xl" />
        </Center>
      </div>
    </div>
  ) : (
    <>
      <PageTitleMetaTags
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        metaImage={AzureBlobFiles.MetaImage}
      />
      <div className="full-container">
        <div className="content-container">
          <PreviousButton path={Paths.ConfirmAddress} />
          <div className="header">
            <div className="header_1">{t("your-final-quote")}</div>
          </div>
          <div style={{ marginTop: "32px" }}>
            <VehicleDetails vehicle={selectedVehicle} />
          </div>
          <QuoteSummary final />
          <div style={{ marginTop: "32px" }}>
            <Benefits />
          </div>
          <ContinueButton
            label={t("continue-to-agreements")}
            clickAction={() => handleContinue()}
            disabled={isContinueButtonDisabled}
            clicked={isContinueButtonDisabled}
          />
        </div>
      </div>
    </>
  );
};
