import { React, useEffect, useContext, useState } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { Paths } from "../models/constants/paths";
import axios from "axios";
import { CarePlusEndpoints } from "../models/constants/carePlusEndpoints";
import { QuoteSummary } from "../components/QuoteSummary/QuoteSummary";
import { useNavigate } from "react-router-dom";
import { VehicleDetails } from "../components/VehicleDetails/VehicleDetails";
import { HomeShopDetails } from "../components/HomeShopDetails/HomeShopDetails";
import { defaultContext } from "../models/constants/defaultContext";
import { appInsights } from "../components/ApplicationInsights/ApplicationInsights";
import { useTranslation } from "react-i18next";
import { PageTitleMetaTags } from "../components/PageTitleMetaTags/PageTitleMetaTags";
import { AzureBlobFiles } from "../models/constants/azureBlobFiles";

export const Welcome = () => {
  const { context, setContext } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pageTitle = t("browser-tab-titles.welcome");
  const pageDescription = t("browser-tab-descriptions.welcome");
  const metaTitle = t("browser-meta-titles.welcome");
  const metaDescription = t("browser-meta-descriptions.welcome");
  
  useEffect(() => {
    if (localStorage.getItem("context")) {
      let contextJson = JSON.parse(localStorage.getItem("context"));
      if (!contextJson.completed_pages.payment) {
        setContext(defaultContext);
        navigate(Paths.Home);
      }
      setContext(contextJson);
    } else {
      setContext(defaultContext);
      navigate(Paths.Home);
    }
  }, []);

  useEffect(() => {
    if (context.user.dgb_id) {
      try {
        const response = axios.post(
          process.env.REACT_APP_BACKEND_API_URL +
            CarePlusEndpoints.FinalizeContractDetails +
            context.user.dgb_id,
          null,
          {
            params: {
              vin: context.selected_vin,
              year: context.vehicle_data.year,
              make: context.vehicle_data.make,
              model: context.vehicle_data.model,
              stripe_subscription_id: context.stripe_subscription_data.stripe_subscription_id,
              first_name: context.user.first_name,
              last_name: context.user.last_name,
              email: context.user.email,
              address_line_1: context.user.mailing_address.line1,
              address_line_2: context.user.mailing_address.line2,
              address_city: context.user.mailing_address.city,
              address_state: context.user.mailing_address.state,
              address_country: context.user.mailing_address.country,
              address_postal_code: context.user.mailing_address.postal_code,
              envelope_id: context.docusign.envelope_id,
              contract_number: context.pcmi_contract_data.contract_number,
              dvi_url: context.vehicle_data.dvi_url, 
              store_no: context.vehicle_data.shop_number,
            },
          }
        );
        window._paq = window._paq || [];
        window._paq.push(['trackGoal', 1, context.final_quote.monthly_rate]);
        window._paq.push(['trackGoal', 4, context.final_quote.monthly_rate]);
        window.dataLayer.push({event: "event_warranty_welcome", data: context.final_quote.monthly_rate});
        setIsLoading(false);
        localStorage.clear();
        return response;
      } catch (error) {
        appInsights.trackException({ error: new Error(error), severityLevel: 1 });
      }
    }
  }, [context.user.dgb_id]);

  return isLoading ? null : (
    <>
      <PageTitleMetaTags
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        metaImage={AzureBlobFiles.MetaImage}
      />
      <div className="full-container">
        <div className="content-container">
          <HomeShopDetails />
          <QuoteSummary final />
          <VehicleDetails vehicle={context.vehicle_data} />
        </div>
      </div>
    </>
  );
};
