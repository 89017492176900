import { useLocation } from "react-router-dom";
import i18next from "../../services/translation/i18next"; //Linter will warn that this isn't used - it definitely is
import { LogoHeader } from "../LogoHeader/LogoHeader";
import { BrochureFooter } from "../BrochureFooter/BrochureFooter";
import { Paths } from "../../models/constants/paths";

export const PageLayout = (props) => {
  const location = useLocation();

  function displayHeader() {
    return location.pathname === Paths.Home ? null : <LogoHeader />;
  }

  return (
    <>
      {displayHeader()}
      {props.children}
      <BrochureFooter />
    </>
  );
};
