import { React, useEffect, useContext, useState } from "react";
import { PreviousButton } from "../components/PreviousButton/PreviousButton";
import { Paths } from "../models/constants/paths";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../context/GlobalContext";
import { StepperComponent } from "../components/Stepper/Stepper";
import axios from "axios";
import { CarePlusEndpoints } from "../models/constants/carePlusEndpoints";
import { VehicleDetails } from "../components/VehicleDetails/VehicleDetails";
import { IneligibilityReasons } from "../models/constants/ineligibilityReasons";
import { Loader, Center } from "@mantine/core";
import { defaultContext } from "../models/constants/defaultContext";
import { appInsights } from "../components/ApplicationInsights/ApplicationInsights";
import { PageTitleMetaTags } from "../components/PageTitleMetaTags/PageTitleMetaTags";
import { AzureBlobFiles } from "../models/constants/azureBlobFiles";
import { useMsal } from "@azure/msal-react";

export const ConfirmVehicle = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { context, setContext } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);
  const pageTitle = t("browser-tab-titles.confirm-vehicle");
  const pageDescription = t("browser-tab-descriptions.confirm-vehicle");
  const metaTitle = t("browser-meta-titles.confirm-vehicle");
  const metaDescription = t("browser-meta-descriptions.confirm-vehicle");
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    if (
      (!localStorage.getItem("context") ||
        !JSON.parse(localStorage.getItem("context")).completed_pages
          .final_estimated_quote) &&
      !context.completed_pages.final_estimated_quote
    ) {
      setContext(defaultContext);
      navigate(Paths.Home);
    }
    
    if (sessionStorage.getItem("sign-in") && activeAccount) {
      sessionStorage.removeItem("sign-in");
      setContext(defaultContext);
      navigate(Paths.Home);
    }
    if (context.user.dgb_id) {
      // Reroute to home page if
      // 1. No local storage context AND Final Estimated Quote
      //    page is not completed in context
      // 2. Final Estimated Quote page is not completed in local storage AND
      //    Final Estimated Quote page is not completed in context
      getDGBCustomerData()
        .then((res) => {
          if (!res.data.vehicles.length) {
            navigate(Paths.IneligibleVehicle, {
              replace: true,
              state: { reasons: [IneligibilityReasons.NO_ELIGIBLE_VEHICLE] },
            });
          } else if (localStorage.getItem("context")) {
            let contextJson = JSON.parse(localStorage.getItem("context"));

            // This sets all user data in context including mailing address, email, and vehicles
            contextJson.user = {
              dgb_id: context.user.dgb_id,
              email: res.data.email,
              first_name: res.data.first_name,
              last_name: res.data.last_name,
              mailing_address: res.data.mailing_address,
              vehicles: res.data.vehicles,
              stripe_account: res.data.stripe_account,
            };
            setContext(contextJson);
          }
          localStorage.removeItem("context");
          setIsLoading(false);
        })
        .catch((error) => {
          appInsights.trackException({ error: new Error(error), severityLevel: 1 });
          navigate(Paths.Error);
        });
    }
  }, [context.user.dgb_id]);

  const getDGBCustomerData = async () => {
    let response;
    try {
      if (localStorage.getItem("dgb_id")) {
        response = await axios.get(
          process.env.REACT_APP_BACKEND_API_URL +
            CarePlusEndpoints.GetDGBCustomerData +
            localStorage.getItem("dgb_id"),
            {timeout: process.env.REACT_APP_API_TIMEOUT}
        );
      } else {
        response = await axios.get(
          process.env.REACT_APP_BACKEND_API_URL +
            CarePlusEndpoints.GetDGBCustomerData +
            context.user.dgb_id,
            {timeout: process.env.REACT_APP_API_TIMEOUT}
        );
      }
      return response;
    } catch (error) {
      appInsights.trackException({ error: new Error(error), severityLevel: 1 });
      navigate(Paths.Error);
    }
  };

  const renderVehicle = (vehicle, index) => {
    return <VehicleDetails key={index} vehicle={vehicle} />;
  };

  return isLoading ? (
    <div className="full-container">
      <div className="content-container">
        <Center>
          <Loader color="#006B5B" size="xl" />
        </Center>
      </div>
    </div>
  ) : (
    <>
      <PageTitleMetaTags
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        metaImage={AzureBlobFiles.MetaImage}
      />
      <div className="full-container">
        <div className="content-container">
          <PreviousButton path={Paths.FinalEstimatedQuote} />
          <div className="header">
            <div className="header_1">{t("confirm-vehicle")}</div>
          </div>
          <div className="subheader">{t("prioritize-peace-of-mind")}</div>
          {context.user.vehicles.map((vehicle, index) => {
            return renderVehicle(vehicle, index);
          })}
        </div>
      </div>
    </>
  );
};
