import React from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Flex,
  Stack,
  Text,
  Title,
  Group,
  Image,
} from "@mantine/core";
import { BrochureBenefitCard } from "./BrochureBenefitCard";
import { useMediaQuery } from "@mantine/hooks";
import IconLowMonthlySubscription from "../../assets/images/Low Monthly Subscription copy.png";
import IconOilChangeCredit from "../../assets/images/Routine Maintenance Costs copy.png";
import IconDecliningDeductible from "../../assets/images/Vanishing Deductible copy.png";
import IconKeepYourRate from "../../assets/images/Keep Your Rate copy.png";
import IconNoCostDeductible from "../../assets/images/No Cost Deductibles for Vets, First Responders, and active military copy.png"
import IconRoadsideAssistance from "../../assets/images/Breakdown Assistance copy.png"
import IconRentalReimbursement from "../../assets/images/Unadressed Pre-Existing Conditions copy.png"
import { Paths } from "../../models/constants/paths";
import { useLocation } from "react-router-dom";
import Plus from "../../assets/images/plus.svg";
import CarePlus from "../../assets/images/careplus.svg";

export const BrochureBenefits = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const isMobile = useMediaQuery(
    location.pathname === Paths.Home
      ? "(max-width: 1024px)"
      : "(max-width: 1000px)",
    true,
    {
      getInitialValueInEffect: false,
    }
  );

  const primaryBenefits = [
    {
      title: "low-monthly-subscription-cost",
      text: "low-monthly-subscription-cost-description",
      icon: IconLowMonthlySubscription,
      size: "standard",

    },
    {
      title: "declining-deductible",
      text: "declining-deductible-description",
      icon: IconDecliningDeductible,
      size: "standard",

    },
    {
      title: "no-cost-deductibles",
      text: "no-cost-deductibles-description",
      icon: IconNoCostDeductible,
      size: "standard",
    },
    {
      title: "oil-change-credit",
      text: "oil-change-credit-description",
      icon: IconOilChangeCredit,
      size: "wide",
    },
    {
      title: "lock-in-rates",
      text: "lock-in-rates-description",
      icon: IconKeepYourRate,
      size: "wide",
    },
  ];

  const secondaryBenefits = [
    {
      title: "24-hour-assistance",
      text: "24-hour-assistance-description",
      icon: IconRoadsideAssistance,
      size: "wide",

    },
    {
      title: "rental-vehicle-reimbursement",
      text: "rental-vehicle-reimbursement-description",
      icon: IconRentalReimbursement,
      size: "wide",

    },
  ];

  return (
    <Container size={"responsive"} px={0} bg={"#F8F9FA"} id="coverage_benefits">
      <Stack p={isMobile ? "48px 6%" : "6.8% 19% 5.3% 19%"} gap={24}>
        <Stack ta={"center"}>
          <Group justify="center">
            <Title ff="Crimson Text" fz="40px" lh={"120%"}>
              What's the
            </Title>
            <Image src={Plus} />
            <Title ff="Crimson Text" fz="40px" lh={"120%"}>
              in
            </Title>
            <Image src={CarePlus} />
            <Title ff="Crimson Text" fz="40px" lh={"120%"}>
              ?
            </Title>
          </Group>
          <Text ff="Montserrat" fz="20px" lh="165%" c="#495057">
            {t("benefits-description")}
          </Text>
          <Text ff="Montserrat" fz="20px" lh="165%" c="#495057">
            {t("benefits-description-2")}
          </Text>
        </Stack>
        <Flex gap={isMobile ? "16px" : "24px"} mt="24px" wrap={isMobile ? "wrap" : null} justify="center">
          {primaryBenefits.slice(0, 3).map((card, index) => (
            <React.Fragment key={index}>
              <BrochureBenefitCard {...card} />
            </React.Fragment>
          ))}
        </Flex>
        <Flex gap={isMobile ? "16px" : "24px"} wrap={isMobile ? "wrap" : null} justify="center">
          {primaryBenefits.slice(3, 5).map((card, index) => (
            <React.Fragment key={index}>
              <BrochureBenefitCard {...card} />
            </React.Fragment>
          ))}
        </Flex>
        <Text ff="Crimson Text" fz="24px" ta="center" mt="24px">{t("benefits-subheader")}</Text>
        <Flex gap={isMobile ? "16px" : "24px"} wrap={isMobile ? "wrap" : null} justify="center">
          {secondaryBenefits.map((card, index) => (
            <React.Fragment key={index}>
              <BrochureBenefitCard {...card} />
            </React.Fragment>
          ))}
        </Flex>
      </Stack>
    </Container>
  );
};
