import { Stepper, rem } from '@mantine/core';
import { IconCurrencyDollar, IconHeartHandshake, IconCar, IconCheck, IconUser, IconNumber1, IconNumber2 } from '@tabler/icons-react';
import { React } from 'react';

export const StepperComponent = (props) => {
  return props.isInitialQuote ? 
    <Stepper 
      active={props.activeState}
      allowNextStepsSelect={false}
      completedIcon={<IconCheck style={{ width: rem(18), height: rem(18) }} color={('#ffffff')} />}
      color={('#006b5b')}
    >
      <Stepper.Step icon={<IconNumber1 style={{ width: rem(18), height: rem(18) }} />} />
      <Stepper.Step icon={<IconNumber2 style={{ width: rem(18), height: rem(18), color:'#000000'}} />} />
    </Stepper> 
    : 
    <Stepper 
      active={props.activeState}
      allowNextStepsSelect={false}
      completedIcon={<IconCheck style={{ width: rem(18), height: rem(18) }} color={('#ffffff')} />}
      color={('#006b5b')}
    >
      <Stepper.Step icon={<IconCar style={{ width: rem(18), height: rem(18) }} />} />
      <Stepper.Step icon={<IconUser style={{ width: rem(18), height: rem(18), color:'#000000'}} />} />
      <Stepper.Step icon={<IconHeartHandshake style={{ width: rem(18), height: rem(18), color:'#000000' }} />} />
      <Stepper.Step icon={<IconCurrencyDollar style={{ width: rem(18), height: rem(18), color:'#000000' }} />} />
    </Stepper>
};