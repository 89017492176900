export const CarePlusEndpoints = {
  CreateContractWithStripeSubscription: "/v1/contract/create-with-stripe-subscription",
  CreateorUpdateStripeCustomer: "/v1/customer/stripe/create",
  DisqualifyingQuestions: "/v1/quote/disqualifying-questions",
  FinalizeContractDetails: "/v1/customer/finalize-contract-details/",
  FinalQuote: "/v1/quote/final",
  GetDGBCustomerData:"/v1/customer/dgb/",
  InitialQuote: "/v1/quote/initial",
  InitialQuoteByVin: "/v1/quote/initial/by-vin",
  InitialQuoteByLicensePlate: "/v1/quote/initial/license-plate",
  Stripe: "/v1/payment_intents/:id",
  Surcharge: "/v1/quote/surcharges",
  Signature: "/v1/contract/signature",
  ValidateAddress: "/v1/customer/validate-address"
};
