import React from "react";
import { Paper, Tabs, Center, Button, Divider } from "@mantine/core";
import { useTranslation, Trans } from "react-i18next";
import { Text, Title, Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import "./InitialQuoteForm.scss";
import { YMMMForm } from "../YMMMForm/YMMMForm";
import { VINForm } from "../VINForm/VINForm";
import { LicensePlateForm } from "../LicensePlateForm/LicensePlateForm";
import { useContext, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { defaultContext } from "../../models/constants/defaultContext";
import { loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { italicText } from "../../utils/textStyling";

export const InitialQuoteForm = (props) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const handleLoginRedirect = () => {
    let contextJSON = context;
    contextJSON.completed_pages.final_estimated_quote = true;
    localStorage.setItem("context", JSON.stringify(contextJSON));
    instance.loginRedirect(loginRequest).catch((error) => {
      console.log(error);
    });
  };

  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });
  const isNarrow = useMediaQuery("(max-width: 450px)", true, {
    getInitialValueInEffect: false,
  });
  const isVeryNarrow = useMediaQuery("(max-width: 375px)", true, {
    getInitialValueInEffect: false,
  })
  const { context, setContext } = useContext(GlobalContext);
  
  const getDefaultTab = () => {
    if (context.vehicle_data.vin || props.vin) {
      return "vin";
    } else if (
      (props.year && props.make && props.model) ||
      (!context.vehicle_data.license_plate &&
        context.vehicle_data.year &&
        context.vehicle_data.make &&
        context.vehicle_data.model)
    ) {
      return "make-model";
    } else {
      return "license-plate";
    }
  };
  const [selectedTab, setSelectedTab] = useState(getDefaultTab());

  const handleTabChange = (value) => {
    setSelectedTab(value);
    setContext({
      ...defaultContext,
      quote_form_data: context.quote_form_data,
    });
  };

  return (
    <Paper
      bg={"white"}
      p={isMobile ? "48px 6%" : "40px"}
      radius={8}
      id="start_quote"
      mih={isMobile ? null : "940px"}
      miw={isMobile ? null : "500px"}
    >
      <Stack gap={24}>
        <Title ff="Crimson Text" fz={40} fw={600} lh={"125%"}>
          {t("your-vehicle")}
        </Title>
        <Text ff={"Montserrat"} fz={18} fw={400} lh={"160%"} c={"#636363"}>
          <Trans
            i18nKey={"enter-vehicle-information"}
            t={t}
            values={{ italicText }}
            components={{
              italic: italicText("enter-vehicle-information"),
            }}
          />
        </Text>
        <Tabs
          color="#ff671d"
          defaultValue={getDefaultTab}
          onChange={handleTabChange}
          styles={{
            list: {
              display: "flex",
              flexWrap: "nowrap"
            }
          }}
        >
          <Tabs.List grow justify="center" mb="24px" ff="Montserrat" fw={600}>
            <Tabs.Tab value="license-plate">
              <Stack>
                <Text ff="Montserrat" fw="600" fz={isVeryNarrow ? 12 : isNarrow ? 15 : 18}>
                  {t("license-plate")}*
                </Text>
                {selectedTab === "license-plate" && (
                  <Text
                    fz={isNarrow ? "6pt" : "8pt"}
                    style={{
                      position: "absolute",
                      textAlign: "center",
                      left: 0,
                      right: 0,
                      marginTop: "40px",
                      color: "#006b5b",
                      fontWeight: 600,
                    }}
                  >
                    {t("most-accurate")}
                  </Text>
                )}
              </Stack>
            </Tabs.Tab>
            <Tabs.Tab value="make-model" fz={isVeryNarrow ? 12 : isNarrow ? 15 : 18}>
              {t("make-model")}
            </Tabs.Tab>
            <Tabs.Tab value="vin">
              <Stack>
                <Text ff="Montserrat" fw="600" fz={isVeryNarrow ? 12 : isNarrow ? 15 : 18}>
                  {t("vin")}*
                </Text>
                {selectedTab === "vin" && (
                  <Text
                    fz={isNarrow ? "6pt" : "8pt"}
                    style={{
                      position: "absolute",
                      textAlign: "center",
                      left: 0,
                      right: 0,
                      marginTop: "40px",
                      color: "#006b5b",
                      fontWeight: 600,
                    }}
                  >
                    {t("most-accurate")}
                  </Text>
                )}
              </Stack>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="license-plate">
            <LicensePlateForm />
          </Tabs.Panel>
          <Tabs.Panel value="make-model">
            <YMMMForm
              year={props.year}
              make={props.make}
              model={props.model}
              mileage={props.mileage}
            />
          </Tabs.Panel>
          <Tabs.Panel value="vin">
            <VINForm vin={props.vin} mileage={props.mileage} />
          </Tabs.Panel>
        </Tabs>
      </Stack>
      <Divider label={<Text fz={16} fw={400} c={"#636363"}>{t("or")}</Text>} labelPosition="center" styles={{ root: { marginTop: "24px" } }}/>
      <Text ff={"Montserrat"} fz={18} fw={400} lh={"160%"} c={"#636363"} mt={"24px"}>
        {t("already-a-guest")}
      </Text>
      <Center>
        <Button
          variant="outline"
          color="#FF671D"
          className="button"
          styles={{
            root: {
              marginTop: "12px",
            },
          }}
          onClick={() => handleLoginRedirect()}
        >
          {activeAccount ? t("select-vehicle") : t("sign-in")}
        </Button>
      </Center>
    </Paper>
  );
};
