import { useEffect, useContext, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";

import { PageLayout } from "./components/PageLayout/PageLayout";
import { b2cPolicies } from "./authConfig";
import { compareIssuingPolicy } from "./utils/claimUtils";

import "./styles/App.scss";
import "@mantine/carousel/styles.css";
import { useNavigate } from "react-router-dom";
import { Auth } from "./pages/Auth";
import { Error } from "./pages/Error";
import { Home } from "./pages/Home";
import { Questionnaire } from "./pages/Questionnaire";
import { FinalEstimatedQuote } from "./pages/FinalEstimatedQuote";
import { ConfirmVehicle } from "./pages/ConfirmVehicle";
import { ConfirmDetails } from "./pages/ConfirmDetails";
import { ConfirmAddress } from "./pages/ConfirmAddress";
import { FinalQuote } from "./pages/FinalQuote";
import { Agreements } from "./pages/Agreements";
import { Payment } from "./pages/Payment";
import { IneligibleVehicle } from "./pages/IneligibleVehicle";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { ThankYou } from "./pages/ThankYou";
import { Welcome } from "./pages/Welcome";
import { GlobalContext, GlobalContextProvider } from "./context/GlobalContext";
import { createTheme, Input, MantineProvider } from "@mantine/core";
import { Paths } from "./models/constants/paths";
import { b2cCodes } from "./models/constants/b2cCodes";
import { defaultContext } from "./models/constants/defaultContext";
import { reactPlugin } from "./components/ApplicationInsights/ApplicationInsights";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";

const Pages = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();
  const { context, setContext } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        // localStorage.setItem("dgb_id", event.payload.idTokenClaims.extension_DGBID);
        if (event.payload.idTokenClaims.dgbId) {
          setContext({
            ...context,
            user: {
              ...context.user,
              dgb_id: event.payload.idTokenClaims.dgbId,
            },
          });
        } else {
          setContext(defaultContext);
          navigate(Paths.Error);
        }
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          event.payload.idTokenClaims["tfp"] === b2cPolicies.names.editProfile
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                account.idTokenClaims["tfp"] === b2cPolicies.names.signUpSignIn
            );

          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */
        if (
          compareIssuingPolicy(
            event.payload.idTokenClaims,
            b2cPolicies.names.forgotPassword
          )
        ) {
          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (
          event.error &&
          event.error.errorMessage.includes(b2cCodes.RESET_PASSWORD)
        ) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
        if (event.error && event.error.errorMessage.includes(b2cCodes.CANCEL)) {
          setContext(JSON.parse(localStorage.getItem("context")));
          navigate(Paths.FinalEstimatedQuote);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route index path={Paths.AuthResponse} element={<Auth />} />
      <Route index path={Paths.Questionnaire} element={<Questionnaire />} />
      <Route index path={Paths.FinalEstimatedQuote} element={<FinalEstimatedQuote />} />
      <Route index path={Paths.ConfirmVehicle} element={<ConfirmVehicle />} />
      <Route index path={Paths.ConfirmDetails} element={<ConfirmDetails />} />
      <Route index path={Paths.ConfirmAddress} element={<ConfirmAddress />} />
      <Route index path={Paths.FinalQuote} element={<FinalQuote />} />
      <Route index path={Paths.Agreements} element={<Agreements />} />
      <Route index path={Paths.Payment} element={<Payment />} />
      <Route index path={Paths.IneligibleVehicle} element={<IneligibleVehicle />} />
      <Route index path={Paths.Error} element={<Error />} />
      <Route index path={Paths.TermsAndConditions} element={<TermsAndConditions />} />
      <Route index path={Paths.Welcome} element={<Welcome />} />
      <Route index path={Paths.ThankYou} element={<ThankYou />} />
      <Route path = "/*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

const theme = createTheme({
  components: {
    Input: Input.extend({
      styles: {
        input: {
          fontFamily: "Montserrat",
          fontSize: "18px",
          lineHeight: "160%",
        },
      },
    }),
  },
});

const App = ({ instance }) => {
  const [isUATverified, setIsUATverified] = useState(null);

  useEffect(() => {
    let flag = JSON.parse(process.env.REACT_APP_UAT_LOGIN_FLAG);
    if (typeof flag === "undefined") {
      sessionStorage.setItem("isUATverified", JSON.stringify(true));
    } else if (!sessionStorage.getItem("isUATverified")) {
      sessionStorage.setItem("isUATverified", JSON.stringify(!flag));
    }
    setIsUATverified(JSON.parse(sessionStorage.getItem("isUATverified")));
  }, []);

  const checkUATCredentials = () => {
    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;

    if (
      username === process.env.REACT_APP_UAT_USERNAME &&
      password === process.env.REACT_APP_UAT_PASSWORD
    ) {
      sessionStorage.setItem("isUATverified", JSON.stringify(true));
      setIsUATverified(true);
    } else {
      alert(
        "The username/password combination you have entered is invalid. Please try again."
      );
    }
  };

  return isUATverified === null ? null : isUATverified ? (
    <AppInsightsErrorBoundary onError={() => <Error />} appInsights={reactPlugin}>
      <GlobalContextProvider>
        <MantineProvider theme={theme}>
          <MsalProvider instance={instance}>
            <PageLayout>
              <Pages />
            </PageLayout>
          </MsalProvider>
        </MantineProvider>
      </GlobalContextProvider>
    </AppInsightsErrorBoundary>
  ) : (
    <>
      <h1 style={{ textAlign: "center", marginTop: "24px" }}>
        Care+ UA Testing
      </h1>
      <form
        onSubmit={checkUATCredentials}
        style={{
          margin: "48px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <input
          placeholder="Username"
          id="username"
          name="username"
          style={{
            marginBottom: "16px",
            minWidth: "256px",
            maxWidth: "384px",
            margin: "auto",
          }}
        />
        <input
          type="password"
          placeholder="Password"
          id="password"
          name="password"
          style={{
            marginBottom: "16px",
            minWidth: "256px",
            maxWidth: "384px",
            margin: "auto",
          }}
        />
        <button style={{ width: "128px", margin: "auto" }}>Log In</button>
      </form>
    </>
  );
};

export default App;
