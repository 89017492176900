import {
  Button,
  Center,
  Container,
  Flex,
  Group,
  Input,
  Modal,
  Paper,
  SegmentedControl,
  Select,
  Stack,
  Text,
} from "@mantine/core";
import { useMediaQuery, useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  categories,
  coveredComponents,
  nonCoveredComponents,
  desktopStyle,
  mobileStyle,
} from "../../models/constants/WhatsCovered";
import { IconSearch } from "@tabler/icons-react";
import { WhatsCoveredModal } from "../WhatsCovered/WhatsCoveredModal";

export const ComponentsList = (props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });
  const [selectedCategory, setSelectedCategory] = useState("engine");
  const [searchCriteria, setSearchCriteria] = useState("");
  const [showCovered, setShowCovered] = useState(true);
  const [opened, { open, close }] = useDisclosure(false);

  const filterCoveredComponents = (category) => {
    let list = [];
    coveredComponents
      .filter((component) => {
        if (component.category === category) {
          return component;
        }
      })
      .forEach((result) =>
        list.push({
          name: t(result.translationKey + ".text"),
          note: t(result.translationKey + ".note"),
        })
      );
    list = list.filter((component) => {
      if (
        component.name
          .toLocaleLowerCase()
          .includes(searchCriteria.toLocaleLowerCase())
      ) {
        return component;
      }
    });
    return list;
  };
  const [filteredCoveredComponents, setFilteredCoveredComponents] = useState(
    filterCoveredComponents("engine")
  );

  const displayCoveredComponents = () => {
    if (showCovered || !isMobile) {
      let displayedComponents = [];
      if (!filteredCoveredComponents.length) {
        return styledComponentBox("No matches found");
      }
      for (let i = 0; i < filteredCoveredComponents.length; i++) {
        displayedComponents.push(
          styledComponentBox(
            filteredCoveredComponents[i].name,
            filteredCoveredComponents[i].note,
            i
          )
        );
      }
      return displayedComponents;
    } else {
      return [];
    }
  };

  const filterNonCoveredComponents = (category) => {
    let list = [];
    nonCoveredComponents
      .filter((component) => {
        if (component.category === category) {
          return component;
        }
      })
      .forEach((result) => list.push(t(result.translationKey)));
    list = list.filter((component) => {
      if (
        component
          .toLocaleLowerCase()
          .includes(searchCriteria.toLocaleLowerCase())
      ) {
        return component;
      }
    });
    return list;
  };
  const [
    filteredNonCoveredComponents,
    setFilteredNonCoveredComponents,
  ] = useState(filterNonCoveredComponents("engine"));

  const displayNonCoveredComponents = () => {
    if (!(showCovered && isMobile)) {
      if (!filteredNonCoveredComponents.length) {
        return styledComponentBox("No matches found");
      }
      return filteredNonCoveredComponents.map((component, index) => {
        if (
          component
            .toLocaleLowerCase()
            .includes(searchCriteria.toLocaleLowerCase())
        ) {
          return styledComponentBox(component, null, index);
        }
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    setFilteredCoveredComponents(filterCoveredComponents(selectedCategory));
    setFilteredNonCoveredComponents(
      filterNonCoveredComponents(selectedCategory)
    );
  }, [selectedCategory]);

  useEffect(() => {
    setFilteredCoveredComponents(filterCoveredComponents(selectedCategory));
    setFilteredNonCoveredComponents(
      filterNonCoveredComponents(selectedCategory)
    );
  }, [searchCriteria]);

  const searchBar = () => {
    return (
      <Flex justify="center">
        <Input
          placeholder="Search"
          leftSection={<IconSearch className="searchIcon" />}
          onChange={(event) => {
            setSearchCriteria(event.currentTarget.value);
          }}
          maw="1125px"
          w="100%"
          id="components-search-bar"
        />
      </Flex>
    );
  };

  const styledComponentBox = (componentName, componentNote, index) => {
    return (
      <Paper
        shadow="0"
        withBorder
        radius="0"
        p="lg"
        styles={isMobile ? mobileStyle : desktopStyle}
        key={index}
      >
        <Stack gap="xs">
          <Text ff="Montserrat" fz="20px" fw="600">
            {componentName}
          </Text>
          {componentNote ? (
            <Text ff="Montserrat" fz="12px" fw="400">
              {componentNote}
            </Text>
          ) : null}
        </Stack>
      </Paper>
    );
  };

  const categoryData = categories.map((category) => {
    let componentCount = "";
    if (searchCriteria.length && filteredCoveredComponents.length) {
      componentCount = ` (${filteredCoveredComponents.length})`;
    }
    return {
      label: (
        <Group w="100%" justify="space-between">
          <Text fz="20px" fw="600">
            {t(category)}
          </Text>
          {/* <Text fz="20px" fw="600">
            {componentCount}
          </Text> */}
        </Group>
      ),
      value: category,
    };
  });

  const categoryDataLabels = categories.map((category) => {
    return { label: t(category), value: category };
  });

  return isMobile ? (
    <Center>
      <Stack>
        {searchBar()}
        <Flex
          justify="center"
          align="center"
          direction="column"
          gap="xl"
          styles={{
            root: {
              marginTop: "16px",
            },
          }}
        >
          <Select
            data={categoryDataLabels}
            allowDeselect={false}
            withCheckIcon={false}
            size="lg"
            defaultValue={categoryDataLabels[0]}
            value={selectedCategory}
            onChange={setSelectedCategory}
            styles={{
              root: {
                width: "375px",
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "600",
              },
              dropdown: {
                fontFamily: "Montserrat",
                fontSize: "16px",
              },
            }}
            id="components-dropdown-selector"
          />
          <Group w="100%" justify="center" id="components-display-selector">
            <Button
              w="47%"
              color="#00C08B"
              variant={showCovered ? "filled" : "outline"}
              styles={{
                root: {
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: 16,
                },
              }}
              onClick={() => setShowCovered(true)}
            >
              {t("covered")}
            </Button>
            <Button
              w="47%"
              color="#99310B"
              variant={showCovered ? "outline" : "filled"}
              styles={{
                root: {
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: 16,
                },
              }}
              onClick={() => setShowCovered(false)}
            >
              {t("not-covered")}
            </Button>
          </Group>
        </Flex>
        <Stack
          gap="0"
          styles={{
            root: {
              maxHeight: "645px",
              overflow: props.modal ? "scroll" : "hidden",
            },
          }}
          id="components-covered"
        >
          {showCovered || !isMobile ? (
            <Paper
              shadow="0"
              p="xs"
              radius="0"
              ff="Montserrat"
              fz="20px"
              fw="600"
              h="48px"
              pl="22px"
              mt="14px"
              styles={{
                root: {
                  background: "#00C08B",
                  color: "white",
                  width: "375px",
                  textAlign: "left",
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: isMobile ? "4px" : null,
                },
              }}
            >
              {t("covered") + ` (${filteredCoveredComponents.length || 0})`}
            </Paper>
          ) : null}
          {displayCoveredComponents()}
        </Stack>
        <Stack gap="0">
          {!showCovered || !isMobile ? (
            <Paper
              shadow="0"
              p="xs"
              radius="0"
              ff="Montserrat"
              fz="20px"
              fw="600"
              h="48px"
              pl="22px"
              styles={{
                root: {
                  background: "#99310B",
                  color: "white",
                  textAlign: "left",
                  borderTopLeftRadius: isMobile ? "4px" : null,
                  borderTopRightRadius: "4px",
                },
              }}
              id="components-not-covered"
            >
              {t("not-covered") +
                ` (${displayNonCoveredComponents().length || 0})`}
            </Paper>
          ) : null}
          {displayNonCoveredComponents()}
        </Stack>
        {props.modal ? null : (
          <Container>
            <Button
              onClick={open}
              variant="subtle"
              size="xl"
              c={"#00C08B"}
              id="components-modal-button"
            >
              {t("expand")}
            </Button>
          </Container>
        )}
      </Stack>
      <Modal
        fullScreen
        styles={{
          title: {
            fontFamily: "Crimson Text",
            fontSize: "24px",
            fontWeight: 600,
          },
        }}
        opened={opened}
        onClose={close}
        title="What's Covered"
        id="components-modal"
      >
        <WhatsCoveredModal category={selectedCategory} searchCriteria={searchCriteria}/>
      </Modal>
    </Center>
  ) : (
    <>
      {searchBar()}
      <Stack>
        <Flex
          justify="center"
          align="flex-start"
          direction="row"
          gap="xl"
          styles={{
            root: {
              marginTop: props.modal ? "8px" : "32px",
              overflow: "hidden",
              marginLeft: "8px",
            },
          }}
        >
          <SegmentedControl
            orientation="vertical"
            size={"xl"}
            data={categoryData}
            value={selectedCategory}
            defaultValue={categoryData[0]}
            onChange={setSelectedCategory}
            color="#000"
            styles={{
              root: {
                width: "300px",
                background: "#fff",
              },
              label: {
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "600",
              },
              indicator: {
                background: "#FF671D",
              },
            }}
            id="components-segmented-selector"
          />
          <Container mah={!props.modal ? null : "723px"} ml="0" mr="0">
            <Group
              gap="0"
              align="top"
              mah={"723px"}
              styles={{
                root: { overflowY: props.modal ? "scroll" : "hidden" },
              }}
            >
              <Stack gap="0">
                {showCovered || !isMobile ? (
                  <Paper
                    shadow="0"
                    p="xs"
                    radius="0"
                    ff="Montserrat"
                    fz="20px"
                    fw="600"
                    h="48px"
                    pl="22px"
                    styles={{
                      root: {
                        background: "#00C08B",
                        color: "white",
                        textAlign: "left",
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: isMobile ? "4px" : null,
                      },
                    }}
                    id="components-covered"
                  >
                    {t("covered") +
                      ` (${
                        filterCoveredComponents(selectedCategory).length || 0
                      })`}
                  </Paper>
                ) : null}
                {displayCoveredComponents()}
              </Stack>
              <Stack gap="0">
                {!showCovered || !isMobile ? (
                  <Paper
                    shadow="0"
                    p="xs"
                    radius="0"
                    ff="Montserrat"
                    fz="20px"
                    fw="600"
                    h="48px"
                    pl="22px"
                    styles={{
                      root: {
                        background: "#99310B",
                        color: "white",
                        textAlign: "left",
                        borderTopLeftRadius: isMobile ? "4px" : null,
                        borderTopRightRadius: "4px",
                      },
                    }}
                    id="components-not-covered"
                  >
                    {t("not-covered") +
                      ` (${displayNonCoveredComponents().length || 0})`}
                  </Paper>
                ) : null}
                {displayNonCoveredComponents()}
              </Stack>
            </Group>
          </Container>
        </Flex>
        {props.modal ? null : (
          <Container>
            <Button
              onClick={open}
              variant="subtle"
              size="xl"
              c={"#00C08B"}
              id="components-modal-button"
            >
              {t("expand")}
            </Button>
          </Container>
        )}
      </Stack>
      <Modal
        fullScreen
        styles={{
          title: {
            fontFamily: "Crimson Text",
            fontSize: "32px",
            fontWeight: 600,
            padding: "12px",
          },
        }}
        opened={opened}
        onClose={close}
        title="What's Covered"
        id="components-modal"
      >
        <WhatsCoveredModal category={selectedCategory} searchCriteria={searchCriteria}/>
      </Modal>
    </>
  );
};
