import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Box, Card, Image, Stack, Text, Title, Anchor } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Paths } from "../../models/constants/paths";
import "./CoverageCard.scss";

export const CoverageCard = (props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });

  const sampleContractLink = () => {
    return (
      <Anchor
        underline="hover"
        target="_blank"
        href={Paths.TermsAndConditions}
        ff="Montserrat"
        fz="18px"
      >
        {t("see-sample-contract")}
      </Anchor>
    );
  };

  return (
    <>
      <Card
        p={isMobile ? "32px 8px" : 32}
        bg={props.isCovered ? "" : "#F8F9FA"}
        w={376}
      >
        <Stack gap="16px" ta={"center"} align="center">
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "96px",
              height: "96px",
              background: "#F7FFFE",
              borderRadius: "4px",
            }}
          >
            <Image src={props.icon} className="card-icon" />
          </Box>
          <Stack gap="8px">
            <Title ff="Montserrat" fz="20px" fw={600} lh="165%">
              {t(props.title)}
            </Title>
            <Text ff="Montserrat" fz="18px" fw={400} lh="160%">
              <Trans
                i18nKey={props.text}
                t={t}
                values={{ sampleContractLink }}
                components={{ contractLink: sampleContractLink() }}
              />
            </Text>
          </Stack>
        </Stack>
      </Card>
    </>
  );
};
