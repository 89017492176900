import { ExistingIssues } from "../components/ExistingIssues/ExistingIssues";
import { VehicleFeatures } from "../components/VehicleFeatures/VehicleFeatures";
import { ContinueButton } from "../components/ContinueButton/ContinueButton";
import { StepperComponent } from "../components/Stepper/Stepper";
import { InitialQuote } from "../components/InitialQuote/InitialQuote";
import { VehicleDetails } from "../components/VehicleDetails/VehicleDetails";
import { PreviousButton } from "../components/PreviousButton/PreviousButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { GlobalContext } from "../context/GlobalContext";
import { Paths } from "../models/constants/paths";
import axios from "axios";
import { CarePlusEndpoints } from "../models/constants/carePlusEndpoints";
import { React, useContext, useEffect, useState } from "react";
import { Center, Loader } from "@mantine/core";
import { defaultContext } from "../models/constants/defaultContext";
import { appInsights } from "../components/ApplicationInsights/ApplicationInsights";
import { PageTitleMetaTags } from "../components/PageTitleMetaTags/PageTitleMetaTags";
import { AzureBlobFiles } from "../models/constants/azureBlobFiles";

export const Questionnaire = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { context, setContext } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isVinVerified, setIsVinVerified] = useState(false);
  const [vehicle, setVehicle] = useState();
  const pageTitle = t("browser-tab-titles.questionnaire");
  const pageDescription = t("browser-tab-descriptions.questionnaire");
  const metaTitle = t("browser-meta-titles.questionnaire");
  const metaDescription = t("browser-meta-descriptions.questionnaire");

  const generateQuote = async (vehicle_data) => {
    if (vehicle_data.vin) {
      getVinQuote(vehicle_data).then((res) => {
        if (res?.data?.ineligibility_reasons.length) {
          navigate(Paths.IneligibleVehicle, { replace: true, state: {reasons: res.data.ineligibility_reasons}});
        }
        setContext({
          ...context,
          initial_quote: res?.data,
          surcharges: {
            ...context.surcharges,
            diesel_surcharge_value: parseFloat(res.data.diesel_surcharge_value),
            four_by_four_surcharge_value: parseFloat(res.data.four_by_four_surcharge_value),
            turbo_surcharge_value: parseFloat(res.data.turbo_surcharge_value)
          },
          vehicle_data: {
            ...context.vehicle_data,
            year: res?.data?.vin_decoded_year,
            make: res?.data?.vin_decoded_make,
            model: res?.data?.vin_decoded_model,
          }
        });
        setIsVinVerified(true);
        setIsLoading(false);
      }).catch(() => {
          navigate(Paths.Error);
      });
    }
    else if (vehicle_data.license_plate && vehicle_data.state) {
      getLicensePlateQuote(vehicle_data).then((res) => {
        if (res?.data?.ineligibility_reasons.length) {
          navigate(Paths.IneligibleVehicle, { replace: true, state: {reasons: res.data.ineligibility_reasons}});
        }
        setContext({
          ...context,
          initial_quote: res?.data,
          surcharges: {
            ...context.surcharges,
            diesel_surcharge_value: parseFloat(res.data.diesel_surcharge_value),
            four_by_four_surcharge_value: parseFloat(res.data.four_by_four_surcharge_value),
            turbo_surcharge_value: parseFloat(res.data.turbo_surcharge_value)
          },
          vehicle_data: {
            ...context.vehicle_data,
            year: res?.data?.vin_decoded_year,
            make: res?.data?.vin_decoded_make,
            model: res?.data?.vin_decoded_model,
          }
        });
        setIsVinVerified(true);
        setIsLoading(false);
      }).catch(() => {
        navigate(Paths.Error);
      });
    }
    else if (vehicle_data.year && vehicle_data.make && vehicle_data.model) {
      getInitialQuote(vehicle_data).then((res) => {
        console.log(res);
        setContext({
          ...context,
          initial_quote: res?.data,
        });
        if (res?.data?.ineligibility_reasons?.length) {
          navigate(Paths.IneligibleVehicle, {
            replace: true,
            state: { reasons: res.data.ineligibility_reasons },
          });
        }
        setIsLoading(false);
      }).catch(() => {
        navigate(Paths.error);
      });
    }
    else {
      navigate(Paths.Error)
    }
  };

  const getInitialQuote = async (vehicle_data) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_API_URL + CarePlusEndpoints.InitialQuote,
        {
          params: {
            year: vehicle_data.year,
            make: vehicle_data.make,
            model: vehicle_data.model,
            mileage: vehicle_data.mileage,
          },
          timeout: process.env.REACT_APP_API_TIMEOUT
        }
      );
      return response;
    } catch (error) {
      appInsights.trackException({ error: new Error(error), severityLevel: 1 });
      navigate(Paths.Error);
    }
  };

  const getVinQuote = async (vehicle_data) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_API_URL + CarePlusEndpoints.InitialQuoteByVin,
        {
          params: {
            vin: vehicle_data.vin,
            mileage: vehicle_data.mileage,
          },
          timeout: process.env.REACT_APP_API_TIMEOUT
        }
      );
      return response;
    }
    catch (error) {
      navigate(Paths.Error);
    }
  }

  const getLicensePlateQuote = async (vehicle_data) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_API_URL + CarePlusEndpoints.InitialQuoteByLicensePlate,
        {
          params: {
            plate_number: vehicle_data.license_plate,
            state: vehicle_data.state,
            mileage: vehicle_data.mileage,
          },
          timeout: process.env.REACT_APP_API_TIMEOUT
        }
      );
      return response;
    }
    catch (error) {
      navigate(Paths.Error);
    }
  }

  const handleContinue = () => {
    setContext({
      ...context,
      completed_pages: { ...context.completed_pages, questionnaire: true },
    });
    navigate(Paths.FinalEstimatedQuote);
  };

  useEffect(() => {
    setIsLoading(true);
    if (!context.completed_pages.home) {
      setContext(defaultContext);
      navigate(Paths.Home);
    } 
    if (!context.initial_quote.base_rate) {
      generateQuote(context.vehicle_data).then(() => {
        setVehicle(context.vehicle_data);
      }).catch((error) => {
        appInsights.trackException({ error: new Error(error), severityLevel: 1 });
        navigate(Paths.Error)
      });
    } else {
      if (context.vehicle_data.vin || context.vehicle_data.license_plate) {
        setIsVinVerified(true);
      }
      setVehicle(context.vehicle_data);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setVehicle(context.vehicle_data);
  }, [context.vehicle_data]);

  const continueDisabled = isVinVerified ? 
    (context.user_answers.problems === null ||
    (context.user_answers.problems &&
      context.user_answers.problems_details === "") ||
    context.user_answers.lift_kit_over_six_inches === null ||
    context.user_answers.aftermarket_enhancements === null)
    : 
    (context.user_answers.problems === null ||
    (context.user_answers.problems &&
      context.user_answers.problems_details === "") ||
    context.user_answers.awd === null ||
    context.user_answers.diesel === null ||
    context.user_answers.lift_kit_over_six_inches === null ||
    context.user_answers.oem_turbo_or_super === null ||
    context.user_answers.aftermarket_enhancements === null);

  return isLoading ? (
    <div className="full-container">
      <div className="content-container">
        <Center>
          <Loader color="#006B5B" size="xl" />
        </Center>
      </div>
    </div>
  ) : (
    <>
      <PageTitleMetaTags
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        metaImage={AzureBlobFiles.MetaImage}
      />
      <div className="full-container">
        <div className="content-container">
          <PreviousButton path={Paths.Home} />
          <InitialQuote vinVerified={isVinVerified} />
          <VehicleDetails vehicle={vehicle} />
          <div className="header">
            <div className="header_2">{t("tell-us-more")}</div>
          </div>
          <div className="subheader">{t("we-have-questions")}</div>
          <ExistingIssues />
          <VehicleFeatures vinVerified={isVinVerified} />
          <ContinueButton
            className="button"
            disabled={continueDisabled}
            label={t("continue-questionnaire")}
            clickAction={() => handleContinue()}
          />
        </div>
      </div>
    </>
  );
};
