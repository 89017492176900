import { React } from "react";
import { Container } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { InitialQuoteForm } from "../InitialQuoteForm/InitialQuoteForm";
import IconInitialQuote from "../../assets/images/20210618_CBA_0801_ED.jpg";

export const BrochureQuoteImage = (props) => {
	const isMobile = useMediaQuery("(max-width: 1024px)", true, {
		getInitialValueInEffect: false,
	});

	return (
		<>
			{isMobile ? (
				null
			) : (
				<Container
					size={"responsive"}
					p={0}
					style={{
						backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${IconInitialQuote})`,
						backgroundSize: "cover",
            backgroundPosition: "center",
						display: "flex",
						alignItems: "center",
						height: "988px",
					}}
				>
					<Container maw="600px">
						<InitialQuoteForm year={props.year} make={props.make} model={props.model} mileage={props.mileage} vin={props.vin} />
					</Container>
				</Container>
			)}
		</>
	);
};
