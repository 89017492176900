export const defaultContext = {
  vehicle_data: {
    dvi_url: null,
    license_plate: null,
    make: null,
    mileage: null,
    model: null,
    shop_address1: null,
    shop_address2: null,
    shop_city: null,
    shop_name: null,
    shop_number: null,
    shop_state: null,
    shop_zip: null,
    state: null,
    vin: null,
    year: null,
  },
  quote_form_data: {
    license_plate: null,
    make: null,
    mileage: null,
    model: null,
    state: null,
    vin: null,
    year: null,

  },
  user_answers: {
    aftermarket_enhancements: null,
    awd: null,
    diesel: null,
    lift_kit_over_six_inches: null,
    oem_turbo_or_super: null,
    problems: null,
    problems_details: "",
  },
  initial_quote: {
    base_rate: null,
    discounted_one_time_payment: null,
    ineligibility_reasons: [],
    one_time_payment: null,
    one_time_payment_discount_amount: null,
    rate_class: null,
  },
  surcharges: {
    diesel_surcharge_value: null,
    four_by_four_surcharge_value: null,
    turbo_surcharge_value: null,
  },
  final_quote: {
    base_rate: null,
    discounted_one_time_payment: null,
    monthly_rate: null,
    one_time_payment: null,
    one_time_payment_discount_amount: null,
    one_time_payment_tax: null,
    plan_code: null,
    subtotal_tax: null,
  },
  stripe_subscription_data: {
    contract_pdf: null,
    stripe_client_secret: null,
    stripe_subscription_id: null,
  },
  pcmi_contract_data: {
    contract_number: null,
  },
  user: {
    // dgb_id: null,
    first_name: null,
    last_name: null,
    email: null,
    mailing_address: {
      line1: null,
      line2: null,
      city: null,
      state: null,
      postal_code: null,
      country: null,
    },
    vehicles: null,
    stripe_account: null,
  },
  selected_vin: null,
  docusign: {
    envelope_id: null,
  },
  completed_pages: {
    agreements: false,
    confirm_address: false,
    confirm_details: false,
    confirm_vehicle: false,
    final_estimated_quote: false,
    final_quote: false,
    home: false,
    questionnaire: false,
    payment: false,
  },
};
