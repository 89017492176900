import { Paper, Textarea } from "@mantine/core";
import { ToggleButton } from "../ToggleButton/ToggleButton";
import { useTranslation } from "react-i18next";
import { React, useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";

export const QuestionBox = ({ id, header, subheader }) => {
  const { t } = useTranslation();
  const { context, setContext } = useContext(GlobalContext);

  function displayToggleOrTextbox() {
    if (id === "problems_details") {
      return (
        <Textarea
          id="problemTextbox"
          placeholder={t("describe-problems")}
          value={context.user_answers.problems_details}
          autosize={true}
          minRows={5}
          onChange={(event) =>
            setContext({
              ...context,
              user_answers: {
                ...context.user_answers,
                problems_details: event.currentTarget.value,
              },
            })
          }
        />
      );
    } else {
      return (
        <ToggleButton
          questionId={id}
          leftButtonText={t("yes")}
          rightButtonText={t("no")}
        />
      );
    }
  }

  return (
    <Paper shadow="0" radius="md" p="24px" withBorder style={{ marginBottom: "16px", borderWidth: "2px" }}>
      <div className="text-box-header">{header}</div>
      <div className="text-box-body">{subheader}</div>
      {displayToggleOrTextbox()}
    </Paper>
  );
};
