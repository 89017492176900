import React from "react";
import {
  Container,
  Image,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";
import CarePlusVsImage from "../../assets/images/Care+ Coverage Graphic_Image_lg.png"

export const CarePlusVs = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });

  return (
    <Container size={"responsive"} px={0} id="care_plus_vs">
      <Stack
        p={isMobile ? "48px 24px" : "6.8% 19% 5.3% 19%"}
        gap={isMobile ? "32px" : "56px"}
      >
        <Container size={"responsive"} px={0} ta={"center"}>
          <Title ff="Crimson Text" fz="40px">
            {t("care-plus-vs-header")}
          </Title>
          <Text ff="Montserrat" fz="20px" lh="160%" c="#495057" mt="16px">
            {t("care-plus-vs-subheader1")}
          </Text>
          <Text ff="Montserrat" fz="20px" lh="160%" c="#495057" mt="16px">
            {t("care-plus-vs-subheader2")}
          </Text>
        </Container>
        <Image src={CarePlusVsImage}/>
      </Stack>
    </Container>
  );
};
