import {
  Anchor,
  Button,
  Center,
  Container,
  Flex,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useMediaQuery, useDisclosure } from "@mantine/hooks";
import { useTranslation, Trans } from "react-i18next";
import { React, useState } from "react";
import { ComponentsList } from "../ComponentsList/ComponentsList";
import {
  categories,
  notCoveredCardsData,
} from "../../models/constants/WhatsCovered";
import { CoverageCard } from "../CoverageCard/CoverageCard";
import { Paths } from "../../models/constants/paths";

export const WhatsCovered = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });

  const handleScrollToTop = () => {
    const section = document.getElementById("start_quote");
    const offset = 10;
    const sectionPosition = section.getBoundingClientRect().top;
    const offsetPosition = sectionPosition + window.scrollY - offset;

    window.scrollTo({ top: offsetPosition, behavior: "smooth" });
  };

  const sampleContractLink = () => {
    return (
      <Anchor
        underline="hover"
        target="_blank"
        href={Paths.TermsAndConditions}
        ff="Montserrat"
        fz="14px"
        c="#FF671D"
      >
        {t("whats-covered-fine-print")}
      </Anchor>
    );
  };

  return isMobile ? (
    <Container size={"responsive"} px={0} bg={"#F8F9FA"} id="whats_covered">
      <Stack p={"48px 6%"} styles={{ root: { width: "100%" } }}>
        <Stack ta={"center"}>
          <Title ff="Crimson Text" fz="40px" lh={"120%"}>
            {t("whats-covered")}
          </Title>
          <Text ff="Montserrat" fz="20px" lh="165%" c="#495057">
            {t("whats-covered-description")}
            <Text ff="Montserrat" fz="20px" lh="165%" c="#495057">
              {t("whats-covered-description-2")}
            </Text>
          </Text>
        </Stack>
        <ComponentsList />
      </Stack>
      <Center>
        <Text
          styles={{
            root: {
              fontFamily: "Montserrat",
              fontSize: 14,
              fontStyle: "italic",
              maxWidth: "360px",
            },
          }}
        >
          <Trans
            i18nKey={"whats-covered-fine-print"}
            t={t}
            values={{ sampleContractLink }}
            components={{ contractLink: sampleContractLink() }}
          />
        </Text>
      </Center>
      <Stack p="48px 6%" gap={24}>
        <Stack ta="center">
          <Title ff="Crimson Text" fz="32px" lh={"120%"}>
            {t("other-coverage-exceptions")}
          </Title>
        </Stack>
        <Flex wrap="wrap" justify="center" gap={0} mt="24px">
          {notCoveredCardsData.map((card, index) => (
            <CoverageCard key={index} {...card} isCovered={true} />
          ))}
        </Flex>
        <Button
          onClick={handleScrollToTop}
          radius={4}
          fullWidth
          color="#FF671D"
          className="button"
        >
          {t("get-your-quote")}
        </Button>
      </Stack>
    </Container>
  ) : (
    <Container size={"responsive"} px={0} bg={"#F8F9FA"} id="whats_covered">
      <Stack p={"6.8% 19% 2% 19%"} styles={{ root: { width: "100%" } }}>
        <Stack ta={"center"}>
          <Title ff="Crimson Text" fz="40px" lh={"120%"}>
            {t("whats-covered")}
          </Title>
          <Text ff="Montserrat" fz="20px" lh="165%" c="#495057">
            {t("whats-covered-description")}
          </Text>
          <Text ff="Montserrat" fz="20px" lh="165%" c="#495057">
            {t("whats-covered-description-2")}
          </Text>
        </Stack>
      </Stack>
      <ComponentsList modal={false} />
      <Center>
        <Text
          styles={{
            root: {
              fontFamily: "Montserrat",
              fontSize: 14,
              fontStyle: "italic",
              maxWidth: "800px",
            },
          }}
        >
          <Trans
            i18nKey={"whats-covered-fine-print"}
            t={t}
            values={{ sampleContractLink }}
            components={{ contractLink: sampleContractLink() }}
          />
        </Text>
      </Center>
      <Stack p={"4.4% 22.8%"} gap={24}>
        <Stack ta="center">
          <Title ff="Crimson Text" fz="32px" lh={"120%"}>
            {t("other-coverage-exceptions")}
          </Title>
        </Stack>
        <Flex wrap="wrap" justify="center" gap={20} mt="24px">
          {notCoveredCardsData.map((card, index) => (
            <CoverageCard key={index} {...card} isCovered={true} />
          ))}
        </Flex>
        <Container size={"responsive"}>
          <Button
            onClick={handleScrollToTop}
            radius={4}
            size="md"
            color="#FF671D"
            className="button"
          >
            {t("get-your-quote")}
          </Button>
        </Container>
      </Stack>
    </Container>
  );
};
