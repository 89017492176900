import { React } from "react";
import { useTranslation, Trans } from "react-i18next";
import {
  Anchor,
  Center,
  Container,
  Divider,
  Flex,
  Image,
  Stack,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import IconPhone from "../../assets/images/Phone.svg";
import IconMail from "../../assets/images/Mail.svg";
import IconLogo from "../../assets/images/Horizontal.svg";
import IconLinkedIn from "../../assets/images/Linkedin.svg";
import IconX from "../../assets/images/X.svg";
import IconFacebook from "../../assets/images/Facebook.svg";
import { Paths } from "../../models/constants/paths";
import { boldText } from "../../utils/textStyling";

export const BrochureFooter = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 1024px)", true, {
    getInitialValueInEffect: false,
  });

  return (
    <>
      <Container size={"responsive"} px={0} bg={"#004C3D"}>
        <Center>
          <Stack gap={32} p={"32px 24px"} w={1088}>
            <Stack gap={16} ff={"Montserrat"} fz={14} fw={600} lh={"145%"}>
              <Anchor
                href="https://www.cbac.com/privacy-policy/"
                target="_blank"
                c={"white"}
              >
                {t("privacy-policy")}
              </Anchor>
              <Anchor
                href="#"
                onClick={() => Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')}
                c={"white"}
              >
                {t("your-privacy-choices")}
                <img
                  src="https://www.cbac.com/images/privacyoptions-white.svg"
                  alt="CCPA Opt-Out"
                  style={{ width: "30px", height: "30px", marginLeft: "8px", paddingBottom: "3px"}}
                />
              </Anchor>
              <Anchor
                href={Paths.TermsAndConditions}
                target="_blank"
                c={"white"}
              >
                {t("terms-and-conditions")}
              </Anchor>
            </Stack>
            <Stack
              gap={8}
              ff={"Montserrat"}
              fz={14}
              fw={400}
              lh={"145%"}
              c={"white"}
            >
              <Text tt={"uppercase"}>{t("contact-us")}</Text>
              <Flex gap={8} align={"center"}>
                <Image
                  src={IconPhone}
                  style={{ width: "20px", height: "20px" }}
                />
                <Text tt={"uppercase"}>{t("phone-number")}</Text>
              </Flex>
              <Flex gap={8} align={"center"}>
                <Image
                  src={IconMail}
                  style={{ width: "20px", height: "20px" }}
                />
                <Text tt={"uppercase"}>{t("email")}</Text>
              </Flex>
            </Stack>
            <Divider />
            <Flex
              gap={32}
              direction={isMobile ? "column" : "row"}
              justify={"space-between"}
              align={isMobile ? "" : "center"}
            >
              <Image src={IconLogo} w={isMobile ? 263 : 440} />
              <Flex gap={8}>
                <Anchor
                  href="https://www.linkedin.com/company/christian-brothers-automotive/"
                  target="_blank"
                >
                  <Image src={IconLinkedIn} />
                </Anchor>
                <Anchor
                  href="https://twitter.com/christianbros"
                  target="_blank"
                >
                  <Image src={IconX} />
                </Anchor>
                <Anchor
                  href="https://www.facebook.com/ChristianBrothersAuto/"
                  target="_blank"
                >
                  <Image src={IconFacebook} />
                </Anchor>
              </Flex>
            </Flex>
            <Divider />
            <Stack gap={"1rem"}>
              <Text
                ff={"Montserrat"}
                fz={"12.5pt"}
                fw={400}
                lh={"140%"}
                c={"#DEE2E6"}
              >
                {t("trademark")}
              </Text>
              <Text
                ff={"Montserrat"}
                fz={"12.5pt"}
                fw={400}
                lh={"140%"}
                c={"#DEE2E6"}
              >
                <Trans
                  i18nKey={"disclaimer-1"}
                  t={t}
                  values={{ boldText }}
                  components={{
                    bold: boldText("disclaimer-1"),
                  }}
                />
              </Text>
              <Text ff={"Montserrat"} fz={"12.5pt"} c="white" fw={700}>
                {t("disclaimer-2")}
              </Text>
            </Stack>
          </Stack>
        </Center>
      </Container>
    </>
  );
};
