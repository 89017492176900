import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { ContinueButton } from '../components/ContinueButton/ContinueButton';
import { Paths } from '../models/constants/paths';
import { defaultContext } from '../models/constants/defaultContext';
import { React, useContext } from 'react';
import { PageTitleMetaTags } from "../components/PageTitleMetaTags/PageTitleMetaTags";
import { AzureBlobFiles } from "../models/constants/azureBlobFiles";

export const Error = () => {
  const {t} = useTranslation();
  const { setContext} = useContext(GlobalContext);
  const navigate = useNavigate();
  const pageTitle = t("browser-tab-titles.error");
  const pageDescription = t("browser-tab-descriptions.error");
  const metaTitle = t("browser-meta-titles.error");
  const metaDescription = t("browser-meta-descriptions.error");

  function clearContextAndRestart () {
    setContext(defaultContext);
    navigate(Paths.Home);
  }

  return (
    <div className='full-container'>
      <PageTitleMetaTags
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        metaImage={AzureBlobFiles.MetaImage}
      />
      <div className='full-container_ineligible-vehicle'>
        <div className='content-container'>
          <div className='header'>
            <div className='header_2'>
              <div className='header_2_centered'>
                {t('something-went-wrong')}
              </div>
            </div>
            <ContinueButton label={t('return')} clickAction={() => clearContextAndRestart()}/> 
          </div>
        </div>
      </div>
    </div>
  )
}