import { AuthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { Container } from 'react-bootstrap';
import { IdTokenData } from '../components/DataDisplay/DataDisplay';
import { useTranslation } from "react-i18next";
import { React } from "react";
import { PageTitleMetaTags } from "../components/PageTitleMetaTags/PageTitleMetaTags";
import { AzureBlobFiles } from "../models/constants/azureBlobFiles";

/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const Auth = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const { t } = useTranslation();
    const pageTitle = t("browser-tab-titles.auth");
    const pageDescription = t("browser-tab-descriptions.auth");
    const metaTitle = t("browser-meta-titles.auth");
    const metaDescription = t("browser-meta-descriptions.auth");

    return (
        <>
            <PageTitleMetaTags
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            metaTitle={metaTitle}
            metaDescription={metaDescription}
            metaImage={AzureBlobFiles.MetaImage}
            />
            <AuthenticatedTemplate>
                {activeAccount ? (
                    <Container>
                        <IdTokenData idTokenClaims={activeAccount.idTokenClaims} />
                    </Container>
                ) : null}
            </AuthenticatedTemplate>
        </>
    );
};