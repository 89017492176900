import { React, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/GlobalContext";
import { Paper, Center, Title, ThemeIcon } from "@mantine/core";
import { IconShieldCheckFilled, IconMapPin } from "@tabler/icons-react";

export const HomeShopDetails = () => {
  const { t } = useTranslation();
  const { context } = useContext(GlobalContext);

  return (
    <>
      <div>
        <Center>
          <IconShieldCheckFilled
            style={{
              color: "#006B5B",
              alignSelf: "flex-start",
              width: "96px",
              height: "96px",
            }}
          />
        </Center>
      </div>
      <div className="header">
        <div className="header_4">
          <Title
            ff="Crimson Text"
            fz="40px"
            lh={"120%"}
            fs="normal"
            fw="600px"
            c="#006B5B"
          >
            {t("welcome-header")}
          </Title>
        </div>
        <Center>
          <div className="subheader_centerbold">
            {t("welcome-subheader-bold", { make: context.vehicle_data.make })}
          </div>
        </Center>
        <Center>
          <div className="subheader_centered">{t("welcome-subheader")}</div>
        </Center>
      </div>
      <Paper
        shadow="0"
        radius="md"
        title={t("home-shop")}
        p="16px"
        withBorder
        style={{ marginBottom: "32px", borderWidth: "2px" }}
      >
        <div className="home-shop-styling">{t("home-shop")}</div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <ThemeIcon size="lg" color="#E7F5FF" marginRight="8px">
            <IconMapPin
              color="#339AF0"
              style={{ width: "75%", height: "75%"}}
            ></IconMapPin>
          </ThemeIcon>
          <div className="vehicle-details-container">
            <div className="vehicle-details-header">
              {"CBA "}
              {context.vehicle_data.shop_name}
            </div>
            <div className="vehicle-details-subheader">
              {context.vehicle_data.shop_address1}{" "}
              {context.vehicle_data.shop_address2}{" "}
            </div>
            <div className="vehicle-details-subheader">
              {context.vehicle_data.shop_city}{", "} 
              {context.vehicle_data.shop_state} {context.vehicle_data.shop_zip}
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};
