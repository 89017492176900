import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Image, Stack, Text } from "@mantine/core";
import Quote from "../../assets/images/Quote.svg";

export const TestimonialCard = (props) => {
	const { t } = useTranslation();

	return (
		<>
			<Card p={32} bg={"#F8F9FA"} h={"100%"}>
				<Stack gap="16px">
					<Image src={Quote} style={{ width: "24px", height: "24px" }} />
					<Stack>
						<Text ff="Montserrat" fz="18px" fw={400} lh="160%">
							{t(props.review)}
						</Text>
						<Text ff="Montserrat" fz="16px" fw={600} lh="155%">
							{t(props.customer)}
						</Text>
					</Stack>
				</Stack>
			</Card>
		</>
	);
};
