import { defaultContext } from '../models/constants/defaultContext';
import { React, createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalContextProvider = (props) => {
  const [context, setContext] = useState(defaultContext);

  return (
    <GlobalContext.Provider value={{context: context, setContext: setContext}}>
      {props.children}
    </GlobalContext.Provider>
  )
}