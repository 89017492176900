import { React, useEffect } from "react";
import { Container, Flex, Image, Modal, Stack, Text } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import MobileLogo from "../../assets/images/Legacy-CBA-Logo.svg";

export const BrochureBanner = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMediaQuery("(max-width: 740px)", true, {
    getInitialValueInEffect: false,
  });

  const today = Date.now();
  const startDate = Date.parse(process.env.REACT_APP_PROMO_START);
  const endDate = Date.parse(process.env.REACT_APP_PROMO_END);

  useEffect(() => {
    if (today >= startDate && today < endDate) {
      open();
    }
  }, []);

  return (
    <Modal
      size="lg"
      opened={opened}
      onClose={close}
      title={
        <Flex direction={isMobile ? "column" : "row"} justify="center">
          <Container styles={{ root: {alignContent: "center"}}}>
            <Image src={MobileLogo} w={"135px"} h={"60px"}></Image>
          </Container>
          <Text data-autofocus styles={{ root: { fontFamily: "Montserrat", fontSize: "20pt", textAlign: "center", marginTop: "8px" }}}>
            Sign Up Now & Save $285!
          </Text>
        </Flex>
      }
      styles={{
        root: { width: "100vw" },
        content: { backgroundColor: "white", color: "#006B5B", fontWeight: "bold" },
        close: { color: "#006B5B", position: "absolute", top: 10, right: 10 },
        header: { display: isMobile ? "block" : "flex" }
      }}
    >
      <Text styles={{ root: { textAlign: "center" } }}>
        For a limited time, enroll in Nice Difference Care+ and we’ll waive the
        $285 registration fee! Automatically applied at checkout.
      </Text>
    </Modal>
  );
};
