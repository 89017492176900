import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Stack, TextInput, NumberInput, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useContext } from "react";
import { useNavigate } from "react-router";
import { GlobalContext } from "../../context/GlobalContext";
import { defaultContext } from "../../models/constants/defaultContext";
import { Paths } from "../../models/constants/paths";

export const VINForm = (props) => {
  const { t } = useTranslation();
  const { context, setContext } = useContext(GlobalContext);
  const [vin, setVin] = useState("");
  const [mileage, setMileage] = useState("");
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      vin: null || context.quote_form_data.vin || props.vin,
      mileage: null || context.quote_form_data.mileage || props.mileage,
    },
    validate: {
      vin: (value) => (!value ? t("vin-required") : null),
      vin: (value) => (value.length === 17 ? null : t("vin-length")),
      mileage: (value) => (!value ? t("vehicle-mileage-required") : null),
    },
  });

  const autoFillForm = (vin, mileage) => {
    form.setFieldValue("vin", vin);
    setVin(vin);
    form.setFieldValue("mileage", mileage);
    setMileage(mileage);
  };

  const submitForm = (formData) => {
    if (form.isValid()) {
      setContext({
        ...context,
        vehicle_data: {
          ...context.vehicle_data,
          vin: formData.vin,
          mileage: formData.mileage,
        },
        quote_form_data: {
          ...context.quote_form_data,
          vin: formData.vin,
          mileage: formData.mileage,
        },
        completed_pages: { ...context.completed_pages, home: true },
      });
      navigate(Paths.Questionnaire);
    }
  };

  useEffect(() => {
    if (context.quote_form_data.mileage && context.quote_form_data.vin) {
      autoFillForm(context.quote_form_data.vin, context.quote_form_data.mileage);
    }
  }, []);

  useEffect(() => {
    if (form.values.vin != context.quote_form_data.vin) {
      setContext({
        ...defaultContext,
        quote_form_data: context.quote_form_data,
      });
    }
    setVin(form.values.vin);
  }, [form.values.vin]);

  useEffect(() => {
    if (
      form.values.mileage &&
      form.values.mileage !== context.vehicle_data.mileage
    ) {
      setContext({
        ...defaultContext,
        quote_form_data: context.quote_form_data,
      });
    }
    setMileage(form.values.mileage);
  }, [form.values.mileage]);


  return (
    <form id="vinForm" onSubmit={form.onSubmit((values) => submitForm(values))}>
      <Stack gap={16} ff={"Montserrat"} fz={16} fw={600} lh={"155%"}>
        <TextInput
          label={t("vin")}
          placeholder={t("vin")}
          size="lg"
          maxLength={17} //validate alphanumeric numbers, exactly 17 or add max and min length
          value={vin}
          {...form.getInputProps("vin")}
        />
        <NumberInput
          label={t("mileage")}
          placeholder={t("mileage")}
          maxLength={7}
          allowDecimal={false}
          allowNegative={false}
          allowLeadingZeros={false}
          thousandSeparator=","
          hideControls
          size="lg"
          value={mileage}
          {...form.getInputProps("mileage")}
        />
      </Stack>
      <Button type="submit" fullWidth color="#FF671D" className="button">
        {t("get-your-quote")}
      </Button>
    </form>
  );
};
