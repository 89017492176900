import { useTranslation } from "react-i18next";
import { QuestionBox } from "../QuestionBox/QuestionBox";
import { GlobalContext } from "../../context/GlobalContext";
import { React, useContext } from 'react';

export const ExistingIssues = () => {
  const {t} = useTranslation();
  const {context} = useContext(GlobalContext)

  function displayProblemDetails () {
    if (context.user_answers.problems) {
      return <QuestionBox id={'problems_details'} header={t('explain-known-problems')} />
    }
  }

  return (
    <>
      <div className="content-container">
        <div className="header">
          <div className="header_3">
            {t('existing-issues')}
          </div>
        </div>
        <QuestionBox id={'problems'} header={t('any-problems')} subheader={t('example-problems')}/>
        {displayProblemDetails()}
      </div>
    </>
  )
}