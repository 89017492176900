import { Container } from "@mantine/core";
import { React } from "react";
import { PdfViewer } from "../PdfViewer/PdfViewer";

export const PDFModal = (props) => {
  return (
    <Container size={"responsive"} px={0}>
      <PdfViewer url={props.url} height={"800vh"}/>
    </Container>
  );
};
