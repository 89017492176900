import { React, useState } from "react";
import { Container } from "@mantine/core";
import { ActionIcon, Group } from "@mantine/core";
import { IconPlayerPlay } from "@tabler/icons-react";
import "./VideoPlayer.scss";
import Vimeo from "@u-wave/react-vimeo";

export const VideoPlayer = (props) => {
  const [isPlaying, setVideoPlaying] = useState(false);

  const handleClick = () => {
    setVideoPlaying(true);
    window.dataLayer.push({ event: "video_loaded" });
  };

  return (
    <>
      {!isPlaying ? (
        <Container className="wrapper" onClick={() => handleClick()}>
          <Vimeo
            video={props.video_url}
            className="react-player"
            controls={false}
            showTitle={false}
            responsive={true}
            autoplay={false}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              cursor: "pointer",
            }}
          >
            <Group justify="center">
              <ActionIcon
                onClick={() => {
                  handleClick();
                }}
                variant="default"
                size="xl"
                aria-label="Toggle color scheme"
              >
                <IconPlayerPlay stroke={1.5} />
              </ActionIcon>
            </Group>
          </div>
        </Container>
      ) : (
        <Container size={"responsive"} ml={0} mr={0} pl={0} pr={0}>
          <Container className="wrapper">
            <Vimeo
              video={props.video_url}
              className="react-player"
              controls={true}
              autoplay={true}
              showTitle={false}
              responsive={true}
              loop={false}
              paused={true}
            />
          </Container>
        </Container>
      )}
    </>
  );
};
